import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, Slider } from '@material-ui/core';
import { ReactGhLikeDiff } from 'react-gh-like-diff';
import PropTypes from 'prop-types';
import 'react-gh-like-diff/dist/css/diff2html.min.css';

import { Grid, Col, Button } from 'components/layout';
import request from 'utils/request';
import { themeColor } from 'utils/themeHelpers';
import { printObject } from '../../../utils/helpers';
import Media from '../../Media';
import { Line, renderLine } from '../../Step';

const Span = styled.button`
  display: inline-block;
  margin: 2px;
  padding: 0.5em;
  font-size: 0.8em;
  background: #fefefe;
  border: 1px solid ${props => (props.current ? themeColor('main') : '#777')};
  border-radius: 2px;
  cursor: pointer;
  ${props => props.current && 'font-weight: bold;'}
`;

const NewValue = styled.div`
  background-color: #ddffdd;
  padding: 0.2em;
`;

const DeletedValue = styled.div`
  background-color: #fee8e9;
  padding: 0.2em;
`;

const H4 = styled.h4`
  margin-bottom: 0;
  margin-top: 0.2em;
`;

const ignoredKeys = [
  'lines',
  'media',
  'translations_info',
  'updated',
  'loadtime',
  'revision_created',
  'revision_created_by_user',
  'content',
];

function getRevisionInfo({ revision_created, revision_created_by_user }) {
  return `${revision_created} by ${revision_created_by_user.user_name}`;
}

function RevisionsWidget({ id, current, lastUpdate = null }) {
  const [revisions, setRevisions] = useState([]);
  const [currentRevision, setCurrentRevision] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const data = await request(`v1/revisions`, { params: { post_id: id } });
      setRevisions(data.data.reverse());
    };

    fetchData();
  }, [id, lastUpdate]);

  const revision = currentRevision !== false && revisions[currentRevision];
  const revisionNext =
    currentRevision !== false && currentRevision !== revisions.length - 1
      ? revisions[currentRevision + 1]
      : current;
  const revisionKeys = Object.keys(revision);
  const revisionNextKeys = Object.keys(revisionNext);
  const changedKeys = revisionNextKeys.filter(k => !ignoredKeys.includes(k));
  changedKeys.deleted = revisionKeys.filter(
    k => !ignoredKeys.includes(k) && !revisionNextKeys.includes(k),
  );

  return (
    <div>
      <h4 style={{ marginBottom: 0 }}>Revisions</h4>
      Total {revisions.length} revisions
      <br />
      <Button
        onClick={e => {
          e.preventDefault();
          setCurrentRevision(revisions.length - 1);
        }}
      >
        Show revisions
      </Button>
      {currentRevision !== false && (
        <Dialog
          open={currentRevision !== false}
          onClose={() => setCurrentRevision(false)}
          fullWidth
          maxWidth="xl"
          style={{ height: '100%' }}
        >
          <DialogContent>
            <h3>Revisions</h3>
            <div>
              {revisions.map((r, i) => (
                <Span
                  current={i === currentRevision}
                  onClick={e => {
                    e.preventDefault();
                    setCurrentRevision(i);
                  }}
                >
                  {getRevisionInfo(r)}
                </Span>
              ))}
            </div>
            <Slider
              // defaultValue={currentRevision}
              // getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              /* valueLabelFormat={(val, num) => {
                console.log(revisions[val].revision_created);
              }} */
              step={1}
              marks
              min={0}
              value={currentRevision}
              max={revisions.length - 1}
              onChange={(e, val) => setCurrentRevision(val)}
            />
            <table>
              <tr>
                <th>Old value</th>
                <th>New value</th>
              </tr>
              {JSON.stringify(revision.media) !==
                JSON.stringify(revisionNext.media) && (
                <>
                  <tr>
                    <th colSpan={2} style={{ textAlign: 'left' }}>
                      <H4>Media</H4>
                    </th>
                  </tr>
                  <tr>
                    <td style={{ width: '50%' }}>
                      <Media image={revision.media} />
                    </td>
                    <td style={{ width: '50%' }}>
                      <Media image={revisionNext.media} />
                    </td>
                  </tr>
                </>
              )}
              {changedKeys.map(k => {
                if (
                  !ignoredKeys.includes(k) &&
                  JSON.stringify(revision[k]) !==
                    JSON.stringify(revisionNext[k])
                ) {
                  return (
                    <>
                      <tr>
                        <th colSpan={2} style={{ textAlign: 'left' }}>
                          <H4>{k.charAt(0).toUpperCase() + k.slice(1)}</H4>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <DeletedValue>
                            {printObject(revision[k])}
                          </DeletedValue>
                        </td>
                        <td>
                          <NewValue>{printObject(revisionNext[k])}</NewValue>
                        </td>
                      </tr>
                    </>
                  );
                }
              })}
              {changedKeys.deleted.map(k => (
                <>
                  <tr>
                    <th colSpan={2} style={{ textAlign: 'left' }}>
                      <H4>{k.charAt(0).toUpperCase() + k.slice(1)}</H4>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <DeletedValue>{printObject(revision[k])}</DeletedValue>
                    </td>
                    <td>
                      <NewValue>{printObject(revisionNext[k])}</NewValue>
                    </td>
                  </tr>
                </>
              ))}
              {JSON.stringify(revision.lines) !==
                  JSON.stringify(revisionNext.lines) && (
                  <>
                    <tr>
                      <th colSpan={2} style={{ textAlign: 'left' }}>
                        <H4>Lines</H4>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        {revision.lines.map(renderLine)}
                      </td>
                      <td>
                        {revisionNext.lines.map(renderLine)}
                      </td>
                    </tr>
                  </>
              )}
            </table>
            {revision.content !== revisionNext.content && (
              <>
                <H4>Content</H4>
                <ReactGhLikeDiff
                  options={{
                    originalFileName: revision.revision_created.replace(
                      ' ',
                      ';',
                    ),
                    updatedFileName:
                      currentRevision === revisions.length - 1
                        ? 'Current'
                        : revisionNext.revision_created.replace(' ', ';'),
                  }}
                  past={revision.content}
                  current={revisionNext.content}
                />
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

RevisionsWidget.propTypes = {
  id: PropTypes.number.isRequired,
  current: PropTypes.object.isRequired,
};

export default RevisionsWidget;
