import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '../AppHeader/Button';
import { ListItem } from '../layout/List';

import Avatar from './Avatar';
import Menu from './Menu';
import messages from './messages';

const LinkA = styled.a`
  transition: color 500ms;
  color: #333;
  font-weight: bold;
  ${props => props.margin && 'margin-left: 2em;'}
  &:hover {
    color: #fa6831;
  }
`;

function UserMenu({
  currentUser,
  isAdmin,
  onLogout,
  currentLang,
  isPreview,
  handleSetPreviewMode,
  canEdit,
  mobile = false,
}) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  if (!currentUser?.login) {
    return (
      <LinkA href="/wp-login.php" margin={!mobile}>
        <FormattedMessage {...messages.login} />
      </LinkA>
    );
  }

  if (mobile) {
    return (
      <React.Fragment>
        {isAdmin && (
          <a href="/api/v1/memcache/?clear=true" target="_blank">
            DEL CACHE
          </a>
        )}
        {canEdit && (
          <>
            <br />
            <Button
              onClick={handleSetPreviewMode}
              style={{ padding: '0 1em', marginRight: '1em' }}
            >
              {isPreview ? 'Dis' : 'En'}able preview
            </Button>
            <br />
            <Link to={`/${currentLang}/admin/`}>Admin</Link>
          </>
        )}
        <br />
        <br />
        <Avatar src={currentUser.avatar} width={30} />{' '}
        <strong style={{ marginLeft: '1em' }}>{currentUser.fullname}</strong>
        <br />
        <br />
        <LinkA
          href={`${currentUser.auth_url}profile-edit?next=${
            window.location.href
          }`}
        >
          <FormattedMessage {...messages.myProfile} />
        </LinkA>
        <br />
        <br />
        <LinkA href="#" onClick={onLogout}>
          <FormattedMessage {...messages.logout} />
        </LinkA>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {isAdmin && (
        <a
          href="/api/v1/memcache/?clear=true"
          target="_blank"
          style={{ marginRight: '0.5em' }}
        >
          DEL CACHE
        </a>
      )}
      {canEdit && (
        <div>
          <Button
            onClick={handleSetPreviewMode}
            style={{ padding: '0 1em', marginRight: '1em' }}
          >
            {isPreview ? 'Dis' : 'En'}able preview
          </Button>
          <Link to={`/${currentLang}/admin/`} style={{ marginRight: '1rem' }}>
            Admin
          </Link>
        </div>
      )}
      <div
        onMouseOver={() => setUserMenuOpen(!userMenuOpen)}
        onMouseOut={() => setUserMenuOpen(!userMenuOpen)}
        style={{ display: 'flex', alignItems: 'center', height: '100%' }}
      >
        <Avatar src={currentUser.avatar} width={30} />
        <Menu open={userMenuOpen}>
          <ListItem>
            <strong>{currentUser.fullname}</strong>
          </ListItem>
          <ListItem>
            <a
              href={`${currentUser.auth_url}profile-edit?next=${
                window.location.href
              }`}
            >
              <FormattedMessage {...messages.myProfile} />
            </a>
          </ListItem>
          <ListItem>
            <a href="#" onClick={onLogout}>
              <FormattedMessage {...messages.logout} />
            </a>
          </ListItem>
        </Menu>
      </div>
    </React.Fragment>
  );
}

UserMenu.propTypes = {
  currentLang: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  handleSetPreviewMode: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isPreview: PropTypes.bool,
  canEdit: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default UserMenu;
