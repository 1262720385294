/*
 *
 * AdminSection reducer
 *
 */
import produce from 'immer';

import { SET_LOADING, SET_SAVED } from './constants';

export const initialState = {
  loading: false,
  error: false,
  saved: false,
};

/* eslint-disable default-case, no-param-reassign */
const adminReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_LOADING:
        draft.loading = action.value;
        draft.saved = false;
        break;
      case SET_SAVED:
        draft.loading = false;
        draft.saved = action.value;
        break;
      default:
        break;
    }
  });

export default adminReducer;
