/*
 *
 * TagPage reducer
 *
 */
import parse from 'html-react-parser';
import produce from 'immer';
import { act } from 'react-testing-library';
import { combineReducers } from 'redux';
import { reorder, flattenCategories } from 'utils/helpers';
import { CHANGE_GUIDE_ORDER } from 'containers/admin/AdminTagPage/constants';
import { categoryInitialState } from '../App/reducer';
import {
  LOAD_GUIDES_SUCCESS,
  LOAD_GUIDES_ERROR,
  LOAD_GUIDES,
  LOAD_TAG_HELPS,
  LOAD_TAG_HELPS_ERROR,
  LOAD_TAG_HELPS_SUCCESS,
  LOAD_TAG_CATEGORIES,
  LOAD_TAG_CATEGORIES_ERROR,
  LOAD_TAG_CATEGORIES_SUCCESS,
} from './constants';

export const initialState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
  dataByCat: {},
  tag: null,
};

/* eslint-disable default-case, no-param-reassign */
const guidesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_GUIDES:
        draft.loading = true;
        draft.loaded = false;
        draft.error = false;
        draft.tag = action.parentId;
        break;
      case LOAD_GUIDES_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        // WTF? BE bug?
        let {payload} = action;
        if (payload && typeof payload === 'object') {
          payload = Object.values(payload);
        }
        draft.data = payload/*.map(g => {
          return {
            ...g,
            categories: g.categories[0],
          };
        });*/
        draft.dataByCat = payload.reduce(
          (acc, val) => {
            if (val.categories && val.categories.length) {
              if (!val.categories || val.categories.length === 0) {
                acc.uncategorized.push(val);
              } else {
                if (!acc[val.categories[0]]) {
                  acc[val.categories[0]] = [];
                }
                acc[val.categories[0]].push(val);
              }
            }
            return acc;
          },
          { uncategorized: [] },
        );
        break;
      case LOAD_GUIDES_ERROR:
        draft.error = true;
        draft.loading = false;
        draft.loaded = true;
        draft.data = [];
        break;
      case CHANGE_GUIDE_ORDER:
        draft.dataByCat[action.category] = reorder(
          draft.dataByCat[action.category],
          action.idx,
          action.idx2,
        );
        break;
    }
  });

export const helpsInitialState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
  tag: null,
  category: null,
  totalPages: 0,
  totalItems: 0,
};

/* eslint-disable default-case, no-param-reassign */
const helpsReducer = (state = helpsInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_TAG_HELPS:
        if (
          draft.tag !== action.tag ||
          action.clear ||
          action.page === 1 ||
          action.category !== draft.category
        ) {
          draft.data = [];
        }
        draft.loading = true;
        draft.loaded = false;
        draft.error = false;
        draft.tag = action.tag;
        draft.category = action.category;
        break;
      case LOAD_TAG_HELPS_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        if (action.payload !== null) {
          draft.data = draft.data.concat(action.payload);
        }
        draft.totalItems = action.totalItems;
        draft.totalPages = action.totalPages;
        break;
      case LOAD_TAG_HELPS_ERROR:
        draft.error = true;
        draft.loading = false;
        draft.loaded = true;
        draft.data = [];
        break;
    }
  });

const categoriesReducer = (state = categoryInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_TAG_CATEGORIES:
        draft.loading = true;
        break;
      case LOAD_TAG_CATEGORIES_SUCCESS:
        draft.data = action.payload;
        draft.dataBySlug = flattenCategories(action.payload).reduce(
          (acc, val) => {
            acc[val.slug] = { ...val, title: parse(val.title) };
            return acc;
          },
          {},
        );
        draft.loading = false;
        break;
      case LOAD_TAG_CATEGORIES_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
    }
  });

export default combineReducers({
  guides: guidesReducer,
  helps: helpsReducer,
  categories: categoriesReducer,
});
