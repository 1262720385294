import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import parseHtml from 'html-react-parser';
import { urlBuilder } from '../../utils/helpers';

import { MenuItem } from './Menu';

const MenuItemLi = MenuItem.withComponent('div');

const Cat = styled.span`
  color: #999;
`;

function Suggestion({
  suggestion,
  categories,
  index,
  itemProps,
  highlightedIndex,
  inputValue,
  column,
  inAdmin = false,
  language,
  useLink = true,
}) {
  const isHighlighted = highlightedIndex === index;
  const matches = match(suggestion[column], inputValue);
  const parts = parse(suggestion[column], matches);

  if (useLink) {
    return (
      <div>
        <MenuItem
          key={suggestion.slug}
          selected={isHighlighted}
          onClick={itemProps.onClick}
          to={urlBuilder({
            language: inAdmin ? `${language}/admin` : language,
            item: suggestion,
          })}
        >
          {suggestion.categories && (
            <Cat>{parseHtml(categories?.[suggestion?.categories[0]]?.title || '')} / </Cat>
          )}
          {parts.map((part, i) =>
            part.highlight ? (
              <span key={String(i)} style={{ fontWeight: 700 }}>
                {parseHtml(part.text)}
              </span>
            ) : (
              <strong key={String(i)} style={{ fontWeight: 300 }}>
                {parseHtml(part.text)}
              </strong>
            ),
          )}
        </MenuItem>
      </div>
    );
  }
  return (
    <div>
      <MenuItemLi
        onClick={itemProps.onClick}
        key={suggestion.slug}
        selected={isHighlighted}
      >
        {suggestion.categories && (
          <Cat>{parseHtml(categories?.[suggestion?.categories[0]]?.title || '')} / </Cat>
        )}
        {parts.map((part, i) =>
          part.highlight ? (
            <span key={String(i)} style={{ fontWeight: 700 }}>
              {parseHtml(part.text)}
            </span>
          ) : (
            <strong key={String(i)} style={{ fontWeight: 300 }}>
              {parseHtml(part.text)}
            </strong>
          ),
        )}
      </MenuItemLi>
    </div>
  );
}

Suggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.object,
  inputValue: PropTypes.string,
  column: PropTypes.string,
  suggestion: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  inAdmin: PropTypes.bool,
};

export default Suggestion;
