/*
 *
 * TagPage actions
 *
 */

import {
  LOAD_GUIDES,
  LOAD_GUIDES_ERROR,
  LOAD_GUIDES_SUCCESS,
  LOAD_TAG_CATEGORIES,
  LOAD_TAG_CATEGORIES_ERROR,
  LOAD_TAG_CATEGORIES_SUCCESS,
  LOAD_TAG_HELPS,
  LOAD_TAG_HELPS_ERROR,
  LOAD_TAG_HELPS_SUCCESS,
} from './constants';

export const loadGuidesAction = (tag, isAdmin = false) => ({
  type: LOAD_GUIDES,
  tag,
  isAdmin,
});

export const loadGuidesSuccess = payload => ({
  type: LOAD_GUIDES_SUCCESS,
  payload,
});

export const loadGuidesError = error => ({
  type: LOAD_GUIDES_ERROR,
  error,
});

export const loadTagHelpsAction = (
  tag,
  category,
  page = 1,
  isAdmin = false,
  sort = null,
  includeGuides = true,
  clear = false,
) => ({
  type: LOAD_TAG_HELPS,
  tag,
  category,
  page,
  isAdmin,
  sort,
  includeGuides,
  clear,
});

export const loadTagHelpsSuccess = (
  payload,
  totalPages = 0,
  totalItems = 0,
) => ({
  type: LOAD_TAG_HELPS_SUCCESS,
  payload,
  totalItems,
  totalPages,
});

export const loadTagHelpsError = error => ({
  type: LOAD_TAG_HELPS_ERROR,
  error,
});

export const loadTagCategoriesAction = tag => ({
  type: LOAD_TAG_CATEGORIES,
  tag,
});

export const loadTagCategoriesSuccess = payload => ({
  type: LOAD_TAG_CATEGORIES_SUCCESS,
  payload,
});

export const loadTagCategoriesError = error => ({
  type: LOAD_TAG_CATEGORIES_ERROR,
  error,
});
