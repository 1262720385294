import styled from 'styled-components';

export default styled.div`
  margin-left: ${({ level = 0 }) => level * 30}px;
  position: relative;
  padding: 1em 0 0 30px;
  width: auto;
  word-break: break-word;
  color: ${({ icon }) => (icon === 'caution' ? 'red' : 'inherit')};
  p {
    margin-top: 0;
  }
  @media print {
    padding-top: 0.5em;
  }
`;
