/**
 *
 * TranslationInfo
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { faInfoCircle, faCheck, faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { themeColor } from '../../../utils/themeHelpers';

const Wrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  border: 1px solid ${themeColor('lightGrey')};
  background: white;
  padding: 1em;
  top: 20px;
  right: 0;
  min-width: 450px;
  max-width: 100%;
  z-index: 9999;
`;

function BooleanIcon({ value }) {
  return value && value !== -1 ? (
    <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
  ) : (
    <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} />
  );
}

function TranslationInfo({ data = {} }) {
  const [open, setOpen] = useState(false);
  const { translations_info } = data;
  return (
    <Wrapper>
      Last push:{' '}
      {translations_info && translations_info.en?.last_push
        ? moment(translations_info.en?.last_push).fromNow()
        : 'Never'}
      <FontAwesomeIcon
        icon={faInfoCircle}
        style={{ marginLeft: '0.5em', cursor: 'pointer' }}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
      />
      {open && translations_info && (
        <Box>
          <table>
            <thead>
              <tr>
                <th>Lang</th>
                <th>Last pull</th>
                <th>Translated</th>
                <th>Actual</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(translations_info).map(lang => {
                if (lang === 'en') return null;
                return (
                  <tr key={lang}>
                    <td>{lang}</td>
                    <td>
                      {translations_info[lang].last_pull !== -1
                        ? moment(translations_info[lang].last_pull).fromNow()
                        : 'Never'}
                    </td>
                    <td>
                      <BooleanIcon value={translations_info[lang].translated} />
                    </td>
                    <td>
                      <BooleanIcon value={translations_info[lang].actual} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      )}
    </Wrapper>
  );
}

TranslationInfo.propTypes = {
  data: PropTypes.object,
};

export default TranslationInfo;
