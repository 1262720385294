/*
 * ErrorBoundary Messages
 *
 * This contains all the text for the ErrorBoundary component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ErrorBoundary';

export default defineMessages({
  main: {
    id: `${scope}.main`,
    defaultMessage: 'Something went wrong.',
  },
  backToHomepage: {
    id: `${scope}.backToHomepage`,
    defaultMessage: 'Back to homepage',
  },
});
