/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Search';

export default defineMessages({
  main: {
    id: `${scope}.main`,
    defaultMessage: 'Search Prusanet...',
  },
  nothingFound: {
    id: `${scope}.nothingFound`,
    defaultMessage: 'Nothing found',
  },
  glossary: {
    id: `${scope}.glossary`,
    defaultMessage: 'Glossary',
  },
  showAllResults: {
    id: `${scope}.showAllResults`,
    defaultMessage: 'Show all results',
  },
});
