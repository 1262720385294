import { createSelector } from 'reselect';
import { initialState, relatedInitialState } from './reducer';

/**
 * Direct selector to the helpPage state domain
 */

const selectHelpPageDomain = state =>
  state.helpPage || { articles: initialState, related: relatedInitialState };

/**
 * Other specific selectors
 */

/**
 * Default selector used by ArticlePage
 */

const makeSelectArticles = () =>
  createSelector(
    selectHelpPageDomain,
    substate => substate.articles,
  );

const makeSelectRelated = () =>
  createSelector(
    selectHelpPageDomain,
    substate => substate.related,
  );

export default makeSelectArticles;
export { selectHelpPageDomain, makeSelectArticles, makeSelectRelated };
