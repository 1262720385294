/**
 *
 * LanguageSwitcher
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';

import { urlBuilder } from '../../utils/helpers';
import { themeColor } from '../../utils/themeHelpers';

import EN_FLAG from './icons/en.png';
import ES_FLAG from './icons/es.png';
import IT_FLAG from './icons/it.png';
import FR_FLAG from './icons/fr.png';
import CS_FLAG from './icons/cs.png';
import DE_FLAG from './icons/de.png';
import JA_FLAG from './icons/ja.png';
import PL_FLAG from './icons/pl.png';

const List = styled.ul`
  position: absolute;
  right: 0;
  top: 24px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-height: 0;
  list-style: none;
  background-color: white;
  width: 40px;
  transition: max-height 200ms;
  overflow: hidden;
  &:hover {
    display: block;
  }
  li {
    cursor: pointer;
    border-bottom: 1px solid ${themeColor('lightGrey')};
    padding: 2px;
  }
`;

const MobileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    width: 25%;
    // border-left: 1px solid ${themeColor('lightGrey')};
    padding: 3px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  width: ${props => (props.mobile ? 'auto' : '30px')};
  margin-right: 2rem;
  &:hover {
    ${List} {
      border: 1px solid ${themeColor('grey')};
      max-height: 220px;
    }
  }
`;

const Li = styled.li`
  ${props =>
    props.current
      ? `
    border: 1px solid #333;
    border-radius: 0.125rem;
  `
      : `
    &:hover {
      color: #666;
    }
  `}
`;

const StyledLink = styled(Link)`
  position: relative;
  margin: 2px;
  border: 1px solid #eee;
  &:hover {
    border-color: #333;
  }
  /*&:hover:after {
    content: '';
    width: 100%;
    display: block;
    opacity: 0.56;
    height: 100%;
    position: absolute;
    background-color: #ddd;
    left: 0;
    top: 0;
  }*/
`;
const LiClassic = styled.li`
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  &:hover {
    font-weight: bold;
  }
`;

const FlagImage = styled.img`
  display: block;
`;

const iconMap = {
  en: EN_FLAG,
  // es: ES_FLAG,
  cs: CS_FLAG,
  /*  it: IT_FLAG,
  ja: JA_FLAG,
  de: DE_FLAG,
  fr: FR_FLAG,
  pl: PL_FLAG, */
};

export function PostLanguageSwitcher({ translations, postType, language }) {
  return Object.keys(translations).map(lng => {
    if (lng === language) {
      return null;
    }
    if (lng !== 'cs' && lng !== 'en') return null;
    if (
      translations[lng].translated ||
      (translations[lng].title && translations[lng].content)
    ) {
      // TODO?
      return (
        <StyledLink
          key={lng}
          to={urlBuilder({
            language: lng,
            item: { type: postType, slug: translations[lng].slug },
          })}
        >
          <FlagImage
            src={iconMap[lng]}
            width={28}
            title={lng.toUpperCase()}
            alt={lng.toUpperCase()}
          />
        </StyledLink>
      );
    }
    if (lng === 'cs' && language !== 'cs') {
      return (
        <StyledLink
          key={lng}
          to={urlBuilder({
            language: lng,
            item: { type: postType, slug: translations[lng].slug },
          })}
        >
          <FlagImage
            src={iconMap[lng]}
            width={28}
            title={lng.toUpperCase()}
            alt={lng.toUpperCase()}
          />
        </StyledLink>
      );
    }
    return null;
  });
}

PostLanguageSwitcher.propTypes = {
  language: PropTypes.string.isRequired,
  translations: PropTypes.object,
  postType: PropTypes.string,
};

function LanguageSwitcher({ currentLang, onSelect, mobile = false }) {
  const ListComp = mobile ? MobileList : List;
  const LiComp = mobile ? Li : LiClassic;
  return (
    <Wrapper mobile={mobile}>
      <span
        style={{
          position: 'relative',
          display: 'inline-block',
          color: mobile ? '#999' : 'inherit',
          width: '40px',
        }}
      >
        {/* <img src={iconMap[currentLang]} title={currentLang.toUpperCase()} alt={currentLang.toUpperCase()} style={{ paddingRight: mobile ? '2px' : 0 }} /> */}
        {/* {mobile && (
          <Overlay />
        )} */}
        {!mobile && (
          <>
            {currentLang.toUpperCase()}
            <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '5px' }} />
          </>
        )}
      </span>
      <ListComp>
        {Object.keys(iconMap).map(lang => {
          if (!mobile && lang === currentLang) return null;
          return (
            <LiComp
              onClick={lang === currentLang ? null : () => onSelect(lang)}
              key={lang}
              current={lang === currentLang}
            >
              {/* <img src={iconMap[lang]} title={lang.toUpperCase()} alt={lang.toUpperCase()} /> */}
              {lang.toUpperCase()}
            </LiComp>
          );
        })}
      </ListComp>
    </Wrapper>
  );
}

LanguageSwitcher.propTypes = {
  onSelect: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

export default LanguageSwitcher;
