import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import AdminTagEditor from 'containers/admin/AdminTagPage/Editor';
import AdminCategoryEditor from 'containers/admin/AdminCategoryPage/Editor';
// import AdminRelationEditor from 'containers/admin/AdminRelationPage/Editor';

export function ModalEditors({
  tagDialogOpen,
  closeTagDialog,
  categoryDialogOpen,
  closeCategoryDialog,
  relationDialogOpen,
  closeRelationDialog,
}) {
  return (
    <>
      <Dialog
        open={tagDialogOpen}
        fullWidth
        maxWidth="lg"
        onClose={closeTagDialog}
      >
        <div style={{ padding: '1em' }}>
          <AdminTagEditor
            slug="new"
            doRedirect={false}
            callback={closeTagDialog}
          />
        </div>
      </Dialog>
      <Dialog
        open={categoryDialogOpen}
        maxWidth="lg"
        fullWidth
        onClose={closeCategoryDialog}
      >
        <div style={{ padding: '1em' }}>
          <AdminCategoryEditor
            slug="new"
            doRedirect={false}
            callback={closeCategoryDialog}
          />
        </div>
      </Dialog>
      <Dialog
        open={relationDialogOpen}
        fullWidth
        maxWidth="lg"
        onClose={closeRelationDialog}
      >
        <div style={{ padding: '1em' }}>
          REL E HERE
          {/*
          <AdminRelationEditor
            slug="new"
            doRedirect={false}
            callback={closeRelationDialog}
          />
          */}
        </div>
      </Dialog>
    </>
  );
}

ModalEditors.propTypes = {
  tagDialogOpen: PropTypes.bool.isRequired,
  categoryDialogOpen: PropTypes.bool.isRequired,
  relationDialogOpen: PropTypes.bool.isRequired,
  closeTagDialog: PropTypes.func.isRequired,
  closeCategoryDialog: PropTypes.func.isRequired,
  closeRelationDialog: PropTypes.func.isRequired,
};

export default ModalEditors;
