import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage, useScrollPosition } from '../../utils/hooks';
import { scrollWithOffset } from '../../utils/helpers';

function Anchor({ node, id, children, offset = -260 }) {
  const Tag = node ? node.name : 'div';
  const [anchor, setAnchor] = useState(id);
  const ref = useRef(null);
  //const [scrolling, setScrolling] = useLocalStorage('is_scrolling', false);
  useScrollPosition(
    e => {
      //setScrolling(true);
      /*if (e.currPos.y > 0 && e.currPos.y < ref.current.getBoundingClientRect().height && !scrolling) {
        setAnchor(null);
        window.location.hash = id;
        setAnchor(id);
      }*/
      //setInterval(() => setScrolling(false), 200);
    },
    null,
    ref,
    false, // ADD THROTTLE?
  );

  useEffect(() => {
    if (window.location.hash.substr(1) === id) {
      if (ref.current) {
        setTimeout(() => scrollWithOffset(ref.current, offset, true), 100);
      }
    }
  }, [window.location.hash, ref.current]);

  return (
    <Tag id={anchor} ref={ref}>
      {children}
    </Tag>
  );
}

Anchor.propTypes = {
  node: PropTypes.object,
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Anchor;
