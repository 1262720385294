/*
 * ArticlePage Messages
 *
 * This contains all the text for the ArticlePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ArticlePage';

export default defineMessages({
  relevantFor: {
    id: `${scope}.relevantFor`,
    defaultMessage: 'Relevant for',
  },
  lastUpdated: {
    id: `${scope}.lastUpdated`,
    defaultMessage: 'Last updated',
  },
  viewCount: {
    id: `${scope}.viewCount`,
    defaultMessage: 'View count',
  },
  created: {
    id: `${scope}.created`,
    defaultMessage: 'Created',
  },
});
