/*
 *
 * GuidePage reducer
 *
 */
import produce from 'immer';
import { combineReducers } from 'redux';
import { reorder } from 'utils/helpers';
import { CHANGE_STEP_ORDER } from 'containers/admin/AdminGuidePage/constants';

import {
  INIT_EMPTY_GUIDE,
  LOAD_GUIDE,
  LOAD_GUIDE_ERROR,
  LOAD_GUIDE_SUCCESS,
  LOAD_STEPS,
  LOAD_STEPS_ERROR,
  LOAD_STEPS_SUCCESS,
  RELOAD_STEP_SUCCESS,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  slug: null,
  data: {},
  notFound: false,
  meta: {
    next: null,
    previous: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const guideReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_GUIDE:
        if (!action.noLoading) {
          draft.loading = true;
        }
        draft.error = false;
        draft.notFound = false;
        draft.slug = action.slug;
        break;
      case LOAD_GUIDE_SUCCESS:
        draft.loading = false;
        draft.notFound = false;
        draft.data = action.payload || {};
        draft.next = action.next || null;
        draft.previous = action.previous || null;
        break;
      case LOAD_GUIDE_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.notFound = action.error || false;
        break;
      case INIT_EMPTY_GUIDE:
        draft.data = { steps: [] };
        draft.slug = null;
        break;
    }
  });

export const stepsInitialState = {
  loading: false,
  error: false,
  slug: null,
  data: [],
};

/* eslint-disable default-case, no-param-reassign */
const stepsReducer = (state = stepsInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_STEPS:
        draft.loading = true;
        draft.error = false;
        draft.slug = action.slug;
        break;
      case LOAD_STEPS_SUCCESS:
        draft.loading = false;
        draft.data = action.payload;
        break;
      case LOAD_STEPS_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
      case RELOAD_STEP_SUCCESS:
        const { data } = draft;
        const idx = data.findIndex(item => item.id === action.payload.id);
        data[idx] = action.payload;
        draft.data = data;
        break;
      case CHANGE_STEP_ORDER:
        draft.data = reorder(draft.data, action.idx, action.idx2);
        break;
    }
  });

export default combineReducers({
  guide: guideReducer,
  steps: stepsReducer,
});
