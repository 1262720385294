import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  faClone,
  faCrop,
  faPencil,
  faTrash,
  faCompress,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Meta = styled.span`
  z-index: 200;
  position: absolute;
  top: 1px;
  left: 1px;
  ${props =>
    props.vertical
      ? 'height: 20px; width: 100%; & > * { margin: 0 0.5em; } flex-direction: row;'
      : 'width: 20px; height: 100%; flex-direction: column;'}
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  color: black;
  display: none;
  background-color: rgba(255, 255, 255, 0.7);
`;

function ImageMeta({
  img,
  onImageDelete = null,
  onCloneImage = null,
  setImgToEdit = null,
  setImgToCrop = null,
  vertical = false,
}) {
  let url = img.original || (img.media && (img.media.original || img.media.url));
  if (img.child || (img.media && img.media.child)) {
    url = (img.child && img.child.original) || (img.media.child && (img.media.child.original || img.media.child.url));
  }
  return (
    <Meta vertical={vertical}>
      {onImageDelete && (
        <FontAwesomeIcon icon={faTrash} onClick={() => onImageDelete(img)} />
      )}
      {onCloneImage && (
        <FontAwesomeIcon icon={faClone} onClick={() => onCloneImage(img)} />
      )}
      {setImgToCrop && (
        <FontAwesomeIcon icon={faCrop} onClick={() => setImgToCrop(img)} />
      )}
      {setImgToEdit && (
        <FontAwesomeIcon icon={faPencil} onClick={() => setImgToEdit(img)} />
      )}
      {url && (
        <a href={url} target="_blank">
          <FontAwesomeIcon icon={faCompress} style={{ color: 'black' }}/>
        </a>
      )}
    </Meta>
  );
}

ImageMeta.propTypes = {
  img: PropTypes.object.isRequired,
  onImageDelete: PropTypes.func,
  onCloneImage: PropTypes.func,
  setImgToEdit: PropTypes.func,
  setImgToCrop: PropTypes.func,
  vertical: PropTypes.bool,
};

export default ImageMeta;
