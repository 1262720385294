import { fabric } from 'fabric';

const Circle = fabric.util.createClass(fabric.Circle, {
  type: 'circle',
  superType: 'drawing',
  initialize(points, options) {
    this.callSuper('initialize', points, options);
    this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });
  },
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.strokeStyle = oldStyle;
    ctx.lineWidth = this.strokeWidth;
    ctx.stroke();
    ctx.restore();
  },
  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.get('name'),
    });
  },
});

Circle.fromObject = function(object, callback, forceAsync) {
  return fabric.Object._fromObject(
    'Circle',
    object,
    callback,
    forceAsync,
  );
};

fabric.Circle = Circle;

export default Circle;
