import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../utils/constants/theme';

export const Button = styled.button`
  padding: 0.3em;
  display: inline-block;
  border: 1px solid ${theme.colors.main};
  color: ${theme.colors.main};
  background-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 400ms, background-color 400ms;
  &:not(:disabled):hover {
    color: white;
    background-color: ${theme.colors.main};
  }
  &:disabled {
    color: ${theme.colors.grey};
    border-color: ${theme.colors.grey};
    cursor: not-allowed;
  }
`;

export const ButtonA = Button.withComponent(Link);
export const ButtonRealA = Button.withComponent('a');

export const ButtonWhite = styled.button`
  padding: 1em 2em;
  text-align: center;
  color: ${theme.colors.main};
  background-color: white;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:not(:disabled):hover {
    text-decoration: underline;
  }
  &:disabled {
    background-color: ${theme.colors.grey};
    cursor: not-allowed;
  }
`;

export const ButtonWhiteA = ButtonWhite.withComponent(Link);

export const ButtonWhiteRealA = ButtonWhite.withComponent('a');

export const ButtonBig = styled.button<{ color?: string }>`
  padding: 1em 2em;
  text-align: center;
  background-color: ${props => props.color || theme.colors.main};
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 500ms;
  &:not(:disabled):hover {
    text-decoration: none;
    background-color: #ca4d1f;
  }
  &:disabled {
    background-color: ${theme.colors.grey};
  }
`;

export const ButtonBigA = ButtonBig.withComponent(Link);
export const ButtonBigRealA = ButtonBig.withComponent('a');

type BaseButtonProps = {
  variant: 'big' | 'white' | 'border';
  children: React.ReactNode;
} & (
  | {
      to: string;
    }
  | {
      href: string;
    }
  | {
      onClick: () => void;
    });

export const BaseButton: React.FC<BaseButtonProps> = ({
  variant,
  ...props
}) => {
  if ('to' in props) {
    switch (variant) {
      case 'big':
        return <ButtonBigA {...props} />;
      case 'white':
        return <ButtonWhiteA {...props} />;
      case 'border':
      default:
        return <ButtonA {...props} />;
    }
  }

  if ('onClick' in props) {
    switch (variant) {
      case 'big':
        return <ButtonBig {...props} />;
      case 'white':
        return <ButtonWhite {...props} />;
      case 'border':
      default:
        return <Button {...props} />;
    }
  }

  switch (variant) {
    case 'big':
      return <ButtonBigRealA {...props} />;
    case 'white':
      return <ButtonWhiteRealA {...props} />;
    case 'border':
    default:
      return <ButtonRealA {...props} />;
  }
};

BaseButton.propTypes = {
  variant: PropTypes.oneOf(["big", "white", "border"] as const).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  children: PropTypes.node,
};

export default BaseButton;
