import { createSelector } from 'reselect';

/**
 * Direct selector to the guidePage state domain
 */

const selectAdminSectionDomain = state => state.adminSection;

/**
 * Other specific selectors
 */

export const makeSelectSavingState = () =>
  createSelector(
    selectAdminSectionDomain,
    globalState => globalState,
  );
export default { selectAdminSectionDomain, makeSelectSavingState };
