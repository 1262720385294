/*
 *
 * TagPage constants
 *
 */

export const LOAD_GUIDES = 'app/TagPage/LOAD_GUIDES';
export const LOAD_GUIDES_SUCCESS = 'app/TagPage/LOAD_GUIDES_SUCCESS';
export const LOAD_GUIDES_ERROR = 'app/TagPage/LOAD_GUIDES_ERROR';

export const LOAD_TAG_HELPS = 'app/TagPage/LOAD_TAG_HELPS';
export const LOAD_TAG_HELPS_SUCCESS = 'app/TagPage/LOAD_TAG_HELPS_SUCCESS';
export const LOAD_TAG_HELPS_ERROR = 'app/TagPage/LOAD_TAG_HELPS_ERROR';

export const LOAD_TAG_CATEGORIES = 'app/TagPage/LOAD_TAG_CATEGORIES';
export const LOAD_TAG_CATEGORIES_SUCCESS =
  'app/TagPage/LOAD_TAG_CATEGORIES_SUCCESS';
export const LOAD_TAG_CATEGORIES_ERROR =
  'app/TagPage/LOAD_TAG_CATEGORIES_ERROR';
