/**
 *
 * EditButton
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ButtonWhiteA as Button } from '../layout/Button';

import messages from './messages';

const Btn = styled(Button)`
  position: absolute;
  right: 5px;
  top: 5px;
  @media print {
    display: none;
  }
`;

interface EditButtonProps {
  url: string;
}

const EditButton: React.FC<EditButtonProps> = ({ url }) => {
  return (
    <Btn to={url}>
      <FormattedMessage {...messages.edit} />
    </Btn>
  );
};

EditButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default EditButton;
