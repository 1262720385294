import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { iconMap } from '../ToolsBar';

const Aside = styled.aside`
  padding: 0.5em;
  box-sizing: border-box;
  border-left: 2px solid #eee;
  height: 100%;
`;

function ObjectList({ objects, onObjectSelect, selectedObject }) {
  return (
    <Aside>
      <h6 style={{ marginTop: '0.2em' }}>Layers (Objects)</h6>
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {objects.map((obj) => {
          const icon = iconMap[obj.type] || null;
          return (
            <li key={obj.id} onClick={() => onObjectSelect(obj.id)} style={{ borderLeft: selectedObject && selectedObject.id === obj.id ? '1px solid grey' : 'none', paddingLeft: '10px' }}>
              <span style={{ color: obj.stroke, width: '30px', display: 'inline-block' }}>{icon && <FontAwesomeIcon icon={icon} />}</span>
              {`${obj.name}${obj.type === 'numberedCircle' ? ` #${obj.label}` : ''}`}
            </li>
          );
        })}
      </ul>
    </Aside>
  );
}

ObjectList.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object),
  onObjectSelect: PropTypes.func.isRequired,
};

export default ObjectList;
