import styled from 'styled-components';
import { themeColor } from '../../utils/themeHelpers';

export default styled.header`
  height: 48px;
  width: 100%;
  padding: 0 1em;
  position: fixed;
  background-color: ${props => props.isTest ? '#94948a' : 'white'};
  border-bottom: 1px solid ${themeColor('lightGrey')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 50;
  @media screen and (max-width: 64em) {
    justify-content: center;
  }
  @media print {
    display: none;
  }
`;
