/**
 *
 * UnsavedPrompt
 *
 */

import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

class UnsavedPrompt extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload = e => {
    if (this.props.condition) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  render() {
    return <Prompt when={this.props.condition} message={this.props.message} />;
  }
}

UnsavedPrompt.propTypes = {
  condition: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default UnsavedPrompt;
