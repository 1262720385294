/**
 *
 * NewsPage
 *
 */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Breadcrumbs from 'components/Breadcrumbs';
import ContactSection from 'components/ContactSection';
import InfiniteScroll from 'components/InfiniteScroll';
import { Col, ColorBar, Grid, WhiteBar, Wrapper, H2 } from 'components/layout';
import Loader from 'components/Loader';
import GreyWrapper from 'containers/Search/GreyWrapper';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import SEOHeader from 'components/SEOHeaders';
import FeaturedList from '../../components/FeaturedList';
import News from '../../components/News';
import request from '../../utils/request';
import messages from './messages';

export function NewsPage({ language }) {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    // loadArticles(match.params.category);
    request('v1/news', {
      params: {
        lng: language,
        orderby: 'created',
        order: 'desc',
        per_page: -1,
      },
    }).then(data => {
      setArticles(data.data);
      setLoading(false);
    });
  }, [language]);

  return (
    <div>
      <SEOHeader title="News" />
      <GreyWrapper />
      <ColorBar height={10}>
        <Wrapper style={{ width: '100%' }}>
          <Breadcrumbs items={[]} language={language} />
          <h1 style={{ textAlign: 'center' }}>
            <FormattedMessage {...messages.newsArchive} />
          </h1>
        </Wrapper>
      </ColorBar>
      {loading ? (
        <Loader />
      ) : (
        articles.length > 0 && (
          <WhiteBar borderTop borderBottom>
            <Wrapper>
              <Grid align="center">
                <Col cols={9} noPadding>
                  <FeaturedList
                    tags={{}}
                    categories={{}}
                    items={articles}
                    language={language}
                    withThumbnails
                    large
                  />
                </Col>
                  {/* <InfiniteScroll
                  onLoad={handlePageChange}
                  page={page}
                  totalPages={articles.totalPages}
                  isLoading={articles.loading}
                /> */}
              </Grid>
            </Wrapper>
          </WhiteBar>
        )
      )}
      <ContactSection />
    </div>
  );
}

NewsPage.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
});

const mapDispatchToProps = () => ({});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(NewsPage);
