/*
 * NewsPage Messages
 *
 * This contains all the text for the NewsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.NewsPage';

export default defineMessages({
  newsArchive: {
    id: `${scope}.newsArchive`,
    defaultMessage: 'News archive',
  },
});
