/*
 *
 * CategoryPage constants
 *
 */

export const LOAD_ARTICLES = 'app/CategoryPage/LOAD_ARTICLES';
export const LOAD_ARTICLES_SUCCESS = 'app/CategoryPage/LOAD_ARTICLES_SUCCESS';
export const LOAD_ARTICLES_ERROR = 'app/CategoryPage/LOAD_ARTICLES_ERROR';

export const LOAD_GUIDES = 'app/CategoryPage/LOAD_GUIDES';
export const LOAD_GUIDES_SUCCESS = 'app/CategoryPage/LOAD_GUIDES_SUCCESS';
export const LOAD_GUIDES_ERROR = 'app/CategoryPage/LOAD_GUIDES_ERROR';
