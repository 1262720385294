import { fabric } from 'fabric';

const Triangle = fabric.util.createClass(fabric.Triangle, {
  type: 'triangle',
  superType: 'drawing',
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.strokeStyle = oldStyle;
    ctx.lineWidth = this.strokeWidth;
    ctx.stroke();
    ctx.restore();
  },
  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.get('name'),
    });
  },
});

Triangle.fromObject = function(object, callback, forceAsync) {
  return fabric.Object._fromObject(
    'Triangle',
    object,
    callback,
    forceAsync,
  );
};

fabric.Triangle = Triangle;

export default Triangle;
