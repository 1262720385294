import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';

import UserMenu from 'components/UserMenu';
import MobileWidget from '../layout/MobileWidget';
import LanguageSwitcher from '../LanguageSwitcher';
import { useLocalStorage, useOutsideClick } from '../../utils/hooks';

import Burger from './Burger';
import Menu from './Menu';
import Nav from './Nav';
import MobileNav from './MobileNav';
import MobileA from './MobileA';
import NavItem from './NavItem';
import Header from './Header';
import RightWidget from './RightWidget';

import LOGO_SRC from './logo.svg';

function AppHeader({
  // languages,
  currentLang,
  currentUser,
  changeLanguage,
  nav,
  onLogout,
  canEdit,
  isAdmin,
  isPreview,
  hideMenu = false,
  handleSetPreviewMode,
}) {
  const [menuOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (menuOpen) setOpen(false);
  });

  const isTest = window.location.origin.indexOf('intranet') === -1;

  return (
    <Header isTest={isTest}>
      <Link
        to={`/${currentLang}/`}
      >
        <img src={LOGO_SRC} alt="Prusanet" width={192} />
        {isTest && <strong style={{ color: '#FF0000' }}>TEST</strong>}
      </Link>
      <Nav>
        {!hideMenu &&
          nav &&
          nav.map(item => (
            <NavItem key={item.ID} active={item.url.includes(window.location.origin)}>
              <a href={item.url} rel="noopener noreferrer">
                {item.title}
              </a>
            </NavItem>
          ))}
      </Nav>


      <RightWidget>
        <LanguageSwitcher
          currentLang={currentLang}
          onSelect={code => changeLanguage(code)}
        />
        <UserMenu
          canEdit={canEdit}
          currentLang={currentLang}
          currentUser={currentUser}
          onLogout={onLogout}
          isPreview={isPreview}
          isAdmin={isAdmin}
          handleSetPreviewMode={handleSetPreviewMode}
        />
      </RightWidget>
      <MobileWidget>
        <Burger open={menuOpen} setOpen={setOpen} />
        <Menu open={menuOpen} forwardRef={ref}>
          <LanguageSwitcher
            currentLang={currentLang}
            onSelect={code => changeLanguage(code)}
            mobile
          />
          {!hideMenu && nav && (
            <MobileNav>
              {nav.map(item => (
                <MobileA
                  href={item.url}
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </MobileA>
              ))}
            </MobileNav>
          )}
          <div>
            <UserMenu
              canEdit={canEdit}
              currentLang={currentLang}
              currentUser={currentUser}
              onLogout={onLogout}
              isPreview={isPreview}
              isAdmin={isAdmin}
              handleSetPreviewMode={handleSetPreviewMode}
              mobile
            />
          </div>
        </Menu>
      </MobileWidget>
    </Header>
  );
}

AppHeader.propTypes = {
  currentLang: PropTypes.string.isRequired,
  // languages: PropTypes.arrayOf(PropTypes.string),
  changeLanguage: PropTypes.func.isRequired,
  nav: PropTypes.array,
  currentUser: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  handleSetPreviewMode: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isPreview: PropTypes.bool,
  canEdit: PropTypes.bool,
  hideMenu: PropTypes.bool,
  /* isEditMode: PropTypes.bool.isRequired,
  onEditModeChange: PropTypes.func.isRequired, */
};

export default AppHeader;
