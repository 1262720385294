import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import messages from './messages';

function SEOHeader({ description, title, image, keywords, locales = null }) {
  const intl = useIntl();
  const desc = description
    ? description.replace(/<\/?[^>]+(>|$)/g, '')
    : intl.formatMessage(messages.description);

  const [imageMeta, setImageMeta] = useState({});

  useEffect(() => {
    if (image && image.original) {
      const img = new Image();

      img.onload = () => {
        setImageMeta({ w: img.width, h: img.height });
      };

      img.src = image.original;
    }
  }, [image]);

  const hasImage = image && image.original;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      {desc ? <meta name="description" content={desc.slice(0, 150)} /> : null}
      {title ? <meta name="subject" content={title} /> : null}
      {title ? <meta property="og:title" content={title} /> : null}
      {hasImage ? <meta property="og:image" content={image.original} /> : null}
      {hasImage ? (
        <meta property="og:image:width" content={imageMeta.w} />
      ) : null}
      {hasImage ? (
        <meta property="og:image:height" content={imageMeta.h} />
      ) : null}
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {desc ? <meta property="og:description" content={desc} /> : null}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Prusa3D" />
      {desc ? <meta name="twitter:description" content={desc} /> : null}
      {desc ? <meta name="twitter:image:alt" content={desc} /> : null}
      {hasImage ? <meta name="twitter:image" content={image.original} /> : null}
      <link
        rel="canonical"
        href={window.location.origin + window.location.pathname}
      />
      {locales &&
        Object.keys(locales).map(locale => (
          <link
            rel="alternate"
            href={`${window.location.origin}${locales[locale]}`}
            hrefLang={locale}
            key={locale}
          />
        ))}
    </Helmet>
  );
}

SEOHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  keywords: PropTypes.string,
  locales: PropTypes.object,
};

export default SEOHeader;
