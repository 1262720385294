import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: calc((100% - 250px) / 2);
  text-align: right;
  @media screen and (max-width: 64em) {
    display: none;
  }
`;
