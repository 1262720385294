import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../layout';

function Media({ image, ...rest }) {
  /* const ref = useRef(null);
  useEffect(() => {
    if (ref.current && image.meta && image.meta.svg) {
      ref.current.innerHTML = image.meta.svg;
    }
  }, []); */
  if (!image) return null;
  if (image.video) {
    return (
      <video
        style={{ width: '100%', zIndex: 5 }}
        controls
        {...rest}
        autoPlay
        loop
      >
        <source src={image.video.original} type={image.video.mime_type} />
        Your browser does not support HTML5 video.
      </video>
    );
  }
  if ((image.image && image.image.child) || image.child) {
    return (
      <Image
        image={(image.image && image.image.child) || image.child}
        {...rest}
      />
    );
  }
  /* if (image.meta && image.meta.svg) {
    return <Wrapper ref={ref} {...rest} />;
  } */
  return <Image image={image.image || image} {...rest} />;
}

Media.propTypes = {
  image: PropTypes.object.isRequired,
};

export default Media;
