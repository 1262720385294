import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment/moment';

import EditLock from '../components/admin/EditLock';
import ItemType, { ItemTypeIcon } from '../components/ItemType';
import { colorMap } from '../components/Step/Bullet';
import Media from '../components/Media';
import TagBadge from '../components/TagBadge';
import { useSocketUsers } from './adminHooks';
import { PLACEHOLDER_SRC, STATUS_MAP } from './constants';
import { urlBuilder } from './helpers';

const Badge = styled.span`
  display: inline-block;
  padding: 0.5em;
  border-radius: 10px;
  font-size: 0.8em;
  color: white;
  font-weight: bold;
`;

const Status = styled(Badge)`
  background-color: ${({ status }) =>
    status === 'private'
      ? colorMap.red
      : status === 'public' || status === 'publish'
        ? colorMap.green
        : colorMap.orange};
`;

const TranslationStatus = styled(Badge)`
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: ${({ translated, actual }) =>
    translated === 1 && actual === 1
      ? colorMap.green
      : translated === 1 && actual !== 1
      ? colorMap.orange
        : colorMap.red};
`;

export const ImageTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      if (!value || !value.image || !value.image.original) {
        return <img src={PLACEHOLDER_SRC} width={100} />;
      }
      return <Media image={value.image} size="medium" />;
    }}
    {...props}
  />
);

const StatusFormatter = ({ value }) => (
  <Status status={value}>{STATUS_MAP[value] || value}</Status>
);

export const StatusTypeProvider = props => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);

export const TagsTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value
        ? value.map(val => (
          <TagBadge
            key={val}
            language={props.language}
            tag={props.tags?.[val]}
          />
        ))
        : null
    }
    {...props}
  />
);

export const CategoriesTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value && value.length > 0
        ? value
          .map(val =>
            props?.categories?.[val] ? props.categories[val].title : '',
          )
          .join(', ')
        : null
    }
    {...props}
  />
);

export const EntityTypeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <span>
        <ItemTypeIcon type={value} />
        &nbsp;
        <ItemType type={value} />
      </span>
    )}
    {...props}
  />
);

export const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value, column, row }) => (
      <div>
        {moment(value).format('YYYY-MM-DD')}
        <br />
        {moment(value).format('hh:mm:ss')}
      </div>
    )}
    {...props}
  />
);

export const TranslationsTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value, column, row }) => (
      <div>
        {Object.entries(value).map(([key, val]) => {
          if (key === 'en' || key === 'settings') return null;
          return (
            <TranslationStatus {...val} key={key}>
              {key}
            </TranslationStatus>
          );
        })}
        {value.settings && value.settings.disablePA ? null : (
          <>
            <br />
            <TranslationStatus
              translated={value.en?.last_push != null ? 1 : 0}
              actual={value.en?.push_is_actual}
            >
              {value.en?.last_push === null
                ? 'Not pushed'
                : value.en?.push_is_actual === 1
                  ? 'Pushed and actual'
                  : 'Pushed but not actual'}
            </TranslationStatus>
          </>
        )}
      </div>
    )}
    {...props}
  />
);

export const ActionsTypeProvider = props => {
  const [, users] = useSocketUsers();
  return (
    <DataTypeProvider
      formatterComponent={({ value, row }) => {
        const item = row;
        if (!item.type) {
          item.type = props.type;
        }
        if (users && users[row.id]) {
          return <EditLock username={users[row.id].username} />;
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <Link
              to={urlBuilder({ language: props.language, item })}
              title="Edit"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </div>
        );
      }}
      {...props}
    />
  );
};

export const CustomActionsTypeProvider = ({ children, ...props }) => (
  <DataTypeProvider
    formatterComponent={({ value, row }) => (
      <div style={{ textAlign: 'center' }}>{children({ value, row })}</div>
    )}
    {...props}
  />
);

const rowStyle = {
  cursor: 'pointer',
  zIndex: 40,
};

export function TableRowWrapper(onRowClick = () => null) {
  return ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      row={row}
      // eslint-disable-next-line no-alert
      onClick={e => onRowClick(row, e)}
      style={Object.assign({ ...rowStyle }, restProps.style)}
    />
  );
}

export function rowClickHandler({ push, baseUrl, language }) {
  return (item, e) => {
    const url = item.type
      ? urlBuilder({ item, language })
      : `${baseUrl}${item.slug}`;

    if (e.ctrlKey) {
      window.open(url);
    } else {
      push(url);
    }
  };
}

export const MultilineTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value, column, row }) => (
      value ? value.map(v => <div>{v}</div>) : '-'
    )}
    {...props}
  />
);

export const globalTableColumnExtensions = [
  { columnName: 'media', width: 120, sortingEnabled: false },
  { columnName: 'status', width: 100 },
  { columnName: 'created', width: 100 },
  { columnName: 'updated', width: 100 },
  {
    columnName: 'translations_info',
    width: 200,
    sortingEnabled: false,
    filteringEnabled: false,
  },
  { columnName: 'actions', width: 120, sortingEnabled: false },
];
