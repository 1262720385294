/*
 *
 * AppMain actions
 *
 */

import {
  LOAD_GLOBAL_INFO,
  LOAD_GLOBAL_INFO_ERROR,
  LOAD_GLOBAL_INFO_SUCCESS,
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_SUCCESS,
  SET_EDIT_MODE,
  SET_TAG,
  TOGGLE_SEARCH,
  LOAD_CURRENT_USER,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_ERROR,
  LOGOUT_USER, SHOW_TOOLTIP, HIDE_TOOLTIP, TOOLTIP_LOADED, SET_PREVIEW_MODE,
} from './constants';

export function loadGlobal(reloadOnly = false) {
  return {
    type: LOAD_GLOBAL_INFO,
    reloadOnly,
  };
}

export const loadGlobalSuccess = payload => ({
  type: LOAD_GLOBAL_INFO_SUCCESS,
  payload,
});

export const loadGlobalError = error => ({
  type: LOAD_GLOBAL_INFO_ERROR,
  error,
});

export const loadCategories = (reloadOnly = false) => ({
  type: LOAD_CATEGORIES,
  reloadOnly,
});

export const loadCategoriesSuccess = payload => ({
  type: LOAD_CATEGORIES_SUCCESS,
  payload,
});

export const loadCategoriesError = error => ({
  type: LOAD_CATEGORIES_ERROR,
  error,
});

export const setTagAction = tag => ({
  type: SET_TAG,
  payload: tag,
});

export const setEditMode = editMode => ({
  type: SET_EDIT_MODE,
  payload: editMode,
});

export const toggleSearchAction = show => ({
  type: TOGGLE_SEARCH,
  payload: show,
});

export const loadCurrentUserAction = () => ({
  type: LOAD_CURRENT_USER,
});

export const loadCurrentUserSuccess = user => ({
  type: LOAD_CURRENT_USER_SUCCESS,
  payload: user,
});

export const loadCurrentUserError = error => ({
  type: LOAD_CURRENT_USER_ERROR,
  error,
});

export const logoutAction = () => ({
  type: LOGOUT_USER,
});

export const actionShowTooltip = (slug, x, y) => ({
  type: SHOW_TOOLTIP,
  slug,
  x,
  y,
});

export const actionHideTooltip = () => ({
  type: HIDE_TOOLTIP,
});

export const tooltipLoaded = (data, related) => ({
  type: TOOLTIP_LOADED,
  data,
  related,
});

export const setPreviewMode = val => ({
  type: SET_PREVIEW_MODE,
  preview: val,
});
