/*
 *
 * AdminTagPage constants
 *
 */

export const SAVE_TAG = 'app/AdminTagPage/SAVE_TAG';
export const SAVE_TAG_SUCCESS = 'app/AdminTagPage/SAVE_TAG_SUCCESS';
export const SAVE_TAG_ERROR = 'app/AdminTagPage/SAVE_TAG_ERROR';

export const DELETE_TAG = 'app/AdminTagPage/DELETE_TAG';

export const CHANGE_GUIDE_ORDER = 'app/AdminTagPage/CHANGE_GUIDE_ORDER';
export const SAVE_GUIDE_ORDER = 'app/AdminTagPage/SAVE_GUIDE_ORDER';
