/*
 *
 * GuidePage constants
 *
 */

export const LOAD_GUIDE = 'app/GuidePage/LOAD_GUIDE';
export const LOAD_GUIDE_SUCCESS = 'app/GuidePage/LOAD_GUIDE_SUCCESS';
export const LOAD_GUIDE_ERROR = 'app/GuidePage/LOAD_GUIDE_ERROR';

export const RELOAD_STEP = 'app/GuidePage/RELOAD_STEP';
export const RELOAD_STEP_SUCCESS = 'app/GuidePage/RELOAD_STEP_SUCCESS';

export const LOAD_STEPS = 'app/GuidePage/LOAD_STEPS';
export const LOAD_STEPS_SUCCESS = 'app/GuidePage/LOAD_STEPS_SUCCESS';
export const LOAD_STEPS_ERROR = 'app/GuidePage/LOAD_STEPS_ERROR';

export const INIT_EMPTY_GUIDE = 'app/admin/AdminGuidePage/INIT_EMPTY_GUIDE';
