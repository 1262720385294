/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import TagManager from 'react-gtm-module';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import ScrollToTop from 'components/ScrollToTop';
import AppMain from 'containers/AppMain';
import { RedirectPage } from '../RedirectPage';

import { loadCategories, loadCurrentUserAction, loadGlobal } from './actions';
import reducer from './reducer';
import saga from './saga';
import GlobalStyle from '../../global-styles';
import { makeSelectGlobal } from './selectors';

const theme = {
  colors: {
    main: '#e05d2d',
    lightGrey: '#e0e0e0',
    grey: '#B3B3B3',
    text: '#000000',
  },
  breakpoints: {
    mobile: '576px',
  },
};

const BodyStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.searchOpen ? 'hidden' : 'auto')}
  }
`;

const tagManagerArgs = {
  gtmId: 'GTM-MCSLPL6',
};

TagManager.initialize(tagManagerArgs);

export function App({ loadInfo, loadCats, loadCurrentUser, global}) {
  useInjectReducer({ key: 'main', reducer });
  useInjectSaga({ key: 'main', saga });

  useEffect(() => {
    if (!global.loaded && !global.loading) {
      loadInfo();
      loadCats();
      loadCurrentUser();
    }
  }, []);

  return (
    <div>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/g:id" component={RedirectPage} />
          {/* <Route path="/h:id" component={RedirectPage} /> */}
          <Route path="/:lang?" component={AppMain} />
        </Switch>
      </ThemeProvider>
      <GlobalStyle />
      <BodyStyle searchOpen={global.searchOpen} />
    </div>
  );
}

App.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  loadCats: PropTypes.func.isRequired,
  loadCurrentUser: PropTypes.func.isRequired,
  global: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadInfo: () => dispatch(loadGlobal()),
    loadCats: () => dispatch(loadCategories()),
    loadCurrentUser: () => dispatch(loadCurrentUserAction()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(App);
