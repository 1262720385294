import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../utils/constants/theme';

export const Tabs = styled.nav`
  border-bottom: 1px solid #ddd;
`;
export const Tab = styled(NavLink)`
  border: 1px solid #ddd;
  border-bottom: none;
  padding: 1em;
  cursor: pointer;
  margin: 0 5px;
  background-color: #ddd;
  display: inline-block;
  &.active,
  &:hover {
    color: ${theme.colors.main};
    background-color: #ffffff;
    margin-bottom: -1px;
    border-bottom: 1px solid #fff;
  }
`;
