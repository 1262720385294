import React from 'react';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { ButtonBig } from '../layout/Button';

import messages from './messages';

const AccessWrapper = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  margin: auto;
  min-height: 70vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface AccessDeniedProps {
  reason: 'ip' | 'employee';
}

function AccessDenied({ reason }: AccessDeniedProps) {
  return (
    <AccessWrapper>
      <p>
        <FormattedMessage {...messages.ipNotInInterval} />
        <br />
        {reason === 'ip' ? (
          <FormattedMessage {...messages.pleaseLogIn} />
        ) : (
          <FormattedMessage {...messages.notEmployee} />
        )}
      </p>
      {reason === 'ip' && (
        <ButtonBig as="a" href="/wp-login.php">
          Login
        </ButtonBig>
      )}
    </AccessWrapper>
  );
}

export default AccessDenied;
