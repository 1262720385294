import React from 'react';

function getTimeFromNumber(num) {
  const hours = Math.floor(num / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (num % 60).toString().padStart(2, '0');
  return [hours, minutes];
}

export function getUsersInCharge(users) {
  if (!users) return null;
  const selectedUsers = Object.values(users).map(entity => {
    if (!entity) return;
    const userInCharge = Object.values(entity).filter(user => user.canEdit);
    if (userInCharge.length === 1) {
      return { ...userInCharge[0] };
    }
  });

  // Array of objects into object.
  const usersInCharge = selectedUsers.reduce(
    (obj, item) =>
      item
        ? {
          ...obj,
          [item.articleId]: item,
        }
        : obj,
    {},
  );

  return usersInCharge || null;
}

export function formatTime(time) {
  if (Array.isArray(time)) {
    const [hours, minutes] = getTimeFromNumber(time[0]);
    const [hoursEnd, minutesEnd] = getTimeFromNumber(time[1]);
    return `${hours}:${minutes}:00 - ${hoursEnd}:${minutesEnd}:00`;
  }
  const [hours, minutes] = getTimeFromNumber(time);
  return `${hours}:${minutes}:00`;
}

export function checkForPreview(isAdmin, location) {
  return isAdmin && location.search.startsWith('?preview=true');
}

export function urlBuilder({ language, item, params = null }) {
  const baseUrl = `/${language}/`;
  let url = '';
  switch (item.type) {
    case 'manuals':
    case 'manual':
    case 'category':
      url = `${baseUrl}category/${item.slug}`;
      break;
    case 'helps':
    case 'help':
      url = `${baseUrl}article/${item.slug}`;
      break;
    case 'news':
    case 'new':
      url = `${baseUrl}news/${item.slug}`;
      break;
    case 'guides':
    case 'guide':
      url = `${baseUrl}guide/${item.slug}`;
      break;
    case 'tags':
    case 'post_tag':
    case 'tag':
      url = `${baseUrl}tag/${item.slug}`;
      break;
    case 'tooltips':
    case 'tooltip':
      url = `${baseUrl}glossary/${item.slug}`;
      break;
    case 'relation':
    case 'relations':
      url = `${baseUrl}relation/${item.slug}`;
      break;
    case 'department':
      url = `${baseUrl}departments/${item.slug}`;
      break;
    default:
      url = `${baseUrl}404`;
      break;
  }
  if (params) {
    url += `?${params}`;
  }
  return url;
}

export function findCatById(categories, id) {
  let found = false;
  if (!categories) return false;
  categories.forEach(cat => {
    if (cat.id === id) {
      found = cat;
    } else if (cat.childrens && cat.childrens.length > 0) {
      const f = findCatById(cat.childrens, id);
      if (f) found = f;
    }
  });
  return found;
}

export function getCatChain(categories, slug) {
  const parts = slug?.split('_');
  if (!parts) return [];
  const id = parseInt(parts[parts.length - 1], 0);
  return getCatChainById(categories, id);
}

export function getCatChainById(categories, id) {
  let found = [];
  if (!categories) return [];
  categories.forEach(cat => {
    if (cat.id === id) {
      found.push(cat);
    } else if (cat.childrens && cat.childrens.length > 0) {
      const f = getCatChainById(cat.childrens, id);
      if (f.length > 0) {
        f.push(cat);
        found = f;
      }
    }
  });
  return found;
}

export const scrollWithOffset = (el, offset, smooth = true, pure = false) => {
  if (el) {
    const elementPosition =
      (pure ? el.getBoundingClientRect().top : el.offsetTop) - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};

export function slugify(string) {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function reorder(data, idx, idx2) {
  if (idx < idx2) {
    data.splice(idx2 + 1, 0, data[idx]);
    data.splice(idx, 1);
  } else {
    data.splice(idx2, 0, data[idx]);
    data.splice(idx + 1, 1);
  }
  return data.map((item, i) => ({ ...item, order: i }));
}

export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function serialize(mixedValue) {
  //  discuss at: https://locutus.io/php/serialize/
  // original by: Arpad Ray (mailto:arpad@php.net)
  // improved by: Dino
  // improved by: Le Torbi (https://www.letorbi.de/)
  // improved by: Kevin van Zonneveld (https://kvz.io/)
  // bugfixed by: Andrej Pavlovic
  // bugfixed by: Garagoth
  // bugfixed by: Russell Walker (https://www.nbill.co.uk/)
  // bugfixed by: Jamie Beck (https://www.terabit.ca/)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io/)
  // bugfixed by: Ben (https://benblume.co.uk/)
  // bugfixed by: Codestar (https://codestarlive.com/)
  // bugfixed by: idjem (https://github.com/idjem)
  //    input by: DtTvB (https://dt.in.th/2008-09-16.string-length-in-bytes.html)
  //    input by: Martin (https://www.erlenwiese.de/)
  //      note 1: We feel the main purpose of this function should be to ease
  //      note 1: the transport of data between php & js
  //      note 1: Aiming for PHP-compatibility, we have to translate objects to arrays
  //   example 1: serialize(['Kevin', 'van', 'Zonneveld'])
  //   returns 1: 'a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}'
  //   example 2: serialize({firstName: 'Kevin', midName: 'van'})
  //   returns 2: 'a:2:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";}'
  //   example 3: serialize( {'ü': 'ü', '四': '四', '𠜎': '𠜎'})
  //   returns 3: 'a:3:{s:2:"ü";s:2:"ü";s:3:"四";s:3:"四";s:4:"𠜎";s:4:"𠜎";}'

  let val;
  let key;
  let okey;
  let ktype = '';
  let vals = '';
  let count = 0;

  const _utf8Size = function (str) {
    return ~-encodeURI(str).split(/%..|./).length;
  };

  const _getType = function (inp) {
    let match;
    let key;
    let cons;
    let types;
    let type = typeof inp;

    if (type === 'object' && !inp) {
      return 'null';
    }

    if (type === 'object') {
      if (!inp.constructor) {
        return 'object';
      }
      cons = inp.constructor.toString();
      match = cons.match(/(\w+)\(/);
      if (match) {
        cons = match[1].toLowerCase();
      }
      types = ['boolean', 'number', 'string', 'array'];
      for (key in types) {
        if (cons === types[key]) {
          type = types[key];
          break;
        }
      }
    }
    return type;
  };

  const type = _getType(mixedValue);

  switch (type) {
    case 'function':
      val = '';
      break;
    case 'boolean':
      val = `b:${mixedValue ? '1' : '0'}`;
      break;
    case 'number':
      val = `${Math.round(mixedValue) === mixedValue ? 'i' : 'd'
        }:${mixedValue}`;
      break;
    case 'string':
      val = `s:${_utf8Size(mixedValue)}:"${mixedValue}"`;
      break;
    case 'array':
    case 'object':
      val = 'a';
      /*
      if (type === 'object') {
        var objname = mixedValue.constructor.toString().match(/(\w+)\(\)/);
        if (objname === undefined) {
          return;
        }
        objname[1] = serialize(objname[1]);
        val = 'O' + objname[1].substring(1, objname[1].length - 1);
      }
      */

      for (key in mixedValue) {
        if (mixedValue.hasOwnProperty(key)) {
          ktype = _getType(mixedValue[key]);
          if (ktype === 'function') {
            continue;
          }

          okey = key.match(/^[0-9]+$/) ? parseInt(key, 10) : key;
          vals += serialize(okey) + serialize(mixedValue[key]);
          count++;
        }
      }
      val += `:${count}:{${vals}}`;
      break;
    case 'undefined':
    default:
      // Fall-through
      // if the JS object has a property which contains a null value,
      // the string cannot be unserialized by PHP
      val = 'N';
      break;
  }
  if (type !== 'object' && type !== 'array') {
    val += ';';
  }

  return val;
}

/*
 * "22,54" => [22,54]
 * "18" => 18
 */
export function parseTimeRange(time) {
  return time.indexOf(',') > 0
    ? time.split(',').map(i => parseInt(i, 0))
    : parseInt(time, 0) || 0;
}

export function flattenCategories(categories) {
  let result = [];
  categories.forEach(cat => {
    result.push(cat);
    if (cat.childrens && cat.childrens.length) {
      result = result.concat(flattenCategories(cat.childrens));
    }
  });
  return result;
}

/**
 * debounce(func, [wait=0], [options={}])
 *
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0] The number of milliseconds to delay.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=false] Specify invoking on the leading edge of the timeout.
 * @param {cancelObj} [options.cancelObj='canceled'] Specify the error object to be rejected.
 * @returns {Function} Returns the new debounced function.
 */
export function debounce(
  func,
  wait = 0,
  { leading = false, cancelObj = 'canceled' } = {},
) {
  let timerId;
  let latestResolve;
  let shouldCancel;

  return function (...args) {
    if (!latestResolve) {
      // The first call since last invocation.
      return new Promise((resolve, reject) => {
        latestResolve = resolve;
        if (leading) {
          invokeAtLeading.apply(this, [args, resolve, reject]);
        } else {
          timerId = setTimeout(
            invokeAtTrailing.bind(this, args, resolve, reject),
            wait,
          );
        }
      });
    }

    shouldCancel = true;
    return new Promise((resolve, reject) => {
      latestResolve = resolve;
      timerId = setTimeout(
        invokeAtTrailing.bind(this, args, resolve, reject),
        wait,
      );
    });
  };

  function invokeAtLeading(args, resolve, reject) {
    func
      .apply(this, args)
      .then(resolve)
      .catch(reject);
    shouldCancel = false;
  }

  function invokeAtTrailing(args, resolve, reject) {
    if (shouldCancel && resolve !== latestResolve) {
      reject(cancelObj);
    } else {
      func
        .apply(this, args)
        .then(resolve)
        .catch(reject);
      shouldCancel = false;
      clearTimeout(timerId);
      timerId = null;
      latestResolve = null;
    }
  }
}

/**
 * Throttle execution of a function. Especially useful for rate limiting
 * execution of handlers on events like resize and scroll.
 *
 * @param  {Number}    delay          A zero-or-greater delay in milliseconds. For event callbacks, values around 100 or 250 (or even higher) are most useful.
 * @param  {Boolean}   [noTrailing]   Optional, defaults to false. If noTrailing is true, callback will only execute every `delay` milliseconds while the
 *                                    throttled-function is being called. If noTrailing is false or unspecified, callback will be executed one final time
 *                                    after the last throttled-function call. (After the throttled-function has not been called for `delay` milliseconds,
 *                                    the internal counter is reset)
 * @param  {Function}  callback       A function to be executed after delay milliseconds. The `this` context and all arguments are passed through, as-is,
 *                                    to `callback` when the throttled-function is executed.
 * @param  {Boolean}   [debounceMode] If `debounceMode` is true (at begin), schedule `clear` to execute after `delay` ms. If `debounceMode` is false (at end),
 *                                    schedule `callback` to execute after `delay` ms.
 *
 * @return {Function}  A new, throttled, function.
 */
export function throttle(delay, noTrailing, callback, debounceMode) {
  /*
   * After wrapper has stopped being called, this timeout ensures that
   * `callback` is executed at the proper times in `throttle` and `end`
   * debounce modes.
   */
  let timeoutID;
  let cancelled = false;

  // Keep track of the last time `callback` was executed.
  let lastExec = 0;

  // Function to clear existing timeout
  function clearExistingTimeout() {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  }

  // Function to cancel next exec
  function cancel() {
    clearExistingTimeout();
    cancelled = true;
  }

  // `noTrailing` defaults to falsy.
  if (typeof noTrailing !== 'boolean') {
    debounceMode = callback;
    callback = noTrailing;
    noTrailing = undefined;
  }

  /*
   * The `wrapper` function encapsulates all of the throttling / debouncing
   * functionality and when executed will limit the rate at which `callback`
   * is executed.
   */
  function wrapper() {
    const self = this;
    const elapsed = Date.now() - lastExec;
    const args = arguments;

    if (cancelled) {
      return;
    }

    // Execute `callback` and update the `lastExec` timestamp.
    function exec() {
      lastExec = Date.now();
      callback.apply(self, args);
    }

    /*
     * If `debounceMode` is true (at begin) this is used to clear the flag
     * to allow future `callback` executions.
     */
    function clear() {
      timeoutID = undefined;
    }

    if (debounceMode && !timeoutID) {
      /*
       * Since `wrapper` is being called for the first time and
       * `debounceMode` is true (at begin), execute `callback`.
       */
      exec();
    }

    clearExistingTimeout();

    if (debounceMode === undefined && elapsed > delay) {
      /*
       * In throttle mode, if `delay` time has been exceeded, execute
       * `callback`.
       */
      exec();
    } else if (noTrailing !== true) {
      /*
       * In trailing throttle mode, since `delay` time has not been
       * exceeded, schedule `callback` to execute `delay` ms after most
       * recent execution.
       *
       * If `debounceMode` is true (at begin), schedule `clear` to execute
       * after `delay` ms.
       *
       * If `debounceMode` is false (at end), schedule `callback` to
       * execute after `delay` ms.
       */
      timeoutID = setTimeout(
        debounceMode ? clear : exec,
        debounceMode === undefined ? delay - elapsed : delay,
      );
    }
  }

  wrapper.cancel = cancel;

  // Return the wrapper function.
  return wrapper;
}

export function printObject(obj, depth = 0) {
  if (obj === undefined || obj === null) return 'empty';
  if (typeof obj === typeof true) return obj ? 'Yes' : 'No';
  if (typeof obj !== 'object') return obj;

  return (
    <div style={{ paddingLeft: `${depth * 10}px` }}>
      {'{'}
      {Object.entries(obj).map(([key, val]) => (
        <div key={key} style={{ paddingLeft: `${depth * 10 + 5}px` }}>
          {key}: {printObject(val, depth + 1)}
        </div>
      ))}
      {'}'}
    </div>
  );
}
