/*
 *
 * AdminCategoryPage constants
 *
 */

export const SAVE_CATEGORY = 'app/AdminCategoryPage/SAVE_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = 'app/AdminCategoryPage/SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_ERROR = 'app/AdminCategoryPage/SAVE_CATEGORY_ERROR';

export const DELETE_CATEGORY = 'app/AdminCategoryPage/DELETE_CATEGORY';

export const CHANGE_GUIDE_ORDER = 'app/AdminCategoryPage/CHANGE_GUIDE_ORDER';
export const SAVE_GUIDE_ORDER = 'app/AdminCategoryPage/SAVE_GUIDE_ORDER';
