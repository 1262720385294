import styled from 'styled-components';

export default styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2%;
  a {
    transition: color 500ms;
    color: #333;
    font-weight: bold;
  }
  ${({ active = false }) => active && `a { color: #fa6831; }`}
  a:hover {
    color: #fa6831;
  }
`;
