import { Field } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel } from 'components/admin/layout';

function StatusField({
  name = 'status',
  label = 'Status',
  values,
  setFieldValue,
  isAdmin = false,
}) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Field component="select" name={name} disabled={!isAdmin}>
        <option value="publish">Public</option>
        <option value="private">Private</option>
        <option value="password">Password</option>
      </Field>
      {values[name] === 'password' && (
        <input
          onChange={e => setFieldValue('password', e.target.value)}
          value={values.password}
        />
      )}
    </div>
  );
}

StatusField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object,
  isAdmin: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
};

export default StatusField;
