/*
 *
 * CategoryPage reducer
 *
 */
import produce from 'immer';
import { combineReducers } from 'redux';
import { CHANGE_GUIDE_ORDER } from 'containers/admin/AdminCategoryPage/constants';
import { reorder } from 'utils/helpers';
import {
  LOAD_ARTICLES_SUCCESS,
  LOAD_ARTICLES_ERROR,
  LOAD_ARTICLES,
  LOAD_GUIDES_SUCCESS,
  LOAD_GUIDES_ERROR,
  LOAD_GUIDES,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  currentCategory: null,
  data: [],
  totalPages: 0,
  totalItems: 0,
};

/* eslint-disable default-case, no-param-reassign */
const categoryArticlesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ARTICLES:
        if (draft.currentCategory !== action.category || action.clear || action.page === 1) {
          draft.data = [];
        }
        draft.currentCategory = action.category;
        draft.loading = true;
        break;
      case LOAD_ARTICLES_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.loaded = true;
        draft.data = [];
        break;
      case LOAD_ARTICLES_SUCCESS:
        draft.loading = false;
        draft.error = false;
        if (action.payload !== null) {
          draft.data = draft.data.concat(action.payload);
        }
        draft.totalItems = action.totalItems;
        draft.totalPages = action.totalPages;
        break;
    }
  });

export const initialGuidesState = {
  loading: false,
  error: false,
  currentCategory: null,
  data: [],
  dataByCat: {},
};

/* eslint-disable default-case, no-param-reassign */
const categoryGuidesReducer = (state = initialGuidesState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_GUIDES:
        draft.currentCategory = action.category;
        draft.loading = true;
        draft.data = [];
        break;
      case LOAD_GUIDES_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
      case LOAD_GUIDES_SUCCESS:
        draft.loading = false;
        draft.error = false;
        let {payload} = action;
        if (payload && typeof payload === 'object') {
          payload = Object.values(payload);
        }
        draft.data = payload;
        draft.dataByCat = payload ? payload.reduce(
          (acc, val) => {
            if (val.categories && val.categories.length) {
              if (!val.categories || val.categories.length === 0) {
                acc.uncategorized.push(val);
              } else {
                if (!acc[val.categories[0]]) {
                  acc[val.categories[0]] = [];
                }
                acc[val.categories[0]].push(val);
              }
            }
            return acc;
          },
          { uncategorized: [] },
        ) : {};
        break;
      case CHANGE_GUIDE_ORDER:
        draft.data = reorder(draft.data, action.idx, action.idx2);
        break;
    }
  });

export default combineReducers({
  articles: categoryArticlesReducer,
  guides: categoryGuidesReducer,
});
