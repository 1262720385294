/*
 *
 * AdminNewPage constants
 *
 */

export const SAVE_NEW = 'app/AdminNewPage/SAVE_NEW';
export const SAVE_NEW_SUCCESS = 'app/AdminNewPage/SAVE_NEW_SUCCESS';
export const SAVE_NEW_ERROR = 'app/AdminNewPage/SAVE_NEW_ERROR';

export const DELETE_NEW = 'app/AdminNewPage/DELETE_NEW';
export const DELETE_NEW_SUCCESS = 'app/AdminNewPage/DELETE_NEW_SUCCESS';
export const DELETE_NEW_ERROR = 'app/AdminNewPage/DELETE_NEW_ERROR';
