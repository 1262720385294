export default {
  TEXT: [
    {
      name: 'Text',
      type: 'text',
      option: {
        type: 'textbox',
        text: 'Place your text here',
        width: 60,
        height: 30,
        fontSize: 32,
        name: 'New text',
      },
    },
  ],
  IMAGE: [
    {
      name: 'Image',
      type: 'image',
      option: {
        type: 'image',
        width: 160,
        height: 160,
        name: 'New image',
        src: '/images/sample/transparentBg.png',
      },
    },
  ],
  SHAPE: [
    {
      name: 'Triangle',
      type: 'shape',
      option: {
        type: 'triangle',
        width: 30,
        height: 30,
        name: 'New shape',
      },
    },
    {
      name: 'Rectangle',
      type: 'shape',
      option: {
        type: 'rect',
        width: 40,
        height: 40,
        name: 'New shape',
      },
    },
    {
      name: 'Circle',
      type: 'shape',
      option: {
        type: 'circle',
        radius: 30,
        name: 'New shape',
      },
    },
    {
      name: 'Arrow Circle',
      type: 'shape',
      option: {
        type: 'circleArrow',
        radius: 30,
        name: 'New shape',
      },
    },
    {
      name: 'Numbered Circle',
      type: 'shape',
      option: {
        type: 'numberedCircle',
        radius: 20,
        name: 'New shape',
      },
    },
  ],
  DRAWING: [
    {
      name: 'Line',
      type: 'drawing',
      option: {
        type: 'line',
        name: 'New line',
      },
    },
    {
      name: 'Arrow',
      type: 'drawing',
      option: {
        type: 'arrow',
        name: 'New arrow',
      },
    },
    {
      name: 'Measure',
      type: 'drawing',
      option: {
        type: 'measure',
        name: 'Measure',
      },
    },
  ],
};
