import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { Col, ImgPlaceholder } from 'components/layout';
import Media from 'components/Media';
import { generateHoverHandler } from 'utils/formUtils';
import ImageMeta, { Meta } from 'containers/MediaGallery/ImageMeta';

export const Column = styled(Col)`
  &:hover ${Meta} {
    display: flex;
  }
`;

function GalleryImage({ width, img, moveImage, index, cloneImage, editImage, onHover = () => null }) {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'img',
    hover: generateHoverHandler({ ref, callback: moveImage, index }),
    drop: () => null,
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'img', id: img ? img.id : index, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  if (!img) {
    return (
      <Col cols={4} noPadding>
        <ImgPlaceholder />
      </Col>
    );
  }

  return (
    <Column cols={4} ref={ref} style={{ opacity }} onMouseOver={() => onHover(img)} noPadding>
      <ImageMeta img={img} vertical onCloneImage={cloneImage} setImgToEdit={editImage} />
      <Media image={img} style={{ width }} border />
    </Column>
  );
}

GalleryImage.propTypes = {
  width: PropTypes.number,
  img: PropTypes.object,
  moveImage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default GalleryImage;
