/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AppHeader';

export default defineMessages({
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Login',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  myProfile: {
    id: `${scope}.myProfile`,
    defaultMessage: 'Prusa Account',
  },
  myProfileIntranet: {
    id: `${scope}.myProfileIntranet`,
    defaultMessage: 'Intranet profile',
  },
});
