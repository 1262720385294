/*
 *
 * AdminTagPage actions
 *
 */

import {
  SAVE_CATEGORY,
  SAVE_CATEGORY_ERROR,
  SAVE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  CHANGE_GUIDE_ORDER,
  SAVE_GUIDE_ORDER,
} from './constants';

export const saveCategoryAction = (id, category, redirect) => ({
  type: SAVE_CATEGORY,
  id,
  category,
  redirect,
});

export const saveCategorySuccess = payload => ({
  type: SAVE_CATEGORY_SUCCESS,
  payload,
});

export const saveCategoryError = error => ({
  type: SAVE_CATEGORY_ERROR,
  error,
});

export const changeGuideOrderAction = (idx, idx2) => ({
  type: CHANGE_GUIDE_ORDER,
  idx,
  idx2,
});

export const saveGuidesOrderAction = () => ({
  type: SAVE_GUIDE_ORDER,
});

export const deleteCategoryAction = id => ({
  type: DELETE_CATEGORY,
  id,
});
