import React from 'react';
import PropTypes from 'prop-types';
import MainFooter from '@prusa3d-platform/prusa-main-footer';

function AppFooter({ currentLang }) {
  return (
    <MainFooter
      locale={currentLang}
      config={{
        website: 'intranet',
        maxWidth: '93.4286rem',
      }}
    />
  );
}

AppFooter.propTypes = {
  currentLang: PropTypes.string.isRequired,
};

export default AppFooter;
