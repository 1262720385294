/*
 *
 * AdminGuidePage constants
 *
 */

export const SAVE_GUIDE = 'app/AdminGuidePage/SAVE_GUIDE';
export const SAVE_GUIDE_SUCCESS = 'app/AdminGuidePage/SAVE_GUIDE_SUCCESS';
export const SAVE_GUIDE_ERROR = 'app/AdminGuidePage/SAVE_GUIDE_ERROR';

export const DELETE_GUIDE = 'app/AdminGuidePage/DELETE_GUIDE';

export const CHANGE_STEP_ORDER = 'app/AdminGuidePage/CHANGE_STEP_ORDER';
export const SAVE_STEP_ORDER = 'app/AdminGuidePage/SAVE_STEP_ORDER';
