import { connect } from 'formik';

export function validateTitle(value) {
  let error;
  if (!value) {
    error = 'Title is required!';
  }
  return error;
}

export function generateHoverHandler({ ref, index, callback, parent }) {
  return (item, monitor) => {
    if (!ref.current) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = index;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = ref.current.getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
    const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
    const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
    const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

    if (upwards && (leftwards || rightwards)) {
      return;
    }

    if (downwards && (leftwards || rightwards)) {
      return;
    }
    // Time to actually perform the action
    callback(dragIndex, hoverIndex, parent);
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex;
  };
}

export function getFullTagBySlug(items) {
  return tag => {
    if (items[tag]) {
      return {
        id: items[tag].id,
        name: items[tag].name,
      };
    }
    return {};
  };
}

export function getFullCategoryBySlug(categories) {
  return cat => {
    if (categories[cat]) {
      return {
        id: categories[cat].id,
        name: categories[cat].title,
        parent: categories[cat].parent,
      };
    }
    return {};
  };
}

function Effect({ formik, onChange, anyChange }) {
  if (!anyChange && formik.dirty) {
    onChange(true);
  }
  if (anyChange && !formik.dirty) {
    onChange(false);
  }
  return null;
}

export const FormikEffect = connect(Effect);
