import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import { Image } from '../layout';
import { urlBuilder } from '../../utils/helpers';
import { themeColor } from '../../utils/themeHelpers';
import { StatusBadge } from '../layout';
import TranslationStatus from '../TranslationStatus';

export const Text = styled.div`
  color: #333;
  font-size: 1em;
  padding: 0 1em;
  text-align: left;
`;

export const Article = styled.article`
  position: relative;
  text-align: center;
  width: calc((100% - 2em) / 3);
  margin-right: 1em;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid ${themeColor('lightGrey')};

  @media screen and (min-width: 48em) {
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 32em) and (max-width: 48em) {
    width: calc((100% - 1em) / 2);
    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 32em) {
    width: 100%;
    margin-right: 0;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  transition: border 500ms;
  border-radius: 4px;
  &:hover {
    border-color: #fa6831;
  }
`;

function Guide({ title, content, status, translation_status, media, slug, language }) {
  return (
    <Article>
      {status === 'private' && <StatusBadge status={status} />}
      <StyledLink to={urlBuilder({ language, item: { slug, type: 'guides' } })}>
        <Text>
          <h4>{parse(title)}<TranslationStatus language={language} status={translation_status} /></h4>
          {/* {content} */}
        </Text>
        {media && media.image && (
          <Image
            image={media.image}
            size="thumbnail"
            alt={title}
            style={{
              borderBottomRightRadius: '4px',
              borderTopRightRadius: '4px',
              width: '100px',
              minWidth: '100px',
              margin: 'auto !important',
            }}
          />
        )}
      </StyledLink>
    </Article>
  );
}

Guide.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  media: PropTypes.object,
};

export default Guide;
