/**
 *
 * AdminSection
 *
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { createStructuredSelector } from 'reselect';

import { Grid, Wrapper, Col, WhiteBar } from 'components/layout';
import AdminMenu from 'components/admin/AdminMenu';
import AdminHomePage from 'containers/admin/AdminHomePage/Loadable';
import AdminArticlePage from 'containers/admin/AdminArticlePage/Loadable';
import AdminTagPage from 'containers/admin/AdminTagPage/Loadable';
import AdminTagsPage from 'containers/admin/AdminTagsPage/Loadable';
import AdminRelationPage from 'containers/admin/AdminRelationPage/Loadable';
import AdminRelationsPage from 'containers/admin/AdminRelationsPage/Loadable';
import AdminTooltipPage from 'containers/admin/AdminTooltipPage/Loadable';
import AdminTranslationsPage from 'containers/admin/AdminTranslationsPage/Loadable';
import AdminTooltipsPage from 'containers/admin/AdminTooltipsPage/Loadable';
import AdminCategoryPage from 'containers/admin/AdminCategoryPage/Loadable';
import AdminCategoriesPage from 'containers/admin/AdminCategoriesPage/Loadable';
import AdminGuidePage from 'containers/admin/AdminGuidePage/Loadable';
import SearchInput from 'containers/Search';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import {
  makeSelectAdminFlag,
  makeSelectGlobal,
  makeSelectUserRoles,
} from 'containers/App/selectors';
import { notifyError } from 'utils/notifications';
import { useInjectReducer } from 'utils/injectReducer';
import getSocket from 'utils/socketClient';
import { ROLES } from 'utils/constants';
import { useCheckRole } from 'utils/hooks';
import Loader from '../../../components/Loader';
import AdminNewPage from '../AdminNewPage';
import AdminNewsPage from '../AdminNewsPage';

import GlobalStyle from './global-styles';
import reducer from './reducer';

import(/* webpackPrefetch: true */ '@tinymce/tinymce-react');

const ColumnHidden = styled(Col)`
  @media print {
    display: none;
  }
`;

const Column = styled(Col)`
  padding-bottom: 1em;
  margin-bottom: 0;
  @media print {
    width: 100%;
  }
`;

function AdminSection({
  match,
  global: { loading, userLoaded },
  roles,
  history,
  language,
}) {
  useInjectReducer({ key: 'adminSection', reducer });

  const { isInAnyRole, isInRole } = useCheckRole(roles);
  const isAdmin = isInAnyRole([ROLES.ADMIN, ROLES.AUTHOR]);

  useEffect(() => {
    if (userLoaded && !loading && !isAdmin) {
      history.push({ pathname: '/' });
      return false;
    }
    if (userLoaded && !loading && isInRole(ROLES.AUTHOR) && language !== 'en') {
      // Force EN lang for authors
      notifyError("You don't have access to other languages", 'Unauthorized');
      history.push({ pathname: '/en/admin' });
      return false;
    }
    // checkAuth();
  }, [isAdmin, userLoaded, loading, language]);

  useEffect(() => {
    const socket = getSocket();
    socket.emit('editors');
    return () => {
      socket.off('editors');
    };
  }, []);

  if (loading && !userLoaded) {
    return <Loader fullPage />;
  }

  return (
    <div>
      <Wrapper width={1600}>
        <ReactNotification />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SearchInput inAdmin />
        </div>
      </Wrapper>
      <WhiteBar borderTop style={{ paddingBottom: 0 }}>
        <div style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid noMargin>
            <ColumnHidden
              cols={2}
              style={{ marginTop: 0, marginBottom: 0, padding: 0 }}
            >
              <AdminMenu language={match.params.lang} />
            </ColumnHidden>
            <Column cols={10}>
              <Switch>
                <Route
                  exact
                  path={`${match.path}/`}
                  component={AdminHomePage}
                />
                <Route
                  path={`${match.path}/tag/:tag`}
                  component={AdminTagPage}
                />
                <Route path={`${match.path}/tags`} component={AdminTagsPage} />
                <Route
                  path={`${match.path}/relations`}
                  component={AdminRelationsPage}
                />
                <Route
                  path={`${match.path}/relation/:relation`}
                  component={AdminRelationPage}
                />
                <Route
                  path={`${match.path}/tooltips`}
                  component={AdminTooltipsPage}
                />
                <Route
                  path={`${match.path}/glossary/:slug`}
                  component={AdminTooltipPage}
                />
                <Route
                  path={`${match.path}/guide/:slug`}
                  component={AdminGuidePage}
                />
                <Route
                  path={`${match.path}/categories`}
                  component={AdminCategoriesPage}
                />
                <Route
                  path={`${match.path}/category/:category`}
                  component={AdminCategoryPage}
                />
                <Route
                  exact
                  path={`${match.path}/news`}
                  component={AdminNewsPage}
                />
                <Route
                  path={`${match.path}/news/:slug`}
                  component={AdminNewPage}
                />
                <Route
                  path={`${match.path}/article/:slug`}
                  component={AdminArticlePage}
                />
                <Route
                  path={`${match.path}/translations`}
                  component={AdminTranslationsPage}
                />
              </Switch>
              <GlobalStyle />
            </Column>
          </Grid>
        </div>
      </WhiteBar>
    </div>
  );
}

AdminSection.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  global: PropTypes.object,
  history: PropTypes.object,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAdmin: makeSelectAdminFlag(),
  global: makeSelectGlobal(),
  language: makeSelectLocale(),
  roles: makeSelectUserRoles(),
});

export default connect(
  mapStateToProps,
  null,
)(AdminSection);
