import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  textarea,
  input {
    outline: none;
    border: 1px solid #e3e3e3;
    padding: 0.3em 0.5em;
    max-width: 100%;
  }

  .MuiDialog-paperFullWidth {
    height: 100%;
  }

  // React tags input
  .react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
  }

  .react-tags.is-focused {
    border-color: #B1B1B1;
  }

  .react-tags__selected {
    display: inline;
  }

  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__selected-tag:after {
    content: '\\2715';
    color: #AAA;
    margin-left: 8px;
  }

  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #B1B1B1;
  }

  .react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }

  @media screen and (min-width: 30em) {

    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }

  }

  .react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  @media screen and (min-width: 30em) {

    .react-tags__suggestions {
      width: 240px;
    }

  }

  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }

  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }

  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }

  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }

  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  // Grid
  .rt-tr {
    cursor: pointer;
  }
  .mce-content-body.mce-edit-focus {
    //min-height: 90px;
  }
  .MuiTableCell-root {
    padding: 4px !important;
  }

  // tinymce
  .tox:not([dir=rtl]) .tox-statusbar__branding {
    display: none;
  }
`;

export default GlobalStyle;
