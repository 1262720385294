import { fabric } from 'fabric';
import Line from './Line';

const Measure = fabric.util.createClass(Line, {
  type: 'measure',
  superType: 'drawing',
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.save();
    ctx.beginPath();
    const p = this.calcLinePoints();
    ctx.moveTo(p.x1, p.y1);
    ctx.lineTo(p.x2, p.y2);
    const xDiff = this.x2 - this.x1;
    const yDiff = this.y2 - this.y1;
    const angle = Math.atan2(yDiff, xDiff);
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
    ctx.rotate(angle);
    // ctx.beginPath();
    const arrowLength = 120; // Math.max(this.width, this.height);
    const realLength = Math.sqrt(this.width*this.width + this.height*this.height);
    ctx.moveTo(0, -arrowLength / 12);
    ctx.lineTo(0, arrowLength / 12);
    ctx.moveTo(-realLength, -arrowLength / 12);
    ctx.lineTo(-realLength, arrowLength / 12);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = oldStyle;
    ctx.stroke();
    ctx.restore();
  },
  toSVG() {
    const label = new fabric.Line([this.left, this.top, 50, 80], {
      originX: 'center',
      originY: 'center',
      stroke: this.stroke,
      strokeWidth: this.strokeWidth,
    });
    // return this.callSuper('toSVG') + label.toSVG();
    return this.callSuper('toSVG') + label.toSVG();
  },
});

Measure.fromObject = function(object, callback, forceAsync) {
  // return fabric.Object._fromObject('Measure', object, callback, forceAsync);
  callback &&
    callback(
      new fabric.Measure([object.x1, object.y1, object.x2, object.y2], object),
    );
};

fabric.Measure = Measure;

export default Measure;
