/*
 * GuidePage Messages
 *
 * This contains all the text for the GuidePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Step';

export default defineMessages({
  comments: {
    id: `${scope}.comments`,
    defaultMessage: 'comments',
  },
  addComment: {
    id: `${scope}.addComment`,
    defaultMessage: 'Add a comment',
  },
  step: {
    id: `${scope}.step`,
    defaultMessage: 'Step',
  },
});
