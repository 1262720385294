/*
 *
 * AdminSection actions
 *
 */

import { SET_LOADING, SET_SAVED } from './constants';

export const setLoading = value => ({
  type: SET_LOADING,
  value,
});

export const setSaved = value => ({
  type: SET_SAVED,
  value,
});
