import { useEffect, useState } from 'react';
import { useModal } from './hooks';
import getSocket from './socketClient';
import request from './request';
import { getUsersInCharge } from './helpers';
import { notifyError, notifySuccess } from './notifications';

export function useSocketUsers() {
  const socket = getSocket();
  const [whoCanEdit, setWhoCanEdit] = useState(null);
  useEffect(() => {
    socket.on('editors', data => {
      const usersInCharge = getUsersInCharge(data);
      setWhoCanEdit(usersInCharge);
    });
  }, []);

  return [socket, whoCanEdit];
}

export function useEditableViaSocket(user, data, callback) {
  const [socket, whoCanEdit] = useSocketUsers();
  const [isPrompted, setIsPrompted] = useState(false);

  useEffect(() => {
    if (data.id && user?.userid) {
      const userInCharge = whoCanEdit?.[data.id];
      if (userInCharge && userInCharge.userId !== user.userid) {
        callback(true);
        !isPrompted &&
        window.alert(
          `User ${userInCharge.username
          } is currently editing this entity. You can view it just in read-only mode.`,
        );
        setIsPrompted(true);
      } else {
        callback(false);
      }
    }
  }, [whoCanEdit, data, user]);

  useEffect(() => {
    if (user && user.userid && data.id) {
      socket.emit('enter', {
        userId: user.userid,
        username: user.fullname || user.username,
        articleId: parseInt(data.id, 0),
      });
    }
    return () => {
      if (user && user.userid && data.id) {
        socket.emit('leave', {
          userId: user.userid,
          articleId: parseInt(data.id, 0),
        });
      }
    };
  }, [user, data]);
}

export function useModalEditors() {
  const [tagDialogOpen, openTagDialog, closeTagDialog] = useModal(false);
  const [
    categoryDialogOpen,
    openCategoryDialog,
    closeCategoryDialog,
  ] = useModal(false);
  const [
    relationDialogOpen,
    openRelationDialog,
    closeRelationDialog,
  ] = useModal(false);

  return [
    tagDialogOpen,
    openTagDialog,
    closeTagDialog,
    categoryDialogOpen,
    openCategoryDialog,
    closeCategoryDialog,
    relationDialogOpen,
    openRelationDialog,
    closeRelationDialog,
  ];
}

export function usePhraseapp(pushUrl) { //, pullUrl
  const [isLoading, setIsLoading] = useState(false);

  function pushToPhraseapp() {
    setIsLoading(true);
    request(`v1/upload_translations/?${pushUrl}`)
      .then(() => {
        setIsLoading(false);
        notifySuccess('Pushed to phraseapp', 'Success', 0);
      })
      .catch(() => {
        setIsLoading(false);
        notifyError('Error pushing to phraseapp', 'Error', 0);
      });
  }

  return {
    pushCallback: pushToPhraseapp,
    // pullCallback: pullFromPhraseapp,
    isLoading,
  };
}
