/*
 *
 * ArticlePage reducer
 *
 */
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  LOAD_ARTICLE_ERROR,
  LOAD_ARTICLE,
  LOAD_ARTICLE_SUCCESS,
  INIT_EMPTY_ARTICLE,
  LOAD_ARTICLE_RELATED,
  LOAD_ARTICLE_RELATED_SUCCESS,
  LOAD_ARTICLE_RELATED_ERROR,
} from './constants';

export const initialState = {
  data: {},
  loading: false,
  slug: null,
  error: false,
  notFound: false,
};

/* eslint-disable default-case, no-param-reassign */
const helpPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ARTICLE:
        draft.loading = true;
        draft.notFound = false;
        draft.slug = action.slug;
        draft.data = {};
        break;
      case LOAD_ARTICLE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.notFound = false;
        if (action.payload !== null) {
          // eslint-disable-next-line prefer-destructuring
          draft.data = action.payload[0];
        }
        break;
      case LOAD_ARTICLE_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.notFound = action.error || false;
        break;
      case INIT_EMPTY_ARTICLE:
        draft.data = {};
        break;
    }
  });

export const relatedInitialState = {
  data: [],
  loading: false,
  slug: null,
  error: false,
};

const relatedReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ARTICLE_RELATED:
        draft.loading = true;
        draft.slug = action.slug;
        break;
      case LOAD_ARTICLE_RELATED_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.data = action.payload ? action.payload.filter(item => item.slug !== draft.slug) : [];
        break;
      case LOAD_ARTICLE_RELATED_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
    }
  });

export default combineReducers({
  articles: helpPageReducer,
  related: relatedReducer,
});
