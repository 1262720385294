import { createSelector } from 'reselect';
import { helpsInitialState, initialState } from './reducer';
import { categoryInitialState } from '../App/reducer';

/**
 * Direct selector to the tagPage state domain
 */

const selectTagPageDomain = state =>
  state.tagPage || { guides: initialState, helps: helpsInitialState, categories: categoryInitialState };

const makeSelectGuides = () =>
  createSelector(
    selectTagPageDomain,
    substate => substate.guides,
  );

const makeSelectHelps = () =>
  createSelector(
    selectTagPageDomain,
    substate => substate.helps,
  );

const makeSelectCategories = () =>
  createSelector(
    selectTagPageDomain,
    substate => substate.categories,
  );

export { selectTagPageDomain, makeSelectGuides, makeSelectHelps, makeSelectCategories };
