import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import Color from './Color';

function ColorBar({ colors, currentColor, onColorChange }) {
  return (
    <Wrapper>
      {colors.map((c) => (
        <Color
          key={c}
          onClick={() => onColorChange(c)}
          selected={currentColor === c}
          color={c}
        />
      ))}
    </Wrapper>
  );
}

ColorBar.propTypes = {
  colors: PropTypes.array.isRequired,
  currentColor: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
};

export default ColorBar;
