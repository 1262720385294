import React from 'react';
import PropTypes from 'prop-types';

import LOADER_IMG from 'components/Loader/loader.svg';

import { ButtonBig, Button, ButtonWhite } from 'components/layout';

function SaveButton({ disabled = false, isSaving = false }) {
  return (
    <ButtonBig type="submit" disabled={disabled || isSaving}>
      {isSaving && (
        <img
          src={LOADER_IMG}
          height={20}
          width={20}
          style={{
            display: 'inline-block',
            marginRight: '10px',
            verticalAlign: 'middle',
          }}
          alt="Saving..."
        />
      )}
      {isSaving ? 'Saving...' : 'Save'}
    </ButtonBig>
  );
}

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  isSaving: PropTypes.bool,
};

export function LoadingButton({
  isLoading = false,
  loadingLabel = 'Loading...',
  children,
  ...rest
}) {
  return (
    <Button disabled={isLoading} {...rest}>
      {isLoading && (
        <img
          src={LOADER_IMG}
          height={20}
          width={20}
          style={{
            display: 'inline-block',
            marginRight: '10px',
            verticalAlign: 'middle',
          }}
          alt={loadingLabel}
        />
      )}
      {isLoading ? loadingLabel : children}
    </Button>
  );
}

LoadingButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadingLabel: PropTypes.string,
  children: PropTypes.node,
};

export default SaveButton;
