/*
 * ItemType Messages
 *
 * This contains all the text for the ItemType component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ItemType';

export default defineMessages({
  helps: {
    id: `${scope}.helps`,
    defaultMessage: 'Article',
  },
  steps: {
    id: `${scope}.steps`,
    defaultMessage: 'Step',
  },
  guides: {
    id: `${scope}.guides`,
    defaultMessage: 'Guide',
  },
  manuals: {
    id: `${scope}.manuals`,
    defaultMessage: 'Assembly manual',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  tooltips: {
    id: `${scope}.tooltips`,
    defaultMessage: 'Glossary',
  },
  post_tag: {
    id: `${scope}.post_tag`,
    defaultMessage: 'Printer',
  },
});
