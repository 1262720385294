import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  font-size: 0.7rem;
  display: inline-block;
  margin-right: 0.5em;
  &:after {
    content: '/';
    margin-left: 0.5em;
  }
  &:last-child:after {
    display: none;
  }
  a {
    color: ${props => props.inAdmin ? props.theme.colors.main : '#f0f0f0'};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

interface BreadcrumbsProps {
  items: Array<{
    title: string;
    link: string;
  }>;
  language: string;
  inAdmin?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items = [], language, inAdmin = false }) => {
  const allItems = [{ title: 'Home', link: `/${language}/` }].concat(items);

  return (
    <List>
      {allItems.map(item => (
        <Item key={item.title} inAdmin={inAdmin}>
          {item.link ? (
            <Link to={item.link}>{parse(item.title)}</Link>
          ) : (
            parse(item.title)
          )}
        </Item>
      ))}
    </List>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  language: PropTypes.string.isRequired,
  inAdmin: PropTypes.bool,
};

export default Breadcrumbs;
