import React from 'react';
import PropTypes from 'prop-types';

import SaveButton from 'components/admin/SaveButton';
import { ButtonBig, ButtonWhiteA } from 'components/layout';

import Wrapper from './Wrapper';

export function FormActions({
  saveDisabled,
  isSaving,
  previewUrl = false,
  onDelete,
  isNew,
}) {
  return (
    <Wrapper>
      <SaveButton disabled={saveDisabled} isSaving={isSaving} />
      {!isNew && (
        <>
          {previewUrl && <ButtonWhiteA to={previewUrl}>Preview</ButtonWhiteA>}
          {typeof onDelete === 'function' && (
            <ButtonBig
              color="red"
              onClick={e => {
                e.preventDefault();
                onDelete();
              }}
            >
              Delete
            </ButtonBig>
          )}
        </>
      )}
    </Wrapper>
  );
}

FormActions.propTypes = {
  isNew: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  isSaving: PropTypes.bool,
  previewUrl: PropTypes.string,
  onDelete: PropTypes.func,
};

export default FormActions;
