/*
 * AdminTagPage Messages
 *
 * This contains all the text for the AdminTagPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AdminTagPage';

export default defineMessages({
  editTag: {
    id: `${scope}.editTag`,
    defaultMessage: 'Editing tag',
  },
  editGuides: {
    id: `${scope}.editGuides`,
    defaultMessage: 'Edit guides',
  },
});
