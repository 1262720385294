/*
 * SEOHeaders Messages
 *
 * This contains all the text for the GuidePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SEOHeaders';

export default defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'All information you need to know about Original Prusa 3D printers. Assembly manuals, print quality troubleshooting, calibration, PrusaSlicer and much more.',
  },
});
