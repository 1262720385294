import { takeLatest, call, put, select } from 'redux-saga/effects';
import request from 'utils/request';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { makeSelectPreviewMode } from '../App/selectors';
import {
  loadArticlesError,
  loadArticlesSuccess,
  loadGuidesError,
  loadGuidesSuccess,
} from './actions';

import { LOAD_ARTICLES, LOAD_GUIDES } from './constants';

export function* articlesSaga(action) {
  const lang = yield select(makeSelectLocale());
  const preview = yield select(makeSelectPreviewMode());
  try {
    const params = {
      lng: lang,
      page: action.page || 1,
      per_page: action.perPage || 10,
      include_guides: action.includeGuides,
    };
    if (action.category !== 'all') {
      params.category = action.category;
    }
    if (preview || action.isAdmin) {
      params.admin = true;
      params.translations_info = true;
    }
    if (preview && !action.isAdmin) {
      params.preview = true;
    }
    if (action.sort) {
      if (action.sort === 'popular') {
        params.popular = true;
      } else {
        const [orderby, order] = action.sort.split(',');
        params.orderby = orderby;
        params.order = order;
      }
    }
    const data = yield call(request, 'v1/helps', {
      params,
    });
    yield put(
      loadArticlesSuccess(
        data ? data.data : [],
        data ? data.info.total_pages : 0,
        data ? data.info.total_items : 0,
      ),
    );
  } catch (e) {
    console.error(e);
    yield put(loadArticlesError(e));
  }
}

export function* guidesSaga(action) {
  try {
    const lang = yield select(makeSelectLocale());
    const preview = yield select(makeSelectPreviewMode());
    const params = { lng: lang };
    if (action.category !== 'all') {
      params.category = action.category;
    }
    if (preview || action.isAdmin) {
      params.admin = true;
      params.not_assembly = true;
    }
    if (preview && !action.isAdmin) {
      params.preview = true;
    }
    const data = yield call(request, 'v1/guides', {
      params,
    });

    yield put(loadGuidesSuccess(data ? data.data : []));
  } catch (e) {
    console.error(e);
    yield put(loadGuidesError(e));
  }
}

// Individual exports for testing
export default function* categoryPageSaga() {
  yield takeLatest(LOAD_ARTICLES, articlesSaga);
  yield takeLatest(LOAD_GUIDES, guidesSaga);
}
