import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../utils/constants/theme';

export default styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 1em;
`;

const listMap = ['none', 'square', 'circle', 'disc'];

export const Item = styled.li<{
  depth: 0 | 1 | 2 | 3;
  padding?: number;
  hasIcon?: boolean;
  subMenu?: boolean;
  hover?: boolean;
}>`
  border-bottom: 1px solid ${theme.colors.lightGrey};
  margin-left: ${props => `${(props.depth || 0) * 20}px`};
  padding: ${props => props.padding || 1}em 0;
  list-style: ${props => listMap[props.depth]};
  position: relative;
  ${props => props.hasIcon && 'padding-right: 2em;'}
  &:first-child {
    ${props =>
      props.subMenu ? '' : `border-top: 1px solid ${theme.colors.lightGrey}`};
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  a {
    color: #333;
    transition: color 400ms;
    &:hover {
      color: ${theme.colors.main};
    }
  }
  ${props =>
    props.hover &&
    `
    &:hover {
      background-color: #fcfcfc;
    }
  `}
  @media screen and (max-width: 32em) {
    padding-right: 0 !important;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 32em) {
    display: none;
  }
`;

interface ListItemProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  depth?: 0 | 1 | 2 | 3;
  hover?: boolean;
  className?: string;
  padding?: number;
  subMenu?: boolean;
  iconLinkTo?: string;
  onClick?: () => void;
}

export const ListItem: React.FC<ListItemProps> = ({
  icon = null,
  children,
  style,
  depth,
  hover = true,
  className,
  padding,
  subMenu = false,
  iconLinkTo = null,
  onClick,
}) => (
  <Item
    hasIcon={!!icon}
    style={style}
    depth={depth || 0}
    hover={hover}
    className={className}
    padding={padding}
    subMenu={subMenu}
    onClick={onClick}
  >
    {children}
    {icon && iconLinkTo && (
      <Link style={{ width: '8px', display: 'inline-block' }} to={iconLinkTo}>
        <IconWrapper>{icon}</IconWrapper>
      </Link>
    )}
    {icon && !iconLinkTo && <IconWrapper>{icon}</IconWrapper>}
  </Item>
);

ListItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  depth: PropTypes.oneOf([0, 1, 2, 3]),
  hover: PropTypes.bool,
  subMenu: PropTypes.bool,
  padding: PropTypes.number,
  className: PropTypes.string,
  iconLinkTo: PropTypes.string,
};
