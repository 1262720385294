/*
 * GuidePage Messages
 *
 * This contains all the text for the GuidePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.component.InfiniteScroll';

export default defineMessages({
  loadMore: {
    id: `${scope}.loadMore`,
    defaultMessage: 'Load more',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading ...',
  },
  noMore: {
    id: `${scope}.noMore`,
    defaultMessage: 'You have reached the end of the Knowledgebase.',
  },
});
