/*
 * AdminTagPage Messages
 *
 * This contains all the text for the AdminCategoryPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AdminCategoryPage';

export default defineMessages({
  editCategory: {
    id: `${scope}.editCategory`,
    defaultMessage: 'Editing category',
  },
  editArticles: {
    id: `${scope}.editArticles`,
    defaultMessage: 'Edit articles',
  },
});
