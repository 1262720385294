/**
 *
 * AppMain
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment';

import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import TooltipDialog from 'containers/TooltipDialog';
import AdminSection from 'containers/admin/AdminSection';
import HomePage from 'containers/HomePage/Loadable';
import DepartmentsPage from 'containers/DepartmentsPage/Loadable';
import HelpPage from 'containers/ArticlePage/Loadable';
import NewPage from 'containers/NewPage/Loadable';
import GuidePage from 'containers/GuidePage/Loadable';
import TagPage from 'containers/TagPage/Loadable';
import SearchPage from 'containers/SearchPage/Loadable';
import CategoryPage from 'containers/CategoryPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import GlossaryPage from 'containers/GlossaryPage/Loadable';
import DiagnosticPage from 'containers/DiagnosticPage/Loadable';
import NewsPage from 'containers/NewsPage';
import {
  makeSelectGlobal,
  makeSelectPreviewMode,
  makeSelectUserRoles,
} from 'containers/App/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import ErrorBoundary from 'components/ErrorBoundary';
import AccessDenied from '../../components/AccessDenied';
import Loader from '../../components/Loader';
import { allowedLangs, ROLES } from '../../utils/constants';
import { useCheckRole, useVideoAutoplay } from '../../utils/hooks';
import { logoutAction, setPreviewMode } from '../App/actions';

const Wrapper = styled.div`
  margin-top: 48px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 48px);
`;

const MainContent = styled.section`
  position: relative;
  min-height: calc(100vh - (95px + 48px + 2em));
`;

export function AppMain(props) {
  const {
    global: { data, loading, currentUser, userLoaded },
    setLang,
    language = 'en',
    match,
    logout,
    redirect,
    roles,
    isPreview,
    changePreviewMode,
  } = props;

  if (!language) {
    setLang('en');
  } else if (language !== match.params.lang) {
    const lng = match.params.lang || language;
    // TODO: maybe log error?
    setLang(allowedLangs.indexOf(lng) > 0 ? lng : 'en');
  }
  moment.locale(language);

  console.log("DEBUG", data);

  const { isInAnyRole, isInRole } = useCheckRole(roles);
  const canEdit = isInAnyRole([ROLES.ADMIN, ROLES.AUTHOR, ROLES.COMMENT_ADMIN]);
  const isAdmin = isInRole(ROLES.ADMIN);

  useVideoAutoplay({ threshold: 0.9 });

  function handleSetPreviewMode() {
    if (isPreview) {
      changePreviewMode(false);
      if (window.location.search.includes('preview=true')) {
        window.location.search = '';
      }
    } else {
      changePreviewMode(true);
      window.location.search = '?preview=true';
    }
  }

  const redirUrl = window.localStorage.getItem('intranet_redirect_url');
  if (redirUrl) {
    window.localStorage.removeItem('intranet_redirect_url');
    redirect(JSON.parse(redirUrl));
  }

  const accessDeniedReason =
    userLoaded && !currentUser.userid && !currentUser.is_allowed
      ? 'ip'
      : 'employee';

  return (
    <Wrapper>
      <Helmet
        titleTemplate="Prusa Intranet | %s"
        defaultTitle="Prusa Intranet"
        htmlAttributes={{ lang: language }}
      >
        <meta charSet="utf-8" />
      </Helmet>
      <AppHeader
        onLogout={logout}
        currentLang={language}
        changeLanguage={setLang}
        nav={data.menu}
        canEdit={canEdit}
        isAdmin={isAdmin}
        isPreview={isPreview}
        handleSetPreviewMode={handleSetPreviewMode}
        currentUser={currentUser}
        hideMenu={!(currentUser?.is_allowed || currentUser?.is_employee)}
      />
      <MainContent>
        <ErrorBoundary>
          {loading ? (
            <Loader fullPage />
          ) : userLoaded &&
            (currentUser.is_allowed || currentUser.is_employee) ? (
            <Switch>
              <Route
                exact
                path={`${match.path}/onboarding`}
                render={p => <HomePage {...p} onboarding />}
              />
              <Route exact path={`${match.path}/`} component={HomePage} />
              {/* Routes */}
              <Route path={`${match.path}/admin`} component={AdminSection} />
              <Route
                path={`${match.path}/article/:slug`}
                component={HelpPage}
              />
              <Route
                path={`${match.path}/:cat([a-zA-Z-]+_\\d+)/article/:slug`}
                component={HelpPage}
              />
              <Route
                path={`${match.path}/:tag/article/:slug`}
                component={HelpPage}
              />
              <Route
                path={`${match.path}/guide/:slug`}
                sensitive
                component={GuidePage}
              />
              <Route
                path={`${match.path}/departments/:slug?/:email?`}
                sensitive
                component={DepartmentsPage}
              />
              <Route
                path={`${match.path}/:cat([a-zA-Z-]+_\\d+)/guide/:slug`}
                sensitive
                component={GuidePage}
              />
              <Route
                path={`${match.path}/:tag/guide/:slug`}
                sensitive
                component={GuidePage}
              />
              <Route
                path={`${match.path}/tag/:tag/:category?`}
                component={TagPage}
              />
              <Route path={`${match.path}/news/:slug`} component={NewPage} />
              <Route path={`${match.path}/news`} exact component={NewsPage} />
              <Route
                path={`${match.path}/glossary/:slug?`}
                component={GlossaryPage}
              />
              <Route
                path={`${match.path}/category/:category`}
                component={CategoryPage}
              />
              <Route path={`${match.path}/search`} component={SearchPage} />
              {/* Wordpress Redirect */}
              <Redirect
                from={`${match.path}/guides/:guideSlug`}
                to={`${match.path}/guide/:guideSlug`}
              />
              <Redirect
                from={`${match.path}/steps/:guideSlug/:slug`}
                to={`${match.path}/guide/:guideSlug#:slug`}
              />
              <Redirect
                from={`${match.path}/step/:guideSlug/:slug`}
                to={`${match.path}/guide/:guideSlug#:slug`}
              />
              <Redirect
                from={`${match.path}/helps/:slug`}
                to={`${match.path}/article/:slug`}
              />
              <Route
                path={`${match.path}/404`}
                exact
                component={NotFoundPage}
              />
              <Route
                path={`${match.path}/:error(\\d+)/:hash?/:firmware(\\d+)?`}
                component={DiagnosticPage}
              />
              {/* <Redirect to={`${match.path}/404`} /> */}
              <Route component={NotFoundPage} />
            </Switch>
          ) : (
            <AccessDenied reason={accessDeniedReason} />
          )}
        </ErrorBoundary>
        <TooltipDialog />
      </MainContent>
      <AppFooter currentLang={language} />
    </Wrapper>
  );
}

AppMain.propTypes = {
  global: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
  }),
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  setLang: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  isPreview: PropTypes.bool,
  changePreviewMode: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
  language: makeSelectLocale(),
  roles: makeSelectUserRoles(),
  isPreview: makeSelectPreviewMode(),
});

function mapDispatchToProps(dispatch) {
  return {
    setLang: lang => dispatch(changeLocale(lang)),
    redirect: url => dispatch(push(url)),
    logout: () => dispatch(logoutAction()),
    changePreviewMode: val => dispatch(setPreviewMode(val)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AppMain);
