/**
 *
 * TagsField
 *
 */

import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';
import ReactTags from 'react-tag-autocomplete';
import PropTypes from 'prop-types';

import { InputLabel } from 'components/admin/layout';
import { Button } from 'components/layout';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  & > .react-tags {
    flex: 1;
  }
`;

function TagsField({ name, label, suggestions, setFieldValue, onOpenDialog = false }) {
  function handleAddAllTags(e) {
    e.preventDefault();
    setFieldValue(name, suggestions);
  }
  function handleRemoveAll(e) {
    e.preventDefault();
    setFieldValue(name, []);
  }
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Field
        name={name}
        render={({ field }) => (
          <Wrapper>
            <ReactTags
              tags={field.value}
              minQueryLength={1}
              suggestions={suggestions}
              handleDelete={val => {
                const value = field.value.slice(0);
                value.splice(val, 1);
                setFieldValue(name, value);
              }}
              handleAddition={val =>
                setFieldValue(name, field.value.concat(val))
              }
              placeholder="Add"
            />
            <Button onClick={field.value.length > 0 ? handleRemoveAll : handleAddAllTags}>
              {field.value.length > 0 ? 'Remove all' : 'Add all'}
            </Button>
            {onOpenDialog && (
              <Button onClick={e => {
                e.preventDefault();
                onOpenDialog();
              }}>
                Create new
              </Button>
            )}
          </Wrapper>
        )}
      />
    </div>
  );
}

TagsField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  onOpenDialog: PropTypes.func,
};

export default TagsField;
