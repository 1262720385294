/*
 *
 * AdminNewPage actions
 *
 */

import {
  SAVE_NEW,
  SAVE_NEW_ERROR,
  SAVE_NEW_SUCCESS,
  DELETE_NEW,
  DELETE_NEW_ERROR,
  DELETE_NEW_SUCCESS,
} from './constants';

export const saveNewAction = (id, article) => ({
  type: SAVE_NEW,
  id,
  article,
});

export const saveNewSuccess = payload => ({
  type: SAVE_NEW_SUCCESS,
  payload,
});

export const saveNewError = error => ({
  type: SAVE_NEW_ERROR,
  error,
});

export const deleteNewAction = id => ({
  type: DELETE_NEW,
  id,
});

export const deleteNewSuccess = () => ({
  type: DELETE_NEW_SUCCESS,
});

export const deleteNewError = error => ({
  type: DELETE_NEW_ERROR,
  error,
});
