/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const LOAD_GLOBAL_INFO = 'app/App/LOAD_GLOBAL_INFO';
export const LOAD_CURRENT_USER = 'app/App/LOAD_CURRENT_USER';
export const LOAD_CURRENT_USER_SUCCESS = 'app/App/LOAD_CURRENT_USER_SUCCESS';
export const LOAD_CURRENT_USER_ERROR = 'app/App/LOAD_CURRENT_USER_ERROR';
export const LOAD_GLOBAL_INFO_SUCCESS = 'app/App/LOAD_GLOBAL_INFO_SUCCESS';
export const LOAD_GLOBAL_INFO_ERROR = 'app/App/LOAD_GLOBAL_INFO_ERROR';
export const LOAD_CATEGORIES = 'app/App/LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SUCCESS = 'app/App/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_ERROR = 'app/App/LOAD_CATEGORIES_ERROR';
export const SET_TAG = 'app/App/SET_TAG';
export const SET_EDIT_MODE = 'app/App/SET_EDIT_MODE';
export const TOGGLE_SEARCH = 'app/App/TOGGLE_SEARCH';

export const LOGOUT_USER = 'app/App/LOGOUT_USER';

export const SHOW_TOOLTIP = 'app/App/SHOW_TOOLTIP';
export const HIDE_TOOLTIP = 'app/App/HIDE_TOOLTIP';
export const TOOLTIP_LOADED = 'app/App/TOOLTIP_LOADED';

export const SET_PREVIEW_MODE = 'app/App/SET_PREVIEW';
