/*
 *
 * MediaGallery actions
 *
 */

import { LOAD_MEDIA, LOAD_MEDIA_ERROR, LOAD_MEDIA_SUCCESS } from './constants';

export function loadMediaAction(page, filter, search = false) {
  return {
    type: LOAD_MEDIA,
    page,
    filter,
    search,
  };
}

export function loadMediaSuccess(payload) {
  return {
    type: LOAD_MEDIA_SUCCESS,
    payload,
  };
}

export function loadMediaError(error) {
  return {
    type: LOAD_MEDIA_ERROR,
    error,
  };
}
