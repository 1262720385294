/*
 *
 * AdminTagPage actions
 *
 */

import {
  SAVE_TAG,
  DELETE_TAG,
  SAVE_TAG_ERROR,
  CHANGE_GUIDE_ORDER,
  SAVE_GUIDE_ORDER,
  SAVE_TAG_SUCCESS,
} from './constants';

export const saveTagAction = (id, tag, redirect = true) => ({
  type: SAVE_TAG,
  id,
  tag,
  redirect,
});

export const saveTagSuccess = payload => ({
  type: SAVE_TAG_SUCCESS,
  payload,
});

export const saveTagError = error => ({
  type: SAVE_TAG_ERROR,
  error,
});

export const changeGuideOrderAction = (category, idx, idx2) => ({
  type: CHANGE_GUIDE_ORDER,
  category,
  idx,
  idx2,
});

export const saveGuidesOrderAction = category => ({
  type: SAVE_GUIDE_ORDER,
  category,
});

export const deleteTagAction = id => ({
  type: DELETE_TAG,
  id,
});
