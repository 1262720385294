/**
 *
 * PhrasePushModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import YesNoModal from '../YesNoModal';

function PhrasePushModal({ ...props }) {
  return (
    <YesNoModal {...props}>
      <p>Send changes for translation?</p>
    </YesNoModal>
  );
}

PhrasePushModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PhrasePushModal;
