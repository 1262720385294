import { Field } from 'formik';
import React from 'react';
import { InputLabel } from 'components/admin/layout';
import PropTypes from 'prop-types';

function CommentsStatusField({ name = 'comment_status', label = 'Comments status', values, setFieldValue }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Field component="select" name={name}>
        <option value="open">Open</option>
        <option value="closed">Closed</option>
      </Field>
    </div>
  );
}

CommentsStatusField.propTypes = {

};

export default CommentsStatusField;
