/**
 *
 * AdminCategoryPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import FormActions from 'components/admin/FormActions';
import FeaturedImageField from 'components/admin/FeaturedImageField';
import { Error, InputLabel } from 'components/admin/layout';
import { Col, Grid, H2 } from 'components/layout';
import { makeSelectSavingState } from 'containers/admin/AdminSection/selectors';
import {
  makeSelectCategoriesBySlug,
  makeSelectUserRoles,
} from 'containers/App/selectors';
import categoryReducer from 'containers/CategoryPage/reducer';
import categorySaga from 'containers/CategoryPage/saga';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getFullCategoryBySlug, validateTitle } from 'utils/formUtils';
import { urlBuilder } from 'utils/helpers';
import { useInjectReducer } from 'utils/injectReducer';

import { useInjectSaga } from 'utils/injectSaga';
import StatusField from '../../../components/admin/StatusField';
import TagsField from '../../../components/admin/TagsField';
import { ROLES } from '../../../utils/constants';
import { useCheckRole } from '../../../utils/hooks';

import { saveCategoryAction } from './actions';
import messages from './messages';
import saga from './saga';

export function AdminCategoryEditor({
  slug,
  categoriesBySlug,
  saveCategory,
  language,
  categories,
  savingState,
  roles,
  onDelete = () => null,
  callback = false,
  doRedirect = true,
}) {
  useInjectReducer({ key: 'categoryPage', reducer: categoryReducer });
  useInjectSaga({ key: 'categoryPage', saga: categorySaga });
  useInjectSaga({ key: 'adminCategoryPage', saga });

  const { isInRole } = useCheckRole(roles);
  const isAdmin = isInRole(ROLES.ADMIN);

  const isNew = slug === 'new';
  const category = Object.values(categoriesBySlug).find(c => c.id === parseInt(slug.split('_')[1], 0)) || {};
  const data = !isNew ? category : {};

  const getCat = getFullCategoryBySlug(categories);
  const categoriesSuggestions = Object.keys(categories).map(getCat);

  const cat = Object.values(categories).find(c => c.id === data.parent);
  const currentParent = cat ? getCat(cat.slug) : null;

  return (
    <React.Fragment>
      <H2>
        {!isNew ? (
          <>
            <FormattedMessage {...messages.editCategory} />{' '}{category.title}
            {/* <Link to={`${match.url}/print`}>PRINT</Link> */}
          </>
        ) : (
          'New category'
        )}
      </H2>
      <Formik
        initialValues={{
          name: data.title || '',
          description: data.content || '',
          // slug: data.slug || '',
          status: data.status || 'private',
          parent: currentParent ? [currentParent] : [],
          _thumbnail_id:
            data.media && data.media.image ? data.media.image.id : null,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          // todo handle parent
          const catData = {
            ...values,
            parent: values.parent.length ? values.parent[0].id : 0,
          };
          saveCategory(data.id, catData, doRedirect);
          if (callback && typeof callback === 'function') callback();
          actions.setSubmitting(false);
        }}
        render={({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          values,
        }) => (
          <Form>
            <Grid>
              <Col cols={7} style={{ overflow: 'visible' }}>
                <div>
                  <InputLabel>Title</InputLabel>
                  <Field
                    type="text"
                    name="name"
                    size={20}
                    validate={validateTitle}
                  />
                  {errors.name && touched.name && (
                    <Error>{errors.name}</Error>
                  )}
                </div>
                <div>
                  <InputLabel>Version</InputLabel>
                  <Field
                    type="text"
                    name="meta.manual_version"
                    size={20}
                  />
                </div>
                <div>
                  <InputLabel>Description</InputLabel>
                  <Field
                    component="textarea"
                    name="description"
                    cols={100}
                    rows={4}
                    // validate={validateTitle}
                  />
                  {errors.description && touched.description && (
                    <Error>{errors.description}</Error>
                  )}
                </div>
                <StatusField
                  values={values}
                  isAdmin={isAdmin}
                  setFieldValue={setFieldValue}
                />
                <TagsField
                  label="Parent category"
                  name="parent"
                  setFieldValue={setFieldValue}
                  suggestions={categoriesSuggestions}
                />
                {errors.parent && (
                  <Error>{errors.parent}</Error>
                )}
              </Col>
              <Col cols={5}>
                <FeaturedImageField
                  name="_thumbnail_id"
                  setFieldValue={setFieldValue}
                  currentMedia={category.media || {}}
                />
              </Col>
            </Grid>
            <FormActions
              saveDisabled={
                isSubmitting || Object.keys(errors).length > 0
              }
              isSaving={savingState.loading}
              isNew={isNew}
              previewUrl={urlBuilder({
                language,
                item: { ...data, type: 'category' },
                params: 'preview=true',
              })}
              onDelete={isInRole(ROLES.ADMIN) ? onDelete : null}
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
}

AdminCategoryEditor.propTypes = {
  categoriesBySlug: PropTypes.object,
  saveCategory: PropTypes.func.isRequired,
  categories: PropTypes.object,
  language: PropTypes.string.isRequired,
  savingState: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  callback: PropTypes.func,
  doRedirect: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = createStructuredSelector({
  categoriesBySlug: makeSelectCategoriesBySlug(),
  categories: makeSelectCategoriesBySlug(),
  language: makeSelectLocale(),
  savingState: makeSelectSavingState(),
  roles: makeSelectUserRoles(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveCategory: (id, category, redirect) => dispatch(saveCategoryAction(id, category, redirect)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AdminCategoryEditor);
