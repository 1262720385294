import { LOCATION_CHANGE, push } from 'connected-react-router';
import {
  call,
  put,
  select,
  all,
  delay,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import moment from 'moment/moment';

import request from 'utils/request';
import { allowedLangs, API_CONST } from '../../utils/constants';
import { makeSelectArticles } from '../ArticlePage/selectors';
import { makeSelectGuide } from '../GuidePage/selectors';
import { CHANGE_LOCALE } from '../LanguageProvider/constants';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import {
  loadCategories,
  loadCategoriesError,
  loadCategoriesSuccess,
  loadCurrentUserAction,
  loadCurrentUserError,
  loadCurrentUserSuccess,
  loadGlobal,
  loadGlobalError,
  loadGlobalSuccess,
  setPreviewMode,
  toggleSearchAction,
  tooltipLoaded,
} from './actions';
import {
  LOAD_CATEGORIES,
  LOAD_CURRENT_USER,
  LOAD_GLOBAL_INFO,
  LOGOUT_USER,
  SHOW_TOOLTIP,
  TOGGLE_SEARCH,
} from './constants';
import {
  makeSelectCategoriesBySlug,
  makeSelectPreviewMode,
  makeSelectSearchOpen,
} from './selectors';

export function* globalSaga() {
  try {
    const lang = yield select(makeSelectLocale());
    // const isPreview = yield select(makeSelectPreviewMode());
    const data = yield call(request, 'v1/info', {
      params: { lng: lang }, // admin: isPreview },
    });
    // TODO: find better solution
    const position = `internal-apps${lang === 'cs' ? '-cs' : ''}`;
    const menuData = yield call(request, `https://${window.location.origin.includes('acc.intranet') ? 'acc.' : ''}backend.prusa3d.com/api/v1/menu`, {
      params: { asPlain: true, position, lng: lang }, // admin: isPreview },
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }, true);
    console.log("SAGA", data, "menu", menuData);

    const payload = data ? data.data : {};
    payload.menu = menuData.data;

    yield put(loadGlobalSuccess(payload));
  } catch (e) {
    console.error(e);
    yield put(loadGlobalError(e));
  }
}

export function* userSaga() {
  try {
    const data = yield call(request, 'prusuki/user', {
      params: { token: API_CONST },
    });
    yield put(loadCurrentUserSuccess(data ? data.data : []));
    // TODO refactor
    if (
      window.location.search.includes('preview=true') ||
      sessionStorage.getItem('preview')
    ) {
      sessionStorage.setItem('preview', '1');
      yield put(setPreviewMode(true));
    }
  } catch (e) {
    yield put(loadCurrentUserError(e));
  }
}

export function* categorySaga() {
  try {
    const lang = yield select(makeSelectLocale());
    const data = yield call(request, 'v1/categories', {
      params: { lng: lang },
    });

    yield put(loadCategoriesSuccess(data ? data.data : []));
  } catch (e) {
    console.error(e);
    yield put(loadCategoriesError(e));
  }
}

const langRegex = /^\/[A-z]{2}\/(.*)/;
const redirRegex = /^\/(r|h|g)[0-9]*$/;
export function* languageSaga(action) {
  // const lang = yield select(makeSelectLocale());
  const article = yield select(makeSelectArticles());
  const guide = yield select(makeSelectGuide());
  const categories = yield select(makeSelectCategoriesBySlug());
  const isRedir = window.location.pathname.match(redirRegex);
  if (allowedLangs.indexOf(action.locale) >= 0) {
    moment.locale(action.locale);
    // TODO shame on me, find better solution
    if (window.location.pathname.match(langRegex)) {
      if (
        article.data.id &&
        window.location.pathname.indexOf('article/') > -1 &&
        article.data.translations_info[action.locale]
      ) {
        let local = action.locale;
        if (window.location.pathname.indexOf('admin/') > -1) {
          local = `${local}/admin`;
        }
        yield put(
          push(
            `/${local}/article/${
              article.data.translations_info[action.locale].slug
            }`,
          ),
        );
      } else if (
        guide.data.id &&
        window.location.pathname.indexOf('guide/') > -1 &&
        guide.data.translations_info[action.locale]
      ) {
        yield put(
          push(
            `/${action.locale}/guide/${
              guide.data.translations_info[action.locale].slug
            }`,
          ),
        );
      } else if (window.location.pathname.indexOf('category/') !== -1) {
        const cat = window.location.pathname.match(/category\/([^/]*)/);
        yield put(
          push(
            `/${action.locale}/category/${
              cat[1] === 'all' ? 'all' : categories[cat[1]].translations_status[action.locale].slug
            }`,
          ),
        );
      } else if (window.location.pathname.indexOf('tag/') !== -1) {
        const cat = window.location.pathname.match(/tag\/([^/]*)\/([^/]*)/);
        if (cat[2]) {
          yield put(
            push(
              `/${action.locale}/tag/${cat[1]}/${
                categories[cat[2]].translations_status[action.locale].slug
              }`,
            ),
          );
        } else {
          yield put(push(`/${action.locale}/tag/${cat[1]}/`));
        }
      } else {
        yield put(
          push(
            window.location.pathname.replace(langRegex, `/${action.locale}/$1`),
          ),
        );
      }
    } else if (!isRedir) {
      yield put(push(`/cs${window.location.pathname}`));
    }
    if (!isRedir) {
      yield put(loadCategories());
      yield put(loadGlobal());
    }
  }
}

// Close search after location change
function* handleLocationChange() {
  const searchOpen = yield select(makeSelectSearchOpen());
  const isPreview = yield select(makeSelectPreviewMode());
  if (!isPreview && window.location.search.includes('preview=true')) {
    yield put(setPreviewMode(true));
  } else if (isPreview) {
    // window.location.search = '?preview=true';
  }

  if (searchOpen) {
    yield put(toggleSearchAction(false));
  }
}

function* searchSaga(action) {
  if (action.payload && window.location.hash) {
    yield put(push(window.location.pathname));
  }
}

function* logoutSaga() {
  const res = yield call(fetch, '/api/prusuki/logout');
  const url = yield res.text();
  window.location.replace(url);
}

function* tooltipSaga(action) {
  const { slug } = action;
  const lang = yield select(makeSelectLocale());
  const [data, related] = yield all([
    call(request, `v1/tooltips`, { params: { slug, lng: lang } }),
    call(request, `v1/related`, {
      params: { lng: lang, item: slug, per_page: 5 },
    }),
  ]);
  yield put(
    tooltipLoaded(data.data.length > 0 ? data.data[0] : {}, related.data || []),
  );
}

export default function* appMainSaga() {
  yield takeLatest(LOAD_GLOBAL_INFO, globalSaga);
  yield takeLatest(LOAD_CATEGORIES, categorySaga);
  yield takeLatest(CHANGE_LOCALE, languageSaga);
  yield takeLatest(LOGOUT_USER, logoutSaga);
  yield takeLatest(LOAD_CURRENT_USER, userSaga);
  yield takeEvery(TOGGLE_SEARCH, searchSaga);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeLatest(SHOW_TOOLTIP, tooltipSaga);
}
