/**
 *
 * ContactSection
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

import { H2, ColorBar, Wrapper, ButtonWhiteA } from '../layout';
import IMAGE_SRC from './question.svg';

const Img = styled.img`
  margin-right: 1em;
  @media screen and (max-width: 48em) {
    max-height: 50px;
  }
`;

const CustomGrid = styled.div`
  display: flex;
  @media screen and (max-width: 48em) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

function ContactSection() {
  return (
    <ColorBar>
      <Wrapper width={600}>
        <CustomGrid>
          <Img
            src={IMAGE_SRC}
            alt={<FormattedMessage {...messages.header} />}
          />
          <div>
            <H2>
              <FormattedMessage {...messages.header} />
            </H2>
            <p>
              <FormattedMessage {...messages.haveQuestions} />
            </p>
            <br />
            <ButtonWhiteA to={'/cs/article/kontakty_111671'}>
              <FormattedMessage {...messages.contactUs} />
            </ButtonWhiteA>
          </div>
        </CustomGrid>
      </Wrapper>
    </ColorBar>
  );
}

ContactSection.propTypes = {};

export default ContactSection;
