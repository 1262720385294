import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the mediaGallery state domain
 */

const selectMediaGalleryDomain = state => state.mediaGallery || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by MediaGallery
 */

const makeSelectMediaGallery = () =>
  createSelector(
    selectMediaGalleryDomain,
    substate => substate,
  );

export default makeSelectMediaGallery;
export { selectMediaGalleryDomain };
