/**
 *
 * YesNoModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

import { ButtonBig, ButtonWhite } from '../../layout';

function YesNoModal({
  open,
  children,
  onSuccess,
  onClose,
  yesDisabled = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent style={{ padding: '0.5em 1em', textAlign: 'center' }}>
        {children}
        <div>
          <ButtonBig onClick={onSuccess} disabled={yesDisabled}>Yes</ButtonBig>
          &nbsp;
          <ButtonWhite onClick={onClose}>No</ButtonWhite>
        </div>
      </DialogContent>
    </Dialog>
  );
}

YesNoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  yesDisabled: PropTypes.bool,
};

export default YesNoModal;
