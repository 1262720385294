import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button = styled.button`
  background-color: #333;
  color: white;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  ${props => props.absolute && `position: absolute; top: 5px; right: 5px;`}
`;

function CloseButton({ onClick, absolute = false }) {
  return (
    <Button onClick={onClick} absolute={absolute}>
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
};

export default CloseButton;
