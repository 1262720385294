import { takeLatest, call, put, select } from 'redux-saga/effects';
import { notifySuccess, notifyError } from 'utils/notifications';
import request from 'utils/request';
import { push } from 'connected-react-router';
import { loadCategories } from 'containers/App/actions';
import { makeSelectCategoryGuides } from '../../CategoryPage/selectors';
import { makeSelectLocale } from '../../LanguageProvider/selectors';
import { setLoading } from '../AdminSection/actions';

import { saveCategoryError } from './actions';
import { SAVE_CATEGORY, DELETE_CATEGORY, SAVE_GUIDE_ORDER } from './constants';

function* categorySaveSaga(action) {
  try {
    const lang = yield select(makeSelectLocale());
    let data;
    let id;
    yield put(setLoading(true));
    if (action.id) {
      data = yield call(request, `wp/v2/categories/${action.id}?lng=${lang}`, {
        method: 'POST',
        body: JSON.stringify(action.category),
      });
      id = action.id;
      notifySuccess('Category updated!');
    } else {
      data = yield call(request, `wp/v2/categories`, {
        method: 'POST',
        body: JSON.stringify(action.category),
      });
      id = data.id;
      notifySuccess('Category created!');
    }
    if (action.redirect) {
      yield put(push(`/${lang}/admin/category/${data.slug}`));
    }
    yield put(loadCategories(true));
    if (id) {
      yield call(request, `v1/object_management?id=${id}&type=term`, {
        method: 'GET',
      });
    }
  } catch (e) {
    console.error(e);
    yield put(saveCategoryError(e));
    notifyError(e.toString(), 'Cannot save category');
  } finally {
    yield put(setLoading(false));
  }
}

export function* guideOrderSaga() {
  const guidesData = yield select(makeSelectCategoryGuides());

  try {
    const data = guidesData.data.map(guide => ({
      order: guide.order,
      id: guide.id,
    }));
    yield call(request, `v1/order`, {
      method: 'POST',
      body: JSON.stringify({
        data,
        type: 'posts',
      }),
    });
    notifySuccess('Order saved!');
  } catch (e) {
    notifyError(e.toString(), 'Cannot save order!');
  }
}

export function* categoryDeleteSaga(action) {
  try {
    yield put(setLoading(true));
    if (action.id) {
      yield call(request, `wp/v2/categories/${action.id}`, {
        method: 'DELETE',
        params: { force: true },
      });
      notifySuccess('Category deleted!');
      const lang = yield select(makeSelectLocale());
      yield put(loadCategories());
      yield call(request, `v1/object_management?id=${action.id}&type=term`, {
        method: 'GET',
      });
      yield put(push(`/${lang}/admin/categories`));
    } else {
      throw new Error('Category not specified!');
    }
  } catch (e) {
    console.error(e);
    notifyError(e.toString(), 'Cannot delete category!');
  } finally {
    yield put(setLoading(false));
  }
}

export default function* adminCategoryPageSaga() {
  yield takeLatest(SAVE_CATEGORY, categorySaveSaga);
  yield takeLatest(DELETE_CATEGORY, categoryDeleteSaga);
  yield takeLatest(SAVE_GUIDE_ORDER, guideOrderSaga);
}
