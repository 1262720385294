import { fabric } from 'fabric';
import Line from './Line';

const Arrow = fabric.util.createClass(Line, {
  type: 'arrow',
  superType: 'drawing',
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.save();
    ctx.beginPath();
    const p = this.calcLinePoints();
    ctx.moveTo(p.x1, p.y1);
    ctx.lineTo(p.x2, p.y2);
    const xDiff = this.x2 - this.x1;
    const yDiff = this.y2 - this.y1;
    const angle = Math.atan2(yDiff, xDiff);
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
    ctx.rotate(angle);
    // ctx.beginPath();
    const arrowLength = 20; // Math.min(Math.max(this.width, this.height) / 8, 20);
    ctx.moveTo(0, 0);
    ctx.lineTo(-arrowLength, arrowLength / 2);
    ctx.moveTo(0, 0);
    ctx.lineTo(-arrowLength, -arrowLength / 2);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = oldStyle;
    ctx.stroke();
    ctx.restore();
  },
  toSVG() {
    const p = this.calcLinePoints();
    const svg = [
      '<line ',
      'COMMON_PARTS',
      'x1="',
      p.x1,
      '" y1="',
      p.y1,
      '" x2="',
      p.x2,
      '" y2="',
      p.y2,
      '" />\n',
    ];
    // return this.callSuper('toSVG') + label.toSVG();
    return this._createBaseSVGMarkup(svg);
  },
});

Arrow.fromObject = function(object, callback, forceAsync) {
  // return fabric.Object._fromObject('Arrow', object, callback, forceAsync);
  callback &&
    callback(new Arrow([object.x1, object.y1, object.x2, object.y2], object));
};

fabric.Arrow = Arrow;

export default Arrow;
