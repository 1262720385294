/**
 *
 * FeaturedList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { List } from '../layout';
import ListItem from './ListItem';

function FeaturedList({
  items,
  language,
  tags,
  withThumbnails = true,
  withIcon = false,
  large = false,
  categories,
}) {
  return (
    <List>
      {items.map(item => (
        <ListItem
          key={item.id}
          item={item}
          language={language}
          withThumbnails={withThumbnails}
          tags={tags}
          withIcon={withIcon}
          categories={categories}
          large={large}
        />
      ))}
    </List>
  );
}

FeaturedList.propTypes = {
  language: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  withThumbnails: PropTypes.bool,
  withIcon: PropTypes.bool,
  large: PropTypes.bool,
};

export default FeaturedList;
