/*
 *
 * ArticlePage constants
 *
 */

export const LOAD_ARTICLE = 'app/ArticlePage/LOAD_ARTICLE';
export const LOAD_ARTICLE_SUCCESS = 'app/ArticlePage/LOAD_ARTICLE_SUCCESS';
export const LOAD_ARTICLE_ERROR = 'app/ArticlePage/LOAD_ARTICLE_ERROR';

export const INIT_EMPTY_ARTICLE = 'app/AdminArticlePage/INIT_EMPTY_ARTICLE';

export const LOAD_ARTICLE_RELATED = 'app/ArticlePage/LOAD_ARTICLE_RELATED';
export const LOAD_ARTICLE_RELATED_SUCCESS = 'app/ArticlePage/LOAD_ARTICLE_RELATED_SUCCESS';
export const LOAD_ARTICLE_RELATED_ERROR = 'app/ArticlePage/LOAD_ARTICLE_RELATED_ERROR';
