import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { urlBuilder } from '../../utils/helpers';
import { themeColor } from '../../utils/themeHelpers';
import Media from '../Media';

const NewsWrapper = styled.article`
  border: 1px solid ${themeColor('lightGrey')};
  background-color: white;
  border-radius: 2px;
  height: 100%;
`;

const NewsText = styled.div`
  padding: 0.75em;
`;

const NewsLink = styled(Link)`
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  p {
    margin: 0;
  }
  hr {
    border: none;
    height: 1px;
    background-color: ${themeColor('lightGrey')};
  }
  small {
    color: ${themeColor('grey')};
  }
`;

function News({ title, media, content, excerpt, created, slug, language }) {
  return (
    <NewsWrapper>
      <NewsLink to={urlBuilder({ language, item: { slug, type: 'news' }})}>
        <Media image={media} />
        <NewsText>
          <h4 style={{ marginTop: 0, marginBottom: '0.5em' }}>{title}</h4>
          {excerpt?.substring(0, 120)}...
          <hr />
          <small>{moment(created).format('D. M. Y')}</small>
        </NewsText>
      </NewsLink>
    </NewsWrapper>
  );
}

export default News;
