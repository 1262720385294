/**
 *
 * Tooltip
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import useMouseLeave from 'use-mouse-leave';

import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { themeColor } from 'utils/themeHelpers';
import Loader from 'components/Loader';
import { urlBuilder } from 'utils/helpers';
import { actionHideTooltip } from 'containers/App/actions';
import { makeSelectTooltip } from 'containers/App/selectors';
import Media from 'components/Media';
import CloseButton from '../../components/layout/CloseButton';
import { useOutsideClick } from '../../utils/hooks';

const Dialog = styled.div`
  position: fixed;
  z-index: 100;
  top: ${props => props.posY + 25}px;
  left: ${props => props.posX}px;
  width: 650px;
  max-width: 90%;
  padding: 0.8em;
  cursor: auto;
  display: ${props => (props.open ? 'block' : 'none')};
  background-color: white;
  border: 1px solid ${themeColor('grey')};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
`;

const Ul = styled.ul`
  border-top: 1px solid #ddd;
  list-style: none;
  margin: 1em 0 0 0;
  padding: 1em 0 0 0;
`;

const MediaStyled = styled(Media)`
  float: right;
  width: 250px;
  margin-left: 1em;
  margin-bottom: 1em;
  @media screen and (max-width: 48em) {
    float: none;
    width: 60%;
  }
`;

function TooltipDialog({ language, hideTooltip, tooltip }) {
  const [isOutside, ref] = useMouseLeave();
  const { data, related, loading, currentSlug } = tooltip;
  const open = !!currentSlug;
  const innerRef = useRef();

  useOutsideClick(innerRef, () => {
    if (open && !loading) hideTooltip();
  });

  useEffect(() => {
    if (isOutside && open) hideTooltip();
  }, [isOutside]);

  return (
    <Dialog open={open} posX={tooltip.x} posY={tooltip.y} ref={ref}>
      <CloseButton onClick={hideTooltip} absolute />
      <div ref={innerRef}>
        {!data || loading ? (
          <Loader />
        ) : (
          <article>
            {data.media && (
              <MediaStyled
                image={data.media.image}
              />
            )}
            <h3 style={{ marginTop: 0 }}>{data.title}</h3>
            {data.content && parse(data.content)}
            {related && related.length > 0 && (
              <Ul>
                {related.map(item => (
                  <li>
                    <Link to={urlBuilder({ language, item })}>
                      {item.title || item.name}
                    </Link>
                  </li>
                ))}
              </Ul>
            )}
          </article>
        )}
      </div>
    </Dialog>
  );
}

TooltipDialog.propTypes = {
  language: PropTypes.string.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  tooltip: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
  tooltip: makeSelectTooltip(),
});

const mapDispatchToProps = dispatch => ({
  hideTooltip: () => dispatch(actionHideTooltip()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(TooltipDialog);
