import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LOADER_IMG from './loader.svg';

const LoaderWrapper = styled.div<{ fullPage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: ${props => (props.fullPage ? 'absolute' : 'relative')};
  ${props => !props.fullPage && 'margin: 1em auto;'}
`;

interface LoaderProps {
  fullPage?: boolean;
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({ fullPage = false, size = 100 }) => {
  return (
    <LoaderWrapper fullPage={fullPage}>
      <img src={LOADER_IMG} width={size} height={size} alt="Loading..." />
    </LoaderWrapper>
  );
};

Loader.propTypes = {
  fullPage: PropTypes.bool,
  size: PropTypes.number,
};

export default Loader;
