/**
 *
 * FeaturedImageField
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import { FormattedMessage } from 'react-intl';

import { InputLabel } from 'components/admin/layout';
import { Button, Grid } from 'components/layout';
import Media from 'components/Media';
import MediaGallery from 'containers/MediaGallery';
import { useModal } from 'utils/hooks';
import { notifySuccess } from 'utils/notifications';
import request from 'utils/request';
import ImageMeta from 'containers/MediaGallery/ImageMeta';

// import messages from './messages';
import GalleryImage, { Column } from './GalleryImage';

function FeaturedImageField({
  setFieldValue,
  name = 'featured_media',
  label = 'Featured image',
  currentMedia = null,
  postId,
  width = 350,
}) {
  const [galleryOpen, openGallery, closeGallery] = useModal(false);
  const [imageToEdit, setImageToEdit] = useState(false);
  const [featuredImage, setFeaturedImage] = useState({});
  const [gallery, setGallery] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [isVideo, setIsVideo] = useState(false);

  const isGallery = name === 'gallery';
  let image = null;

  useEffect(() => {
    if (isGallery) {
      image =
        (currentMedia &&
          currentMedia.gallery &&
          currentMedia.gallery.length &&
          currentMedia.gallery) ||
        [];
      setGallery(isGallery ? image : []);
      setCurrentImage(image[0] || null);
    } else if (currentMedia && currentMedia.video) {
      setIsVideo(true);
      image = currentMedia.video;
      setFeaturedImage(image);
    } else {
      image = (currentMedia && currentMedia.image) || featuredImage || '';
      setFeaturedImage(image);
      setIsVideo(false);
    }
  }, [currentMedia]);

  function handleCloneImage(img) {
    request(`v1/media`, { params: { duplicate: img.id } }).then(() =>
      notifySuccess('Image cloned!'),
    );
  }

  function handleImageEdit(img) {
    request(`v1/media`, { params: { ids: img.id } }).then(data => {
      setImageToEdit(data[0]);
      openGallery();
    });
  }

  function handleImageSwap(dragIndex, hoverIndex) {
    const newGallery = [...gallery];
    const tmp = newGallery[hoverIndex];
    newGallery[hoverIndex] = newGallery[dragIndex];
    newGallery[dragIndex] = tmp;
    setGallery(newGallery);
    setFieldValue(name, newGallery.map(img => img.id));
  }

  return (
    <div style={{ marginBottom: '1em', width: `${width}px` }}>
      <InputLabel>{label}</InputLabel>
      {!isGallery && featuredImage.id ? (
        <Column
          cols={12}
          style={{ position: 'relative', width: '350px' }}
        >
          <ImageMeta
            img={featuredImage}
            vertical
            onCloneImage={handleCloneImage}
            setImgToEdit={handleImageEdit}
          />
          {isVideo ? (
            <video style={{ width: '100%', zIndex: 5 }} controls>
              <source src={featuredImage.original} type={featuredImage.mime_type} />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <Media
              image={featuredImage}
              style={{ width, cursor: 'pointer' }}
              alt="Featured image"
              border
              onClick={e => {
                e.preventDefault();
                openGallery();
              }}
            />
          )}
        </Column>
      ) : isGallery && gallery.length > 0 ? (
        <>
          <Media image={currentImage} onClick={e => {
            e.preventDefault();
            openGallery();
          }} style={{ cursor: 'pointer' }}/>
          <DndProvider backend={HTML5Backend}>
            <Grid noMargin>
              {(gallery.length < 3 ? [0, 1, 2] : gallery).map((el, i) => {
                return (
                  <GalleryImage
                    img={gallery[i]}
                    key={gallery[i] ? gallery[i].id : i}
                    index={i}
                    moveImage={handleImageSwap}
                    cloneImage={handleCloneImage}
                    editImage={handleImageEdit}
                    onHover={setCurrentImage}
                    width={width}
                  />
                )
              })}
            </Grid>
          </DndProvider>
        </>
      ) : (
        <div>No image set yet</div>
      )}
      <br />
      <Button
        onClick={e => {
          e.preventDefault();
          openGallery();
        }}
      >
        Open gallery
      </Button>
     {/* <Button
        onClick={e => {
          e.preventDefault();
          setFieldValue(name, null);
          setFieldValue('meta.video', null);
        }}
      >
        Remove all media
      </Button>*/}
      {galleryOpen && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={galleryOpen}
          onClose={() => {
            if (isGallery) {
              setFieldValue(name, gallery.map(img => img.id));
            }
            setImageToEdit(false);
            closeGallery();
          }}
        >
          <MediaGallery
            gallery={isGallery}
            postId={postId}
            imageToEdit={imageToEdit}
            onEditClose={() => {
              setImageToEdit(false);
              closeGallery();
            }}
            onImageClick={img => {
              if (isGallery) {
                setGallery(img.map(i => i.media));
                setFieldValue(name, img.map(i => i.id));
                setCurrentImage(img[0].media || null);
              } else if (img[0].mime_type.startsWith('video')) {
                setFieldValue('meta.video', img[0].id);
                setFieldValue(name, null);
                setIsVideo(true);
              } else {
                setFeaturedImage(img[0].media);
                setFieldValue(name, img[0].id);
                setFieldValue('meta.video', null);
                setIsVideo(false);
              }
              closeGallery();
            }}
            selectedItems={
              isGallery
                ? gallery.map(img => img.id).filter(i => !!i)
                : currentMedia && (currentMedia.image &&
                currentMedia.image.id ? [currentMedia.image.id] : (currentMedia.video ? [currentMedia.video.id] : [])) || []
            }
          />
        </Dialog>
      )}
    </div>
  );
}

FeaturedImageField.propTypes = {
  name: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  currentMedia: PropTypes.any,
  width: PropTypes.number,
};

export default FeaturedImageField;
