import {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { debounce } from './helpers';
import request from './request';

export function useModal(initialState = false) {
  const [open, setOpen] = useState(initialState);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return [open, openModal, closeModal];
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target ? target.getBoundingClientRect() : {};

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

export const useMediaQuery = query => {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const updateMatch = () => setMatch(window.matchMedia(query).matches);

    const mql = window.matchMedia(query);

    updateMatch();
    try {
      mql.addEventListener('change', updateMatch);
    } catch (e) {
      // Safari fix
      mql.addListener(updateMatch);
    }
    return () => {
      try {
        mql.removeEventListener('change', updateMatch);
      } catch (e) {
        // Safari fix
        mql.removeListener(updateMatch);
      }
    };
  }, [query]);

  return match;
};

export const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function useDebounce(callback, delay) {
  const d = callback;

  const callbackfunc = useCallback(debounce(d, delay), []);

  return [callbackfunc];
}

export function useCheckRole(roles) {
  function isInRole(role) {
    return roles && roles.indexOf(role) > -1;
  }

  function isInAnyRole(roleArray) {
    return roleArray.reduce((acc, val) => {
      if (!acc && roles && roles.indexOf(val) > -1) return true;
      return acc;
    }, false);
  }

  return {
    isInRole,
    isInAnyRole,
  };
}

export function useHookWithRefCallback(callback) {
  const ref = useRef(null);
  const setRef = useCallback(node => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      callback(node);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
}

export function useVideoAutoplay({ threshold = 0.8 } = {}) {
  function checkScroll() {
    const videos = document.getElementsByTagName('video');

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];

      const x = video.offsetLeft;
      const y = video.offsetTop;
      const w = video.offsetWidth;
      const h = video.offsetHeight;
      const r = x + w; // right
      const b = y + h; // bottom

      const visibleX = Math.max(
        0,
        Math.min(
          w,
          window.pageXOffset + window.innerWidth - x,
          r - window.pageXOffset,
        ),
      );
      const visibleY = Math.max(
        0,
        Math.min(
          h,
          window.pageYOffset + window.innerHeight - y,
          b - window.pageYOffset,
        ),
      );

      const visible = (visibleX * visibleY) / (w * h);

      if (visible > threshold) {
        video.play();
      } else {
        video.pause();
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScroll, false);
    window.addEventListener('resize', checkScroll, false);
    return () => {
      window.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);
}

export function useReadConfirmation(entityId) {
  const [confirmed, setConfirmed] = useState(false);
  useEffect(() => {
    if (entityId) {
      request('v1/read_confirmation', {
        params: { post_id: entityId },
      }).then(data => {
        if (data) {
          setConfirmed(new Date(data));
        }
      });
    }
  }, [entityId]);

  function confirmRead() {
    request('v1/read_confirmation', {
      method: 'POST',
      body: JSON.stringify({ post_id: entityId }),
    }).then(data => {
      setConfirmed(new Date(data));
    });
  }
  return [confirmed, confirmRead];
}
