/*
 *
 * CategoryPage actions
 *
 */

import {
  LOAD_ARTICLES,
  LOAD_ARTICLES_ERROR,
  LOAD_ARTICLES_SUCCESS,
  LOAD_GUIDES,
  LOAD_GUIDES_ERROR,
  LOAD_GUIDES_SUCCESS,
} from './constants';

export function loadArticlesAction(
  category,
  page = 1,
  all = false,
  isAdmin = false,
  sort = null,
  includeGuides = true,
  clear = false,
  perPage = 10,
) {
  return {
    type: LOAD_ARTICLES,
    category,
    page,
    all,
    isAdmin,
    sort,
    includeGuides,
    clear,
    perPage,
  };
}

export function loadArticlesSuccess(payload, totalPages = 0, totalItems = 0) {
  return {
    type: LOAD_ARTICLES_SUCCESS,
    payload,
    totalItems,
    totalPages,
  };
}

export function loadArticlesError(error) {
  return {
    type: LOAD_ARTICLES_ERROR,
    error,
  };
}

export const loadGuidesAction = (category, isAdmin = false) => ({
  type: LOAD_GUIDES,
  category,
  isAdmin,
});

export const loadGuidesSuccess = payload => ({
  type: LOAD_GUIDES_SUCCESS,
  payload,
});

export const loadGuidesError = error => ({
  type: LOAD_GUIDES_ERROR,
  error,
});
