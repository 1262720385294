import { fabric } from 'fabric';
import Circle from './Circle';

const NumberedCircle = fabric.util.createClass(Circle, {
  type: 'numberedCircle',
  superType: 'shape',
  initialize(options) {
    options = options || {};
    this.callSuper('initialize', options);
    this.set('label', options.label || '8');
    this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });
  },
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.save();
    const fontSize = this.radius + 3;
    ctx.font = `bold ${fontSize}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const oldStyle = ctx.strokeStyle;
    ctx.lineWidth = this.strokeWidth - 2;
    ctx.strokeStyle = 'white';
    ctx.strokeText(
      this.label,
      0, 0,
    );
    ctx.strokeStyle = oldStyle;
    ctx.fillStyle = this.stroke;
    ctx.fillText(
      this.label,
      0, 0,
    );
    ctx.restore();
  },
  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      label: this.get('label'),
      name: this.get('name'),
    });
  },
  toSVG() {
    const fontSize = this.radius + 5;
    const label = new fabric.Text(`${this.label}`, {
      originX: 'center',
      originY: 'center',
      left: this.left + this.width / 2 + 2 - `${this.label}`.length,
      top: this.top + this.height / 2,
      fontSize,
      fill: this.stroke,
      fontFamily: 'Inconsolata',
      fontWeight: 'bold',
    });
    // return this.callSuper('toSVG') + label.toSVG();
    return this.callSuper('toSVG') + label.toSVG();
  },
});

NumberedCircle.fromObject = function(object, callback, forceAsync) {
  return fabric.Object._fromObject(
    'NumberedCircle',
    object,
    callback,
    forceAsync,
  );
};

fabric.NumberedCircle = NumberedCircle;

export default NumberedCircle;
