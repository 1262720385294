export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PLACEHOLDER_SRC =
  '/wp-content/uploads/prusuki/prusuki-images/UswO631d2Jk1YNGk.jpg';

export const API_CONST =
  'D9:Z!^1S%(%2P7YhBh:,?UKhxEOuW$|^KeFYsZLdc[?-^{Cp-n=h69Dbs64=)yr*';

export {default as STATUS_MAP} from "./constants/statusMap";

export const allowedLangs = ['en', 'cs'];

export const ROLES = {
  ADMIN: 'administrator',
  COMMENT_ADMIN: 'comments_administrator',
  AUTHOR: 'editor',
  EMPLOYEE: 'employee',
};
