import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  background-color: #eeeeee;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
