import { takeLatest, call, put, select } from 'redux-saga/effects';
import request from 'utils/request';
import { makeSelectCurrentUser } from '../App/selectors';
import { loadMediaError, loadMediaSuccess } from './actions';
import { LOAD_MEDIA } from './constants';

export function* loadMediaSaga(action) {
  try {
    const params = { per_page: 30, page: action.page, admin: true };
    if (action.filter === 'author') {
      const user = yield select(makeSelectCurrentUser());
      params.author = user.userid;
    }
    if (action.filter === 'attached') {
      params.attached = 'false';
    }
    if (action.search) {
      params.search = action.search;
    }
    const data = yield call(request, `v1/media`, {
      params,
    });
    yield put(loadMediaSuccess(data));
  } catch (e) {
    console.error(e);
    yield put(loadMediaError(e));
  }
}

// Individual exports for testing
export default function* mediaGallerySaga() {
  yield takeLatest(LOAD_MEDIA, loadMediaSaga);
}
