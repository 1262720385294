import React from 'react';
import styled from 'styled-components';

export const Img = styled.img<{ adaptive?: boolean }>`
  width: ${props => (props.adaptive ? 'auto' : '100%')};
  height: auto;
  clear: both;
  display: block;
  max-height: 100%;
`;

export const Wrapper = styled.div<{
  adaptive?: boolean;
  border?: boolean;
  width?: number;
  inList?: boolean;
}>`
  ${props => (props.adaptive ? 'display: flex; justify-content: center;' : '')}
  max-width: 100%;
  max-height: 100%;
  ${props => (props.border ? 'border: 1px solid #777;' : '')}
  min-width: ${props => `${props.width || 0}px`};
  @media screen and (max-width: 32em) {
    text-align: center;
    max-width: ${props => (props.inList ? '80%' : 'none')} !important;
    min-width: 0 !important;
    margin: 0 auto !important;
    ${Img} {
      width: 100%;
      margin-bottom: 1em;
    }
  }
`;

export interface Image {
  original: string;
  srcset: string;
  medium?: string;
  medium_large?: string;
  thumbnail?: string;
  large?: string;
  id?: number;
  meta?: any;
  [size: string]: string;
}

export interface ImageProps {
  image: Image;
  alt?: string;
  placeholder?: string;
  size?: 'original' | keyof Image;
  adaptive?: boolean;
}

export const Image: React.FC<ImageProps> = ({
  image,
  alt,
  placeholder = '',
  size = 'original',
  adaptive = false,
  ...props
}) => {
  return (
    <Wrapper {...props} adaptive={adaptive}>
      {image ? (
        <Img
          src={image[size] || image.original || placeholder}
          srcSet={image.srcset ? image.srcset : undefined}
          alt={alt}
          sizes="100vw"
          adaptive={adaptive}
        />
      ) : (
        <Img src={placeholder} alt={alt} sizes="100vw" />
      )}
    </Wrapper>
  );
};

export default Img;
