import { defineMessages } from 'react-intl';

export const scope = 'app.components.AccessDenied';

export default defineMessages({
  ipNotInInterval: {
    id: `${scope}.ipNotInInterval`,
    defaultMessage: "You don't have access to this site.",
  },
  pleaseLogIn: {
    id: `${scope}.pleaseLogIn`,
    defaultMessage: 'Please log in using your Prusa Account.',
  },
  notEmployee: {
    id: `${scope}.notEmployee`,
    defaultMessage:
      'Your account is not marked as employee. If you think this is a mistake, please contact HR.',
  },
});
