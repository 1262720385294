import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { urlBuilder, getCatChain } from '../../utils/helpers';
import { CommentIcon } from '../layout/Icons';
import TagBadge, { TagWrapper } from '../TagBadge';
import { Arrow, ListItem as LI } from '../layout';
import { ItemTypeIcon } from '../ItemType';
import messages from '../RelevantTags/messages';

import Media from '../Media';
import TranslationStatus from '../TranslationStatus';

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Icon = styled.span`
  position: absolute;
  bottom: 1em;
  right: 1em;
`;

const Title = styled.h3`
  margin-top: 0;
`;

const Meta = styled.aside`
  padding-top: 0.5em;
  display: flex;
  width: 100%;
  font-size: 0.9em;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 32em) {
    flex-direction: column;
    margin-top: 1em;
    align-items: flex-start;
  }
`;

const Li = styled(LI)`
  display: flex;
  @media screen and (max-width: 32em) {
    flex-direction: column;
    align-items: center;
  }
`;

const CommentMeta = styled.div`
  font-size: 0.8em;
  color: #999;
  @media screen and (max-width: 32em) {
    margin-top: 1em;
  }
`;

function ListItem({
  item,
  language,
  tags,
  categories,
  large = false,
  withThumbnails = true,
  withIcon = false,
}) {
  const title = parse(item.title || item.name || '');
  const cats =
    item.categories?.length > 0 && categories && getCatChain(categories, item.categories[0]).reverse() || [];

  const stripedHtml =
    large &&
    item.content?.replace(/<[^>]+>/g, '').substring(0, 300);
  const excerpt =
    stripedHtml &&
    `${stripedHtml
      .split(' ')
      .splice(0, 30)
      .join(' ')} ...`;

  return (
    <Li key={item.id} icon={<Arrow />} iconLinkTo={urlBuilder({ language, item })}>
      {withThumbnails && item.media?.image && (
        <Link to={urlBuilder({ language, item })} style={{ display: 'block' }}>
          <Media
            image={item.media.image}
            size="thumbnail"
            alt={title}
            inList
            style={{
              maxWidth: large ? '200px' : '100px',
              marginRight: '1em',
            }}
          />
        </Link>
      )}
      <Inner>
        <Link to={urlBuilder({ language, item })} style={{ display: 'block' }}>
          {large ? <Title>{title} <TranslationStatus language={language} status={item.translation_status}/></Title> : <>{title} <TranslationStatus language={language} status={item.translation_status}/></>}
        </Link>
        {excerpt && (
          <p
            style={{ marginBottom: 'auto', marginTop: 0 }}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
        <Meta>
          <div>
            {cats.length
              ? cats.map((c, i) => (
                <span style={{ color: '#999' }} key={c.id}>
                  {i !== 0 && ' > '}
                  {c.title}
                </span>
              ))
              : null}
            {item.tags && (
              <TagWrapper>
                {item.tags.map(tag => (
                  <TagBadge key={tag} language={language} tag={tags[tag]} />
                ))}
              </TagWrapper>
            )}
          </div>
          {item.type === 'helps' && large && (
            <CommentMeta>
              <FormattedMessage {...messages.viewCount} />: {item.view_count}x&nbsp;|&nbsp;
              <CommentIcon width={15} /> {item.comments}
            </CommentMeta>
          )}
        </Meta>
        {withIcon && (
          <Icon>
            <ItemTypeIcon type={item.type} />
          </Icon>
        )}
      </Inner>
    </Li>
  );
}

ListItem.propTypes = {
  item: PropTypes.object,
  language: PropTypes.string.isRequired,
  tags: PropTypes.object,
  categories: PropTypes.array,
  withIcon: PropTypes.bool,
  withThumbnails: PropTypes.bool,
  large: PropTypes.bool,
};

export default ListItem;
