/**
 *
 * DeleteModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import YesNoModal from '../YesNoModal';

function DeleteModal({ ...props }) {
  return (
    <YesNoModal {...props}>
      <p>Are you sure you want to delete this item?</p>
      <p>
        <strong>This action can't be undone!</strong>
      </p>
    </YesNoModal>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteModal;
