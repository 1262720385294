import { fabric } from 'fabric';

const CircleArrow = fabric.util.createClass(fabric.Circle, {
  type: 'circleArrow',
  superType: 'drawing',
  initialize(points, options) {
    this.callSuper('initialize', points, options);
    this.endAngle = Math.PI * 1.5;
    /*this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });*/
  },
  _render(ctx) {
    ctx.beginPath();
    this.callSuper('_render', ctx);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.strokeStyle = oldStyle;
    ctx.lineWidth = this.strokeWidth;
    ctx.stroke();
    ctx.restore();
    // TODO: compute arc point
    ctx.save();
    ctx.translate(0, -this.radius);
    ctx.rotate(-0.1); // this.radius/800
    const arrowLength = this.radius / 4;
    ctx.moveTo(0, 0);
    ctx.lineTo(-arrowLength, arrowLength / 2);
    ctx.moveTo(0, 0);
    ctx.lineTo(-arrowLength, -arrowLength / 2);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = oldStyle;
    ctx.stroke();
    ctx.restore();
  },
  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.get('name'),
    });
  },
});

CircleArrow.fromObject = function(object, callback, forceAsync) {
  return fabric.Object._fromObject(
    'CircleArrow',
    object,
    callback,
    forceAsync,
  );
};

fabric.CircleArrow = CircleArrow;

export default CircleArrow;
