import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  text-align: center;
`;

export const EditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  ${Wrapper} {
    background: white;
    padding: 5px;
    border-radius: 10px;
  }
`;

function EditLock({ username, compact = true }) {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faLock} />
      {compact ? <br /> : '\u00A0'}
      <span style={{ fontSize: '10px', color: '#555' }}>
        {username}
        {compact ? <br /> : ' '}is editing
      </span>
    </Wrapper>
  );
}

EditLock.propTypes = {
  username: PropTypes.string.isRequired,
  compact: PropTypes.bool,
};

export default EditLock;
