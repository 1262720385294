/**
 *
 * ErrorBoundary
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import request from '../../utils/request';
import messages from './messages';

const Wrapper = styled.div`
  border: 1px solid rgba(0,0,0,.1);
  background-color: #f7f7f7;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

class SmallErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    const data = {
      url: this.props.location.pathname,
      title: error.message,
      stacktrace: errorInfo.componentStack,
    };
    request('v1/errors', { method: 'POST', body: JSON.stringify(data) }).then(
      () => console.log('ERROR LOGGED'),
    );
    /* console.error(error);
    console.info(errorInfo.componentStack); */
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <h3>
            <FormattedMessage {...messages.main} />
          </h3>
          <FormattedMessage {...messages.secondary} />
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

SmallErrorBoundary.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(SmallErrorBoundary);
