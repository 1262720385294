/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ContactSection';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'More questions?',
  },
  haveQuestions: {
    id: `${scope}.haveQuestions`,
    defaultMessage:
      "If you have a question about something that isn't covered here, contact our HR!",
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact us',
  },
});
