import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import { notifyError, notifySuccess } from 'utils/notifications';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { setLoading, setSaved } from '../AdminSection/actions';

import { saveNewError, saveNewSuccess } from './actions';
import { SAVE_NEW, DELETE_NEW } from './constants';

// Individual exports for testing
export function* articleSaveSaga(action) {
  try {
    const lang = yield select(makeSelectLocale());
    let data;
    let id;
    yield put(setLoading(true));
    if (action.id) {
      data = yield call(request, `wp/v2/news/${action.id}`, {
        method: 'POST',
        params: { admin: true, lng: lang },
        body: JSON.stringify(action.article),
      });
      id = action.id;
      notifySuccess('New updated!');
    } else {
      data = yield call(request, `wp/v2/news`, {
        method: 'POST',
        params: { admin: true, lng: lang },
        body: JSON.stringify(action.article),
      });
      notifySuccess('New created!');
      id = data[0].id;
      yield put(push(`/${lang}/admin/new/${data[0].slug}`));
    }
    // yield put(loadNewSuccess(data));
    yield put(saveNewSuccess());
    if (id) {
      yield call(request, `v1/object_management?id=${id}&type=post`, {
        method: 'GET',
      });
    }
  } catch (e) {
    console.error(e);
    yield put(saveNewError(e));
    notifyError(e.toString(), 'Cannot save article');
  } finally {
    yield put(setSaved(true));
  }
}

export function* articleDeleteSaga(action) {
  try {
    yield put(setLoading(true));
    if (action.id) {
      yield call(request, `wp/v2/news/${action.id}`, {
        method: 'DELETE',
      });
      notifySuccess('New deleted!');
      const lang = yield select(makeSelectLocale());
      yield put(push(`/${lang}/admin/news`));
      yield call(request, `v1/object_management?id=${action.id}&type=post`, {
        method: 'GET',
      });
    } else {
      throw new Error('New not specified!');
    }
  } catch (e) {
    console.error(e);
    yield put(saveNewError(e));
    notifyError(e.toString(), 'Cannot delete article!');
  } finally {
    yield put(setLoading(false));
  }
}

export default function* helpPageSaga() {
  yield takeLatest(SAVE_NEW, articleSaveSaga);
  yield takeLatest(DELETE_NEW, articleDeleteSaga);
}
