import { createSelector } from 'reselect';
import { initialState, stepsInitialState } from './reducer';

/**
 * Direct selector to the guidePage state domain
 */

const selectGuidePageDomain = state =>
  state.guidePage || { guide: initialState, steps: stepsInitialState };

/**
 * Other specific selectors
 */

export const makeSelectGuide = () =>
  createSelector(
    selectGuidePageDomain,
    globalState => globalState.guide,
  );

export const makeSelectSteps = () =>
  createSelector(
    selectGuidePageDomain,
    globalState => globalState.steps,
  );

export default { selectGuidePageDomain, makeSelectGuide, makeSelectSteps };
