import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

function AuthorInfo({ author, editor, created, updated }) {
  return (
    <div>
      <h4 style={{ marginBottom: 0 }}>Info</h4>
      <p>
        {author &&
          `Created by ${author.user_name} ${moment(created).fromNow()}`}
        {editor && <br />}
        {editor &&
          `Last updated by ${editor.user_name} ${moment(updated).fromNow()}`}
      </p>
    </div>
  );
}

AuthorInfo.propTypes = {
  author: PropTypes.object,
  editor: PropTypes.object,
  created: PropTypes.string,
  updated: PropTypes.string,
};

export default AuthorInfo;
