/**
 *
 * ErrorBoundary
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react'

import { Wrapper } from '../layout';
import messages from './messages';
import IMG_SRC from './under_construction.gif';

class ErrorBoundary extends React.Component {

  render() {
    return (
      <Sentry.ErrorBoundary fallback={
        <Wrapper style={{ textAlign: 'center' }}>
          <h1>
            <FormattedMessage {...messages.main} />
          </h1>
          <img src={IMG_SRC} title="Jo is working on a fix" alt="Jo is working on a fix" />
          <br />
          <br />
          <Link to="/">
            &larr; <FormattedMessage {...messages.backToHomepage} />
          </Link>
        </Wrapper>
      }>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );

  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(ErrorBoundary);
