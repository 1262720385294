import { fabric } from 'fabric';

const Line = fabric.util.createClass(fabric.Line, {
  type: 'line',
  superType: 'drawing',
  initialize(points, options) {
    const { a, c, angleDeg } = this.computeFromPoints(
      points[0],
      points[1],
      options.realX2 || points[2],
      options.realY2 || points[3],
    );
    options = options || {};
    // finally, initialize u
    this.callSuper('initialize', [a.x, a.y, c.x, c.y], {
      noScaleCache: false, // false to force cache update while scaling (doesn't redraw parts of line otherwise)
      lockRotation: false,
      hasRotatingPoint: false, // to disable rotation control
      centeredRotation: false,
      angle: angleDeg,
      ...options,
      originX: 'left',
      originY: 'bottom',
      lockScalingFlip: true,
      lockScalingX: false,
      lockScalingY: false,
      lockSkewingX: false,
      lockSkewingY: false,
      lockUniScaling: true,
      left: a.x,
      bottom: a.y,
      cornerStyle: 'circle',
      hasBorders: false,
      transparentCorners: false,
      realX2: options.realX2 || points[2],
      realY2: options.realY2 || points[3],
    });

    this.setControlsVisibility({
      tr: false,
      tl: false,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });

    this.on('scaling', function(e) {
      const startDist = Math.sqrt(
        (e.pointer.x - this.x1) ** 2 + (e.pointer.y - this.y1) ** 2,
      );
      const endDist = Math.sqrt(
        (e.pointer.x - this.realX2) ** 2 + (e.pointer.y - this.realY2) ** 2,
      );
      if (startDist < endDist) {
        this.set({
          x1: e.pointer.x,
          y1: e.pointer.y,
        });
      } else {
        this.set({
          realX2: e.pointer.x,
          realY2: e.pointer.y,
        });
      }
      this.recalculate();
    });

    let _curX;
    let _curY;
    let moving = false;
    // Update realX/Y on move
    this.on('moving', function(e) {
      if (moving === false) {
        _curX = this.x1;
        _curY = this.y1;
      }
      moving = true;
    });

    this.on('modified', function(e) {
      if (e?.e && moving) {
        this.realX2 += this.x1 - _curX;
        this.realY2 += this.y1 - _curY;
        moving = false;
      }
    });
  },
  _render(ctx) {
    this.callSuper('_render', ctx);
    ctx.lineCap = 'round';
    ctx.lineWidth = this.strokeWidth + 2;
    const oldStyle = ctx.strokeStyle;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = oldStyle;
    ctx.stroke();
    ctx.restore();
  },
  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      x1: this.x1,
      x2: this.x2,
      realX2: this.realX2,
      angle: this.angle,
      y1: this.y1,
      y2: this.y2,
      realY2: this.realY2,
      name: this.get('name'),
    });
  },
  recalculate() {
    const { c: C, angleDeg: angle } = this.computeFromPoints(
      this.x1,
      this.y1,
      this.realX2,
      this.realY2,
    );
    this.set({
      left: this.x1,
      bottom: this.y1,
      scaleX: 1,
      scaleY: 1,
      x2: C.x,
      y2: C.y,
      angle,
    });
    this.rotate(angle);
  },
  computeFromPoints(x1, y1, x2, y2) {
    const a = new fabric.Point(x1, y1);
    const b = new fabric.Point(x2, y2);
    // find this line's vector
    const vectorB = b.subtract(a);
    // find angle between line's vector and x axis
    let angleRad = Math.atan2(vectorB.y, vectorB.x);
    if (angleRad < 0) {
      angleRad = 2 * Math.PI + angleRad;
    }
    const angleDeg = fabric.util.radiansToDegrees(angleRad);
    // find initial horizontal position by rotating the tip back
    const c = fabric.util.rotatePoint(b.clone(), a, -angleRad);
    return { a, c, angleDeg };
  },
});

Line.fromObject = function(object, callback, forceAsync) {
  console.log(object);
  // return fabric.Object._fromObject('Arrow', object, callback, forceAsync);
  callback &&
    callback(
      new fabric.Line([object.x1, object.y1, object.x2, object.y2], object),
    );
};

fabric.Line = Line;

export default Line;
