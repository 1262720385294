import io from 'socket.io-client';

const socketUrl = process.env.WEBSOCKET_SERVER_URL;

if(!socketUrl) throw new Error('Websocket server url is not defined.');

export const socketFactory = () => {
  let sock = null;
  return () => {
    if (!sock) {
      sock = io(socketUrl, {
        timeout: 60000,
        reconnectionDelay: 1000,
        reconnection: true,
        reconnectionAttempts: 4,
        transports: ['websocket'],
        agent: false,
        upgrade: false,
        rejectUnauthorized: false,
      });
    }
    return sock;
  };
};

export default socketFactory();
