/*
 *
 * MediaGallery reducer
 *
 */
import produce from 'immer';
import { LOAD_MEDIA_SUCCESS, LOAD_MEDIA_ERROR, LOAD_MEDIA } from './constants';

export const initialState = {
  loading: false,
  error: false,
  page: 1,
  lastPage: false,
  data: [],
};

/* eslint-disable default-case, no-param-reassign */
const mediaGalleryReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_MEDIA:
        draft.loading = true;
        draft.page = action.page;
        if (action.page === 1) {
          draft.data = [];
          draft.lastPage = false;
        }
        break;
      case LOAD_MEDIA_ERROR:
        draft.error = true;
        draft.loading = false;
        draft.lastPage = true;
        break;
      case LOAD_MEDIA_SUCCESS:
        draft.loading = false;
        if (action.payload.length > 0) {
          draft.data = draft.data.concat(action.payload);
        } else {
          draft.lastPage = true;
        }
        break;
    }
  });

export default mediaGalleryReducer;
