/**
 *
 * ItemType
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNewspaper,
  faWrench,
  faTools,
  faBook,
  faServer,
  faPrint,
} from '@fortawesome/pro-solid-svg-icons';

import { injectIntl } from 'react-intl';
import messages from './messages';

function ItemType({ intl, type }) {
  return intl.formatMessage(messages[type]);
}

ItemType.propTypes = {
  intl: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const iconMap = {
  manuals: faTools,
  guides: faWrench,
  helps: faNewspaper,
  tooltips: faBook,
  category: faServer,
  post_tag: faPrint,
};

export function ItemTypeIcon({ type }) {
  return <FontAwesomeIcon icon={iconMap[type]} />;
}

ItemTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default injectIntl(ItemType);
