/**
 *
 * AdminMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
// import { FormattedMessage } from 'react-intl';

import { List, ListItem } from 'components/layout';
import { themeColor } from 'utils/themeHelpers';
// import messages from './messages';

const Nav = styled.nav`
  background-color: ${themeColor('main')};
  padding: 1em;
  height: 100%;
  margin-right: 1em;
  a {
    color: white;
    &:hover {
      color: black;
    }
  }
  @media print {
    display: none;
  }
`;

function AdminMenu({ language }) {
  const items = [
    { label: 'Articles', url: 'category/all', addUrl: 'article' },
    { label: 'News', url: 'news', addUrl: 'news' },
    { label: 'Guides', url: 'tag/all', addUrl: 'guide' },
    { label: 'Categories', url: 'categories', addUrl: 'category' },
    { label: 'Tags (Printers)', url: 'tags', addUrl: 'tag' },
    { label: 'Relations', url: 'relations', addUrl: 'relation' },
    { label: 'Tooltips', url: 'tooltips', addUrl: 'glossary' },
  ];

  return (
    <Nav>
      <List>
        {items.map(item => (
          <ListItem key={item.url} hover={false}>
            <NavLink to={`/${language}/admin/${item.url}`}>
              {item.label}
            </NavLink>
            {item.addUrl && (
              <Link
                to={`/${language}/admin/${item.addUrl}/new`}
                style={{
                  color: 'white',
                  float: 'right',
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </Nav>
  );
}

AdminMenu.propTypes = {
  language: PropTypes.string.isRequired,
};

export default AdminMenu;
