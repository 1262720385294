import { createSelector } from 'reselect';
import { initialState, initialGuidesState } from './reducer';

/**
 * Direct selector to the categoryPage state domain
 */

const selectCategoryPageDomain = state =>
  state.categoryPage || {
    articles: initialState,
    guides: initialGuidesState,
  };

/**
 * Other specific selectors
 */

/**
 * Default selector used by CategoryPage
 */

const makeSelectCategoryPage = () =>
  createSelector(
    selectCategoryPageDomain,
    substate => substate,
  );

const makeSelectCategoryArticles = () =>
  createSelector(
    selectCategoryPageDomain,
    substate => substate.articles,
  );

const makeSelectCategoryGuides = () =>
  createSelector(
    selectCategoryPageDomain,
    substate => substate.guides,
  );

export default makeSelectCategoryPage;
export {
  selectCategoryPageDomain,
  makeSelectCategoryArticles,
  makeSelectCategoryGuides,
};
