import { store } from 'react-notifications-component';

export function notifySuccess(message = '', title = 'Success', duration) {
  notify(title, message, 'success', duration);
}

export function notifyError(message = '', title = 'Error', duration) {
  notify(title, message, 'danger', duration);
}

export function notifyWarning(title, message = '') {
  notify(title, message, 'warning');
}

export function notifyInfo(title, message = '') {
  notify(title, message, 'info');
}

export function notify(title, message = '', type = 'success', duration = 5000) {
  store.addNotification({
    title,
    message,
    type,
    showIcon: true,
    insert: 'top',
    width: 200,
    container: 'top-right',
    animationIn: ['animated', 'flipInX'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
      onScreen: true,
    },
  });
}
