import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faThumbtack,
} from '@fortawesome/pro-light-svg-icons';

export const colorMap = {
  red: '#c1280b',
  orange: '#ff9024',
  yellow: '#f3e00e',
  green: '#16dc81',
  light_blue: '#15a1d6',
  blue: '#2343e8',
  violet: '#dc54b7',
  black: 'black',
};

export const ColorBullet = styled.span`
  display: block;
  position: ${props => props.relative ? 'relative' : 'absolute'};
  ${props => props.relative && 'cursor: pointer;'};
  width: 12px;
  height: 12px;
  color: ${props => colorMap[props.color]};
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  ${props => !props.relative && 'left: 5px; top: 1em;'}
  @media print {
    top: 0.5em;
  }
`;

export const IconWrapper = styled.span`
  ${props => !props.relative && 'left: 0; top: 1em;'}
  vertical-align: middle;
  display: block;
  width: 24px;
  text-align: center;
  position: ${props => props.relative ? 'relative' : 'absolute'};
  ${props => props.relative && 'cursor: pointer;'};
  color: ${props => props.color || 'black'};
  svg {
    width: 24px;
  }
  @media print {
    top: 0.5em;
  }
`;

export const iconMap = {
  note: faInfoCircle,
  caution: faExclamationTriangle,
  reminder: faThumbtack,
};

function Bullet({ color = 'black', icon, onClick, relative = false } = {}) {
  if (icon) {
    const faIcon = iconMap[icon];

    return (
      <IconWrapper
        color={icon === 'caution' ? 'red' : 'black'}
        onClick={onClick}
        relative={relative}
      >
        <FontAwesomeIcon
          icon={faIcon}
          className={icon === 'caution' ? 'fa-beat' : ''}
          size="lg"
        />
      </IconWrapper>
    );
  }
  return (
    <ColorBullet color={color} onClick={onClick} relative={relative}>
      {'\u2B22'}
    </ColorBullet>
  );
}

Bullet.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
};

export default Bullet;
