import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import { useLocalStorage, useModal } from '../../../utils/hooks';
import request from '../../../utils/request';
import Button from '../../layout/Button';
import Loader from '../../Loader';

function ReadConfirmationInfo({ id }) {
  const [open, openModal, closeModal] = useModal(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    request('v1/read_confirmation_data', { params: { post_id: id } }).then(
      setData,
    );
  }, [id]);

  return (
    <>
      <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? (
                data.map(row => (
                  <TableRow key={row.user_email}>
                    <TableCell>{row.user_email}</TableCell>
                    <TableCell>{row.timestamp}</TableCell>
                  </TableRow>
                ))
              ) : (
                <Loader />
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="border"
            target="_blank"
            href={`/api/v1/read_confirmation_export?post_id=${id}`}
          >
            Export all confirmations
          </Button>
          <Button variant="border" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="border" type="button" onClick={openModal}>
        View all confirmations
      </Button>
    </>
  );
}

ReadConfirmationInfo.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ReadConfirmationInfo;
