/**
 *
 * PrintModal
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import { List, ListItem, Button, Grid, Col, CheckboxLabel as Label } from 'components/layout';
import { notifyError } from 'utils/notifications';
import request from 'utils/request';
import { allowedLangs } from 'utils/constants';
import Loader from 'components/Loader';

function PrintModal({
  open,
  onClose,
  language = 'en',
  itemType = 'category',
  id,
  options,
}) {
  const [pdfLoading, setPdfLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [pdfErrors, setPdfErrors] = useState([]);
  const [stepsToExclude, setStepsToExclude] = useState('');
  const [toc, setToc] = useState(true);
  const [pn, setPn] = useState(true);
  const [qr, setQr] = useState(true);
  const [lang, setLang] = useState(language);
  const [format, setFormat] = useState('200x275');
  const [realOptions, setRealOptions] = useState(options || {});

  useEffect(() => {
    setRealOptions(options);
  }, [options]);

  function handlePrint() {
    setPdfLoading(true);
    setPdfErrors([]);
    // TODO item type
    const ids = Object.values(realOptions).filter(g => g.include).map(g => g.id).join(',');
    request(`v1/pdf`, {
      params: {
        category_id: id,
        ids,
        lng: lang,
        pn,
        toc,
        qr,
        format,
        placeholder,
        excludeSteps: stepsToExclude,
      },
    })
      .then(res => {
        setPdfLoading(false);
        setPdfUrl(res.data);
        setPdfErrors(res.errors);
      })
      .catch(err => {
        setPdfLoading(false);
        notifyError('Error generating PDF');
      });
  }

  function handleOptionCheck(id, val) {
    setRealOptions(realOptions.map(o => {
      if (o.id === id) {
        return { ...o, include: val };
      }
      return o;
    }));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <div style={{ padding: '0.5em 1em', textAlign: 'center' }}>
        <h3>PDF Generator</h3>
        <Grid>
          <Col cols={6} padding={2}>
            <h4 style={{ margin: 0 }}>Options:</h4>
            <List>
              <ListItem padding={0.1}>
                <Label>
                  Generate ToC
                  <input
                    checked={toc}
                    type="checkbox"
                    onChange={e => setToc(e.target.checked)}
                  />
                </Label>
              </ListItem>
              <ListItem padding={0.1}>
                <Label>
                  Add page numbers
                  <input
                    checked={pn}
                    type="checkbox"
                    onChange={e => setPn(e.target.checked)}
                  />
                </Label>
              </ListItem>
              <ListItem padding={0.1}>
                <Label>
                  Include QR code
                  <input
                    checked={qr}
                    type="checkbox"
                    onChange={e => setQr(e.target.checked)}
                  />
                </Label>
              </ListItem>
              <ListItem padding={0.1}>
                <Label>
                  Add first blank page
                  <input
                    checked={placeholder}
                    type="checkbox"
                    onChange={e => setPlaceholder(e.target.checked)}
                  />
                </Label>
              </ListItem>
              <ListItem padding={0.1}>
                <Label>
                  Language
                  <select onChange={e => setLang(e.target.value)}>
                    {allowedLangs.map(l => <option value={l}>{l.toUpperCase()}</option>)}
                  </select>
                </Label>
              </ListItem>
              <ListItem padding={0.1}>
                <Label>
                  Format
                  <select onChange={e => setFormat(e.target.value)}>
                    {['A4', 'A5', '200x275'].map(l => <option value={l}>{l}</option>)}
                  </select>
                </Label>
              </ListItem>
            </List>
            Steps to exclude (ids, coma separated): <input type="text" value={stepsToExclude} onChange={e => setStepsToExclude(e.target.value)} />
          </Col>
          <Col cols={6}>
            <h4 style={{ margin: 0 }}>Guides to include:</h4>
            <List>
              {realOptions.map(v => (
                <ListItem key={v.id} padding={0.1}>
                  <Label>
                    {v.title}
                    <input
                      checked={v.include}
                      type="checkbox"
                      onChange={e => handleOptionCheck(v.id, e.target.checked)}
                    />{' '}
                  </Label>
                </ListItem>
              ))}
            </List>
          </Col>
        </Grid>
        {!pdfLoading && pdfErrors && pdfErrors.length > 0 && (
          <>
            <p>Warnings</p>
            {pdfErrors.map(e => (
              <p>Image too big (over 8mb) in {e.type} {e.slug}: {e.img}</p>
            ))}
          </>
        )}
        {!pdfLoading && pdfUrl && (
          <a
            target="_blank"
            href={`/wp-content/uploads/pdf/${pdfUrl}`}
            style={{ marginRight: '1em' }}
          >
            Download PDF
          </a>
        )}
        <br />
        {pdfLoading ? (
          <div style={{ width: 100, margin: 'auto' }}>
            <Loader />
          </div>
        ) : (
          <Button onClick={handlePrint}>Generate</Button>
        )}
      </div>
    </Dialog>
  );
}

PrintModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  itemType: PropTypes.string,
  id: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default PrintModal;
