import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { urlBuilder } from 'utils/helpers';
import { Article, StyledLink, Text } from 'components/Guide';
import { Image, StatusBadge } from 'components/layout';
import { useDrag, useDrop } from 'react-dnd';
import { generateHoverHandler } from 'utils/formUtils';
import { useSocketUsers } from '../../../utils/adminHooks';
import EditLock, { EditOverlay } from '../EditLock';

const Inner = StyledLink.withComponent('div');

function AdminGuide({
  id,
  index,
  title,
  content,
  media,
  slug,
  language,
  status,
  moveGuide = () => null,
  dropCallback = () => null,
}) {
  const [, map] = useSocketUsers();
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'guide',
    hover: generateHoverHandler({ ref, callback: moveGuide, index }),
    drop: dropCallback,
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'guide', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const inner = (
    <>
      <Text>
        <h4>{title}</h4>
        {content}
      </Text>
      {media && (
        <Image
          image={media.image}
          size="thumbnail"
          alt={title}
          style={{
            borderBottomRightRadius: '4px',
            borderTopRightRadius: '4px',
            width: '100px',
          }}
        />
      )}
    </>
  );

  return (
    <Article ref={ref} style={{ opacity }}>
      <StatusBadge status={status} />
      {!map[id] ? (
        <StyledLink to={urlBuilder({ language, item: { slug, type: 'guides' } })}>
          {inner}
        </StyledLink>
      ) : (
        <Inner>
          <EditOverlay>
            <EditLock username={map[id].username} compact={false} />
          </EditOverlay>
          {inner}
        </Inner>
      )}
    </Article>
  );
}

AdminGuide.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  media: PropTypes.object,
  moveGuide: PropTypes.func,
  dropCallback: PropTypes.func,
};

export default AdminGuide;
