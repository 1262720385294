/**
 *
 * MediaGallery
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faFilePdf,
  faExclamationTriangle,
  faFileUpload,
} from '@fortawesome/pro-solid-svg-icons';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import Loader from 'components/Loader';
import {
  Grid,
  ButtonBig,
  Button,
  Tabs,
  Tab as NavTab,
  List,
  ListItem,
  CheckboxLabel as Label,
} from 'components/layout';
import request from 'utils/request';
import { themeColor } from 'utils/themeHelpers';
import { useModal, useLocalStorage } from 'utils/hooks';
import ImageFabricEdit from 'components/ImageEditor/FabricEdit';
import { colorMap } from 'components/Step/Bullet';
import Media from '../../components/Media';
import OkModal from '../../components/modals/OkModal';
import YesNoModal from '../../components/modals/YesNoModal';
import { makeSelectAdminFlag } from '../App/selectors';
import ImageEditorRc from './ImageCropper';
import { loadMediaAction } from './actions';
import makeSelectMediaGallery from './selectors';
import reducer from './reducer';
import saga from './saga';
import ImageMeta, { Meta } from './ImageMeta';

const Tab = NavTab.withComponent('button');

const Icon = styled.span`
  position: absolute;
  z-index: 200;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  color: ${themeColor('main')};
  border: 2px solid ${themeColor('main')};
  background-color: white;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const ImgWrapper = styled.div`
  position: relative;
  width: calc(20% - 0.5em);
  padding: 1px;
  margin: 0.25em;
  border: 1px solid
    ${props => (props.isSelected ? themeColor('main') : themeColor('grey'))};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &:hover ${Icon}, &:hover ${Meta} {
    display: flex;
  }
  @media screen and (min-width: 72em) {
    width: calc(12.5% - 0.5em);
  }
`;

const DropZoneInner = styled.div`
  text-align: center;
  min-height: 80vh;
  background-color: #eee;
  border: 2px dashed ${themeColor('lightGrey')};
  width: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
`;

const MetaData = styled.div`
  padding: 5px;
  font-size: 0.7em;
  text-align: center;
  font-style: italic;
  background-color: #efefef;
  width: 100%;
`;

const InfoBar = styled.aside`
  width: 15%;
  min-width: 220px;
  border-left: 1px solid ${themeColor('grey')};
  padding: 0.5em;
  right: 10px;
  top: 0;
  height: 100%;
`;

const InfoImgWrapper = styled.div`
  position: relative;
  padding: 2px;
  border: 1px solid ${themeColor('lightGrey')};
  margin-bottom: 2px;
  &:hover ${Meta} {
    display: flex;
  }
`;

const fileErrors = Object.freeze({
  BIG: 1,
  SMALL: 2,
  SIZE: 3,
});

export function MediaGallery({
  selectedItems = [],
  data,
  loadMedia,
  gallery = false,
  onImageClick = () => null,
  imageToEdit = false,
  onEditClose = () => null,
  isAdmin = false,
}) {
  useInjectReducer({ key: 'mediaGallery', reducer });
  useInjectSaga({ key: 'mediaGallery', saga });

  const [infoOpen, openInfo, closeInfo] = useModal(selectedItems.length > 0);
  const [filter, setFilter] = useLocalStorage(
    'pkb_media_gallery_filter',
    false,
  );
  const [customSelectedItems, setCustomSelectedItems] = useState([]);
  const [imgToCrop, setImgToCrop] = useState(false);
  const [aspectModalOpen, openAspectModal, closeAspectModal] = useModal(false);
  const [imgToEdit, setImgToEdit] = useState(imageToEdit);
  const [filesBuffer, setFilesBuffer] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [search, setSearch] = useState('');
  const wrapperRef = useRef(null);

  useEffect(() => {
    loadMedia(1, filter);
    if (selectedItems.length) {
      request(`v1/media`, { params: { ids: selectedItems.join(',') } }).then(
        resp => {
          const items = selectedItems.map(id => resp.find(i => i.id === id));
          setCustomSelectedItems(items);
        },
      );
      // Promise.all(real).then(setCustomSelectedItems);
    }
  }, [imgToEdit]);

  useEffect(() => {
    if (customSelectedItems.length > 0) {
      openInfo();
    } else {
      closeInfo();
    }
  }, [customSelectedItems]);

  function loadMore(e) {
    e.preventDefault();
    if (!data.lastPage) {
      loadMedia(data.page + 1, filter);
    }
  }

  function selectImg(e) {
    e.preventDefault();
    onImageClick(customSelectedItems);
  }

  function changeFilter(val) {
    setFilter(val);
    loadMedia(1, val);
  }

  async function handleImageClick(img) {
    if (!gallery) {
      setCustomSelectedItems([img]);
    } else if (customSelectedIds.includes(img.id)) {
      setCustomSelectedItems(customSelectedItems.filter(i => i.id !== img.id));
    } else {
      if (gallery) {
        const data = await addImageProcess(img.media.original);
        const aspectRatio = data.height / data.width;
        console.log("ASPECT", aspectRatio);
        //if (aspectRatio < 1.3 || aspectRatio > 1.5 || data.height < 600 || data.width < 800) { // or exact?
        //  openAspectModal();
        //} else {
          setCustomSelectedItems([...customSelectedItems, img]);
        //}
      }
    }
  }

  function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  }

  async function doUpload(file) {
    return request('wp/v2/media', {
      method: 'POST',
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': `attachment; filename="${file.name}"`,
      },
      body: file,
    });
  }

  async function handleUpload(acceptedFiles) {
    const rejectedImages = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      console.log('image up', file);
      if (file.type.indexOf('image') >= 0) {
        const mb = (file.size / 1024 / 1024).toFixed(2);
        if (mb > 7) {
          file.error = fileErrors.SIZE;
          rejectedImages.push(file);
          continue;
        }
        const img = await addImageProcess(window.URL.createObjectURL(file));

        if (img.width > 3200 || img.height > 2400) {
          file.error = fileErrors.BIG;
          rejectedImages.push(file);
          continue;
        }
        if (img.width < 800 || img.height < 600) {
          file.error = fileErrors.SMALL;
          rejectedImages.push(file);
          continue;
        }
      }

      try {
        const resp = await doUpload(file);
        console.log('file up', file);
      } catch (error) {
        console.error('ERR', error);
      }
    }
    if (rejectedImages.length === 0) {
      changeFilter('attached');
    }
    setFilesToUpload([]);
    setFilesBuffer(rejectedImages);
  }

  async function handleRepeatedUpload() {
    try {
      const res = await Promise.all(
        filesBuffer
          .filter(f => filesToUpload.includes(f.name))
          .map(file => doUpload(file)),
      );
      setFilesBuffer([]);
      setFilesToUpload([]);
      changeFilter('attached');
    } catch (error) {
      console.error('ERR', error);
    }
  }

  function handleImageSave(img, edit = false) {
    const oldImg = imgToCrop || imgToEdit;
    const body = {
      parent: oldImg.id,
      file: img,
      file_name: `${oldImg.title.replace('.', '_')}_${Math.round(
        Math.random() * 10000,
      )}_painted.${oldImg.mime_type.split('/')[1]}`,
    };
    request(`v1/media`, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(() => {
      if (edit) {
        setImgToEdit(false);
      } else {
        setImgToCrop(false);
      }
      loadMedia(1, filter);
      // onImageClick(customSelectedItems);
    });
  }

  function handleImageDelete(img) {
    if (window.confirm('Are you sure you want to delete this image?')) {
      request(`wp/v2/media/${img.id}`, {
        method: 'DELETE',
        params: { force: true },
      }).then(() => {
        loadMedia(1, filter);
      });
    }
  }

  function handleSearch(e) {
    const val = e.target.value;
    setSearch(val);
    if (val.length > 2) {
      loadMedia(1, '', val);
    }
  }

  function handleCloneImage(img) {
    request(`v1/media`, { params: { duplicate: img.id } }).then(() => {
      loadMedia(1, filter);
    });
  }

  function handleEditClose() {
    setImgToEdit(false);
    onEditClose();
  }

  function renderMediaItem(item) {
    switch (item.mime_type) {
      case 'model/3mf':
      case 'model/stl':
      case 'model/x.stl-binary':
      case 'application/octet-stream':
      case 'text/plain':
      case 'text/html':
      case 'application/pdf':
      case '':
        return renderFile(item);
      case 'video/webm':
      case 'video/mp4':
        return renderVideo(item);
      default:
        return renderImage(item);
    }
  }

  function handleOptionCheck(i, val) {
    if (filesToUpload.includes(i)) {
      setFilesToUpload(filesToUpload.filter(v => v !== i));
    } else {
      setFilesToUpload([...filesToUpload, i]);
    }
  }

  function renderVideo(item) {
    return (
      <ImgWrapper
        style={{ textAlign: 'center' }}
        key={item.id}
        isSelected={
          customSelectedItems && customSelectedIds.indexOf(item.id) >= 0
        }
      >
        <ImageMeta
          img={item}
          onImageDelete={isAdmin && handleImageDelete}
        />
        {customSelectedItems && customSelectedIds.indexOf(item.id) >= 0 ? (
          <Icon visible onClick={() => handleImageClick(item)}>
            <FontAwesomeIcon icon={faCheck} />
          </Icon>
        ) : (
          <Icon onClick={() => handleImageClick(item)} />
        )}
        <video style={{ width: '100%', zIndex: 5 }} controls>
          <source src={item.media.original} type={item.mime_type} />
          Your browser does not support HTML5 video.
        </video>
        <MetaData>
          {item.title}
          <br />
          {item.created}
        </MetaData>
      </ImgWrapper>
    );
  }

  function renderFile(file) {
    return (
      <ImgWrapper style={{ textAlign: 'center' }} key={file.id}>
        <ImageMeta img={file} />
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <p>{file.title}</p>
          <code style={{ wordBreak: 'break-all', fontSize: '0.7rem' }}>
            {file.media.url}
          </code>
        </div>
        <MetaData>
          {file.created}
        </MetaData>
      </ImgWrapper>
    );
  }

  function renderImage(img) {
    return (
      <ImgWrapper
        key={img.id}
        isSelected={
          customSelectedItems && customSelectedIds.indexOf(img.id) >= 0
        }
      >
        <ImageMeta
          img={img}
          onCloneImage={handleCloneImage}
          onImageDelete={isAdmin && handleImageDelete}
          setImgToCrop={setImgToCrop}
          setImgToEdit={setImgToEdit}
        />
        {customSelectedItems && customSelectedIds.indexOf(img.id) >= 0 ? (
          <Icon visible onClick={() => handleImageClick(img)}>
            <FontAwesomeIcon icon={faCheck} />
          </Icon>
        ) : (
          <Icon onClick={() => handleImageClick(img)} />
        )}
        {/* <Img src={img.media.thumbnail} /> */}
        <Media image={img.media} />
        <MetaData>
          {img.title}
        </MetaData>
      </ImgWrapper>
    );
  }

  const customSelectedIds = customSelectedItems.map(img => img.id);
  const bigFiles = filesBuffer.filter(f => f.error === fileErrors.BIG);
  const smallFiles = filesBuffer.filter(f => f.error === fileErrors.SMALL);
  const sizeFiles = filesBuffer.filter(f => f.error === fileErrors.SIZE);

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <div
        style={{
          overflowY: 'scroll',
          height: '90vh',
          flex: 1,
        }}
        ref={wrapperRef}
      >
        {imgToCrop && (
          <ImageEditorRc
            // ref='cropper'
            // crossOrigin='true' // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
            src={imgToCrop.media.original}
            style={{ height: 400, width: 400 }}
            aspectRatio={4 / 3} // 16 / 9
            guides
            rotatable
            scalable
            zoomable
            saveImage={handleImageSave}
            responseType="blob/base64"
          />
        )}
        {imgToEdit && (
          <ImageFabricEdit
            image={{
              ...imgToEdit,
              url: imgToEdit.media
                ? imgToEdit.media.original
                : imageToEdit.original,
            }}
            editable
            wrapperRef={wrapperRef}
            onSave={(data, base64) => handleImageSave(base64, true)}
            onClose={handleEditClose}
            colors={Object.values(colorMap)}
          />
        )}
        {!imgToCrop && !imgToEdit && (
          <div>
            <Tabs>
              <Tab
                className={!filter ? 'active' : ''}
                onClick={e => {
                  e.preventDefault();
                  changeFilter(false);
                }}
              >
                All
              </Tab>
              <Tab
                className={filter === 'author' ? 'active' : ''}
                onClick={e => {
                  e.preventDefault();
                  changeFilter('author');
                }}
              >
                Uploaded by me
              </Tab>
              <Tab
                className={filter === 'attached' ? 'active' : ''}
                onClick={e => {
                  e.preventDefault();
                  changeFilter('attached');
                }}
              >
                Unused
              </Tab>
              <Tab
                className={filter === 'upload' ? 'active' : ''}
                onClick={e => {
                  e.preventDefault();
                  setFilter('upload');
                }}
              >
                Upload new
              </Tab>
            </Tabs>
            {!filter && <p style={{ margin: '0.5em' }}>Search by file name: <input type="text" onChange={handleSearch} value={search} /></p>}
            {filter !== 'upload' ? (
              <>
                <p style={{ padding: '0 1em' }}>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ color: 'red', margin: '0 5px' }}
                  />
                  Using the same image without duplicating it means that they
                  are linked. Any change (e.g. drawing an arrow) will appear to
                  all of them. Consider duplicating the pic first to break this
                  link.
                </p>
                <Grid noMargin style={{ padding: '1em' }}>
                  {data.data.map(renderMediaItem)}
                  {data.loading && <Loader />}
                </Grid>
                <aside
                  style={{
                    margin: '1em auto',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {!data.lastPage ? (
                    <ButtonBig onClick={loadMore}>Load more</ButtonBig>
                  ) : (
                    'You have reached the end of the universe.'
                  )}
                </aside>
              </>
            ) : (
              <Dropzone onDrop={handleUpload}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <DropZoneInner {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon
                      icon={faFileUpload}
                      size="6x"
                      style={{ marginBottom: '1em' }}
                    />
                    <div style={{ height: '100%', width: '100%' }}>
                      {isDragActive
                        ? 'Drop the files here ...'
                        : "Drag 'n' drop some files here, or click to select files"}
                    </div>
                  </DropZoneInner>
                )}
              </Dropzone>
            )}
          </div>
        )}
      </div>
      {infoOpen && (
        <InfoBar>
          {customSelectedItems.map(img => (
            <InfoImgWrapper key={img.id}>
              <ImageMeta
                img={img}
                onImageDelete={() =>
                  setCustomSelectedItems(
                    customSelectedItems.filter(i => i.id !== img.id),
                  )
                }
                onCloneImage={handleCloneImage}
                setImgToEdit={setImgToEdit}
                setImgToCrop={setImgToCrop}
              />
              {/* <Img src={img.media && img.media.thumbnail} width={200} /> */}
              {img.mime_type.startsWith('video') ? (
                <video style={{ width: '100%', zIndex: 5 }} controls>
                  <source src={img.media.original} type={img.mime_type} />
                  Your browser does not support HTML5 video.
                </video>
              ) : (
                <Media image={img.media} width={200} />
              )}
              {/* <small>
                      Uploaded: {img.date}
                    </small> */}
            </InfoImgWrapper>
          ))}
          <Button onClick={selectImg}>Select</Button>
        </InfoBar>
      )}
      <OkModal onClose={closeAspectModal} open={aspectModalOpen} style={{ zIndex: 99999999 }}>
        <p>Selected image has incorrect aspect ratio (only 4:3 allowed) or have too low resolution (min. 800x600) and therefore can't be used for step gallery.</p>
      </OkModal>
      <YesNoModal
        open={filesBuffer.length > 0}
        onClose={() => setFilesBuffer([])}
        onSuccess={handleRepeatedUpload}
        yesDisabled={filesToUpload.length === 0}
        style={{ zIndex: 99999999 }}
      >
        <p>Some of the files contain errors or warnings.</p>
        {bigFiles.length > 0 && (
          <>
            <h5 style={{ color: 'orange' }}>Exceeding resolution 3200x2400:</h5>
            <List>
              {bigFiles.map(f => (
                <ListItem padding={0.1}>
                  <Label>
                    {f.name}
                    <input
                      type="checkbox"
                      checked={filesToUpload.includes(f.name)}
                      onChange={e =>
                        handleOptionCheck(f.name, e.target.checked)
                      }
                    />
                  </Label>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {smallFiles.length > 0 && (
          <>
            <h5 style={{ color: 'red' }}>Resolution too small (under 800x600):</h5>
            <List>
              {smallFiles.map(f => (
                <ListItem padding={0.1}>
                  <Label>
                    {f.name}
                    <input
                      type="checkbox"
                      checked={filesToUpload.includes(f.name)}
                      onChange={e =>
                        handleOptionCheck(f.name, e.target.checked)
                      }
                    />
                  </Label>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {sizeFiles.length > 0 && (
          <>
            <h5 style={{ color: 'red' }}>Exceeding size of 7MB:</h5>
            <List>
              {sizeFiles.map(f => (
                <ListItem padding={0.1}>
                  <Label>
                    {f.name}
                    <input
                      type="checkbox"
                      disabled
                    />
                  </Label>
                </ListItem>
              ))}
            </List>
          </>
        )}
        <p>Do you wish to proceed with upload of selected files?</p>
      </YesNoModal>
    </div>
  );
}

MediaGallery.propTypes = {
  loadMedia: PropTypes.func.isRequired,
  onImageClick: PropTypes.func,
  data: PropTypes.object,
  gallery: PropTypes.bool,
  selectedItems: PropTypes.array,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  data: makeSelectMediaGallery(),
  isAdmin: makeSelectAdminFlag(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadMedia: (page, filter = false, search = false) =>
      dispatch(loadMediaAction(page, filter, search)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(MediaGallery);
