/**
 * plugin.js
 *
 * Copyright, Moxiecode Systems AB
 * Released under LGPL License.
 *
 * License: http://www.tinymce.com/license
 * Contributing: http://www.tinymce.com/contributing
 */
/* global tinymce:true */

export default function advlistPlugin(editor) {
  const lastStyles = {};

  function setStart() {
    let v;
    const { dom } = editor;
    const sel = editor.selection;

    // Check for existing list element
    const list = dom.getParent(sel.getNode(), 'ol');
    if (list) {
      v = editor.dom.getAttrib(list, 'start', 1);
    } else {
      v = 1;
    }

    const data = { start: `${v}` };

    editor.windowManager.open({
      title: 'List numbering',
      body: {
        type: 'panel',
        items: [
          {
            label: 'Start From',
            name: 'start',
            type: 'input',
            minWidth: 50,
            maxWidth: 50,
          },
        ],
      },
      initialData: data,
      buttons: [
        {
          text: 'Ok',
          type: 'submit',
          subtype: 'primary',
        },
        {
          text: 'Cancel',
          type: 'cancel',
        },
      ],
      onSubmit: api => {
        const listA = dom.getParent(editor.selection.getNode(), 'ol');
        if (listA) {
          const dataA = api.getData();
          editor.dom.setAttrib(listA, 'start', dataA.start);
        }
        api.close();
      },
      onCancel: api => {
        api.close();
      },
    });
  }

  function buildMenuItems(listName, styleValues) {
    const items = [];

    tinymce.each(styleValues.split(/[ ,]/), function(styleValue) {
      const iconStyle = listName === 'OL' ? 'num' : 'bull';
      const iconName =
        styleValue === 'disc' || styleValue === 'decimal'
          ? 'default'
          : styleValue;
      items.push({
        text: styleValue.replace(/-/g, ' ').replace(/\b\w/g, function(chr) {
          return chr.toUpperCase();
        }),
        value: styleValue === 'default' ? '' : styleValue,
        icon: `list-${iconStyle}-${iconName}`,
        type: 'choiceitem',
      });
    });

    return items;
  }

  const olMenuItems = buildMenuItems(
    'OL',
    editor.getParam(
      'advlist_number_styles',
      'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman',
    ),
  );
  olMenuItems.push({
    text: 'Start Number',
    value: 'startnumber',
    type: 'choiceitem',
    icon: 'settings',
  });

  const ulMenuItems = buildMenuItems(
    'UL',
    editor.getParam('advlist_bullet_styles', 'default,circle,disc,square'),
  );

  function applyListFormat(listName, styleValue) {
    editor.undoManager.transact(function() {
      let list;
      const { dom } = editor;
      const sel = editor.selection;

      // Check for existing list element
      list = dom.getParent(sel.getNode(), 'ol,ul');

      // Switch/add list type if needed
      if (!list || list.nodeName !== listName || styleValue === false) {
        editor.execCommand(
          listName === 'UL' ? 'InsertUnorderedList' : 'InsertOrderedList',
        );
      }

      // Set style
      styleValue = styleValue === false ? lastStyles[listName] : styleValue;
      lastStyles[listName] = styleValue;

      list = dom.getParent(sel.getNode(), 'ol,ul');
      if (list) {
        dom.setStyle(list, 'listStyleType', styleValue || null);
        list.removeAttribute('data-mce-style');
      }

      editor.focus();
    });
  }

  function updateSelection(e) {
    const listStyleType =
      editor.dom.getStyle(
        editor.dom.getParent(editor.selection.getNode(), 'ol,ul'),
        'listStyleType',
      ) || '';

    e.control.items().each(function(ctrl) {
      ctrl.active(ctrl.settings.data === listStyleType);
    });
  }

  editor.ui.registry.addSplitButton('numlista', {
    type: 'splitbutton',
    tooltip: 'Numbered list',
    icon: 'ordered-list',
    presets: 'listpreview',
    columns: 3,
    fetch: callback => {
      callback(olMenuItems);
    },
    onShow: updateSelection,
    onItemAction: (api, e) => {
      if (e === 'startnumber') {
        setStart();
      } else {
        applyListFormat('OL', e);
      }
    },
    onAction: () => {
      applyListFormat('OL', false);
    },
  });

  editor.ui.registry.addSplitButton('bullista', {
    type: 'splitbutton',
    tooltip: 'Bullet list',
    icon: 'unordered-list',
    presets: 'listpreview',
    columns: 3,
    fetch: callback => {
      callback(ulMenuItems);
    },
    onShow: updateSelection,
    onItemAction: (api, e) => {
      applyListFormat('UL', e);
    },
    onAction: () => {
      applyListFormat('UL', false);
    },
  });
}
