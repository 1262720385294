import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Slider from 'react-slick';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import { Player } from 'video-react';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons';

import { CommentIcon } from '../layout/Icons';
import { PrintHidden, PrintVisible, H2 } from '../layout';
import Loader from '../Loader';
import CommentSection from '../../containers/CommentSection/Loadable';
import { scrollWithOffset } from '../../utils/helpers';
import { useModal } from '../../utils/hooks';
import Anchor from '../Anchor';
import EditButton from '../EditButton';
import Media from '../Media';
import SmallErrorBoundary from '../SmallErrorBoundary';
import TranslationStatus from '../TranslationStatus';

import Bullet from './Bullet';
import Line from './Line';
import messages from './messages';

const Article = styled.article`
  margin-bottom: 2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1em;
  min-height: 400px;
  position: relative;
  @media print {
    page-break-inside: avoid;
    border-bottom: none;
    padding-top: 2em;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
const Main = styled.div`
  display: flex;
  margin-bottom: 1em;
  @media screen and (max-width: 40em) {
    flex-direction: column;
  }
  @media print {
    ${props =>
    props.mediaCount > 1 ? 'flex-direction: column' : 'flex-direction: row'};
  }
`;

const Button = styled.button`
  color: ${props => (props.isMainColor ? '#fa6831' : '#8C8C8C')};
  position: absolute;
  right: 0;
  bottom: -1.25em;
  cursor: pointer;
  padding: 0.5em 0 0.5em 1em;
  border: none;
  background: white;
  outline: none;
`;

const ZoomIcon = styled.span`
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: 5px;
  transition: opacity 500ms;
  color: #333;
  border: 1px solid #888;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  padding: 2px 5px;
`;

export const SliderWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  width: 60%;
  img {
    cursor: pointer;
  }
  &:hover {
    ${ZoomIcon} {
      opacity: 1;
    }
  }
  @media screen and (max-width: 40em) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1em;
  }
  @media print {
    max-width: 100%;
    margin-right: 0;
    ${props =>
    props.mediaCount > 1 ? 'width: auto' : 'width: 50%; margin-right: 1em'};
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
  }
`;

const LineWrapper = styled.div`
  width: ${({ hasMedia }) => (hasMedia ? '40%' : '100%')};
  @media screen and (max-width: 40em) {
    width: 100%;
  }
  @media print {
    ${props => (props.mediaCount > 1 ? 'width: 100%' : 'width: 50%')};
  }
`;

const Span = styled.span`
  margin-left: 1em;
  font-weight: normal;
`;

const GalleryWrapper = styled.div`
  display: none;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  max-height: 300px;
  height: auto;
  & > * {
    flex: 1;
  }
  img {
    border: 1px solid #333;
  }
  @media print {
    display: flex;
  }
`;

const H2spec = styled(H2)`
  color: #000;
  @media print {
    -webkit-print-color-adjust: exact;
    background-color: #cecece;
    padding: 0.5em;
  }
`;

const getGallerySettings = gallery => ({
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  arrows: true,
  // infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging(i) {
    return (
      <a key={i} style={{ maxWidth: '100px', display: 'block' }} href={`#${i}`}>
        <Media image={gallery && gallery[i]} size="medium" />
      </a>
    );
  },
});

export function renderLine({ meta = {}, id, title }) {
  const { level = 0, ...metaRest } = meta || {};
  return (
    <Line key={id} level={level} icon={metaRest.icon}>
      <Bullet {...metaRest} />
      {parse(title)}
    </Line>
  );
}

function imageAccessor(image) {
  return image.child && image.child.original
    ? image.child.original
    : image.original;
}

function Step({
  id,
  lines,
  title,
  translation_status,
  media,
  iterator,
  comments: commentsCount,
  slug,
  isAdmin = false,
  language,
  guideSlug,
  forceCommentsOpen = false,
  printQuality = false,
}) {
  const { gallery, video, image, yt_video } = media;
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isOverlayOpen, openOverlay, closeOverlay] = useModal(false);
  const settings = gallery ? getGallerySettings(gallery) : {};

  function handleImageClick(e, img) {
    e.preventDefault();
    setLightboxOpen(true);
    setLightboxIndex(img);
  }

  const stepRef = useRef(null);

  useEffect(() => {
    if (parseInt(window.location.hash.substr(1), 0) === id) {
      if (stepRef.current) {
        setTimeout(() => scrollWithOffset(stepRef.current, -260, false), 100);
      }
    }
  }, []);

  function renderMedia() {
    if (yt_video) {
      return (
        <iframe
          src={yt_video.replace('watch?v=', 'embed/')}
          width="100%"
          height="400"
          frameBorder={0}
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      );
    }
    if (video) {
      return (
        <SliderWrapper>
          <Media image={media} />
        </SliderWrapper>
      );
    }
    if (gallery) {
      return (
        <SliderWrapper mediaCount={gallery.length}>
          <PrintHidden>
            <Slider {...settings}>
              {gallery.map((img, i) => (
                <div key={img.medium}>
                  <ZoomIcon onClick={e => handleImageClick(e, i)}>
                    <FontAwesomeIcon icon={faSearchPlus} color="#333"/>
                  </ZoomIcon>
                  <Media
                    image={img}
                    alt={title}
                    size="medium"
                    onClick={e => handleImageClick(e, i)}
                  />
                </div>
              ))}
            </Slider>
            {lightboxOpen && (
              <Lightbox
                mainSrc={imageAccessor(gallery[lightboxIndex])}
                nextSrc={gallery.length > 1 ? imageAccessor(
                  gallery[(lightboxIndex + 1) % gallery.length],
                ) : undefined}
                prevSrc={gallery.length > 1 ?imageAccessor(
                  gallery[
                  (lightboxIndex + gallery.length - 1) % gallery.length
                    ],
                ) : undefined}
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() =>
                  setLightboxIndex(
                    (lightboxIndex + gallery.length - 1) % gallery.length,
                  )
                }
                onMoveNextRequest={() =>
                  setLightboxIndex((lightboxIndex + 1) % gallery.length)
                }
              />
            )}
          </PrintHidden>
          <GalleryWrapper>
            {gallery.map((img, i) => (
              <Media
                key={img.id}
                image={img}
                alt={title}
                size="original"
                adaptive
                onClick={e => handleImageClick(e, i)}
              />
            ))}
          </GalleryWrapper>
        </SliderWrapper>
      );
    }

    return (
      <SliderWrapper>
        <ZoomIcon>
          <FontAwesomeIcon icon={faSearchPlus} color="#333" />
        </ZoomIcon>
        <Media
          image={image}
          alt={title}
          key={image.medium}
          size="medium"
          // onClick={e => this.handleImageClick(e, i)}
        />
      </SliderWrapper>
    )
  }

  return (
    <Anchor id={id.toString()}>
      <Article
        ref={stepRef}
        onMouseEnter={openOverlay}
        onMouseLeave={closeOverlay}
      >
        <NavLink
          smooth
          exact
          to={`${window.location.search}#${id}`}
          activeClassName="selected"
          scroll={el => scrollWithOffset(el, -260)}
        >
          <H2spec>
            <FormattedMessage {...messages.step} /> {iterator} <Span>{title}</Span>
            <TranslationStatus
              language={language}
              status={translation_status}
            />
          </H2spec>
        </NavLink>
        <Main mediaCount={gallery.length}>
          {renderMedia()}
          <SmallErrorBoundary>
            <LineWrapper
              hasMedia={image || video || gallery}
              mediaCount={gallery.length}
            >
              {lines && lines.length ? lines.map(renderLine) : <Loader />}
            </LineWrapper>
          </SmallErrorBoundary>
        </Main>
        <PrintHidden>
          {!commentsCount ? (
            <Button
              onClick={() => setCommentsOpen(!commentsOpen)}
              isMainColor={false}
            >
              <CommentIcon width={20} />{' '}
              <FormattedMessage {...messages.addComment} />
            </Button>
          ) : (
            <Button onClick={() => setCommentsOpen(!commentsOpen)} isMainColor>
              <CommentIcon width={20} /> {commentsCount}{' '}
              <FormattedMessage {...messages.comments} />
            </Button>
          )}
        </PrintHidden>
        {isOverlayOpen && isAdmin && (
          <EditButton
            url={`/${language}/admin/guide/${guideSlug}/steps/${iterator - 1}`}
          >
            Edit
          </EditButton>
        )}
      </Article>
      <CommentSection
        open={commentsOpen || forceCommentsOpen}
        parentId={id}
        commentsOpen
      />
    </Anchor>
  );
}

Step.propTypes = {
  lines: PropTypes.array,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  iterator: PropTypes.number,
  comments: PropTypes.number,
  media: PropTypes.object,
  language: PropTypes.string.isRequired,
  id: PropTypes.number,
  isAdmin: PropTypes.bool.isRequired,
  guideSlug: PropTypes.string,
};

export { Bullet, Line };

export default Step;
