import styled from 'styled-components';

export default styled.div`
  float: left;
  margin: 0 3px 0 0;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.25);
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  border: ${({ selected }) => selected ? '2px solid white' : 'none'};
`;
