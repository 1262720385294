import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { FormattedMessage, useIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import TagManager from 'react-gtm-module';

import request from 'utils/request';
import ItemType from 'components/ItemType';
import Loader from 'components/Loader';
import { toggleSearchAction } from 'containers/App/actions';
import { Wrapper, ButtonBig, Grid, Col } from 'components/layout';
import {
  makeSelectCategoriesBySlug,
  makeSelectSearchOpen,
  makeSelectPreviewMode,
} from 'containers/App/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { useDebounce } from 'utils/hooks';

import messages from './messages';
import Suggestion from './Suggestion';
import Input, { Icon, InputContainer } from './Input';
import Menu, { MenuItem, Section } from './Menu';

const Test = styled.div``;

const Main = styled.div`
  width: auto;
  display: inline-block;
  max-width: 100%;
  @media print {
    display: none;
  }
`;

function SearchInput({
  color,
  historyPush,
  language,
  categories,
  toggleSearch,
  height = 100,
  types = ['categories', 'guides', 'helps', 'tooltips'],
  typesToRender = ['post_tag', 'category', 'manuals', 'guides', 'helps'],
  // searchOpen,
  inAdmin = false,
  isPreview = false,
  defaultValue = '',
  disableAutocomplete = false,
}) {
  const [suggested, setSuggestions] = useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const intl = useIntl();

  async function fillSuggestions(inputValue = '') {
    const result = await request('v1/search', {
      params: {
        search: inputValue,
        per_page: 20,
        lng: language,
        type: types.join(','),
        admin: inAdmin || isPreview,
      },
    });

    const sugg = result.data.reduce((acc, val) => {
      if (!acc[val.type]) {
        acc[val.type] = [];
      }
      acc[val.type].push(val);
      return acc;
    }, {});

    setLoading(false);
    setSuggestions(sugg);
  }

  const [handleChangeDebounced] = useDebounce(handleChange, 250);
  const [handleGtmEventDebounced] = useDebounce(handleGtmEvent, 1000);

  async function handleGtmEvent(searchPhrase) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        searchPhrase: searchPhrase.toLowerCase(),
      },
    });
  }

  async function handleChange(
    { inputValue, selectedItem, isOpen = null },
    helpers,
  ) {
    if (helpers.isOpen !== null) {
      toggleSearch(helpers.isOpen);
    }
    // Abort when selecting item?
    if (selectedItem && inputValue === selectedItem?.title) return;
    if (inputValue && inputValue.length >= 3) {
      setLoading(true);
      handleGtmEventDebounced(inputValue);
      await fillSuggestions(inputValue);
    } else if (!inputValue || (inputValue && inputValue.length < 3)) {
      helpers.closeMenu();
      toggleSearch(false);
      setSuggestions({});
    }
  }

  function handleSelected(item, helpers) {
    toggleSearch(false);
    helpers.closeMenu();
    /* historyPush(
      urlBuilder({
        language: inAdmin ? `${language}/admin` : language,
        item,
      }),
    ); */
  }

  function getSearchUrl(val) {
    return `/${inAdmin ? `${language}/admin` : language}/search/?s=${encodeURIComponent(
      val,
    )}`;
  }

  if (disableAutocomplete) {
    return (
      <Main>
        <InputContainer>
          <Icon>
            <FontAwesomeIcon icon={faSearch} />
          </Icon>
          <label htmlFor="search-input" style={{ display: 'none' }}>
            Search
          </label>
          <Input
            id="search-input"
            {...{
              color,
              placeholder: intl.formatMessage(messages.main),
              value,
              onChange: e => setValue(e.target.value),
              onKeyDown(e) {
                if (e.key === 'Enter') {
                  historyPush(getSearchUrl(value));
                }
                return null;
              },
            }}
            tabindex={1}
          />
        </InputContainer>
      </Main>
    );
  }

  const { tooltips } = suggested;

  return (
    <Main>
      <Downshift
        onChange={handleSelected}
        onStateChange={handleChangeDebounced}
        itemToString={i => (i ? i?.title : '')}
        initialInputValue={defaultValue}
      >
        {({
          getInputProps,
          getRootProps,
          getLabelProps,
          getItemProps,
          closeMenu,
          isOpen,
          inputValue,
          highlightedIndex,
        }) => (
          <Test {...getRootProps()}>
            <InputContainer>
              <Icon>
                <FontAwesomeIcon icon={faSearch} size="lg" />
              </Icon>
              <label
                id="search-input"
                {...getLabelProps()}
                style={{ display: 'none' }}
              >
                Search
              </label>
              <Input
                id="search-input"
                {...getInputProps({
                  color,
                  placeholder: intl.formatMessage(messages.main),
                  onKeyDown(e) {
                    if (e.key === 'Enter' && highlightedIndex === null) {
                      closeMenu();
                      toggleSearch(false);
                      historyPush(getSearchUrl(inputValue));
                    }
                    return null;
                  },
                })}
                tabindex={1}
              />
            </InputContainer>
            {isOpen && inputValue.length >= 3 ? (
              <Menu maxHeight={height}>
                {loading ? (
                  <Loader fullPage />
                ) : (
                  <Wrapper>
                    <Grid noMargin>
                      <Col cols={tooltips && tooltips.length > 0 ? 8 : 12}>
                        {Object.keys(suggested).length > 0 ? (
                          typesToRender.map(
                            cat =>
                              suggested[cat] && (
                                <Section key={cat}>
                                  <h4>
                                    <ItemType type={cat} />
                                  </h4>
                                  {suggested[cat].map((suggestion, index) =>
                                    Suggestion({
                                      language,
                                      inAdmin,
                                      categories,
                                      suggestion,
                                      index,
                                      itemProps: getItemProps({
                                        item: suggestion,
                                      }),
                                      highlightedIndex,
                                      inputValue,
                                      column: 'title',
                                    }),
                                  )}
                                </Section>
                              ),
                          )
                        ) : (
                          <MenuItem key="404" style={{ textAlign: 'center' }}>
                            <FormattedMessage {...messages.nothingFound} />
                          </MenuItem>
                        )}
                      </Col>
                      {tooltips && tooltips.length > 0 && (
                        <Col cols={4}>
                          <Section>
                            <h4>
                              <FormattedMessage {...messages.glossary} />
                            </h4>
                            {tooltips.map((suggestion, index) =>
                              Suggestion({
                                language,
                                inAdmin,
                                categories,
                                suggestion,
                                index,
                                itemProps: getItemProps({ item: suggestion }),
                                highlightedIndex,
                                inputValue,
                                column: 'title',
                              }),
                            )}
                          </Section>
                        </Col>
                      )}
                    </Grid>
                    {Object.keys(suggested).length > 0 && (
                      <div style={{ textAlign: 'center', marginTop: '2em' }}>
                        <ButtonBig
                          onClick={() => {
                            toggleSearch(false);
                            closeMenu();
                            historyPush(getSearchUrl(inputValue));
                          }}
                        >
                          <FormattedMessage {...messages.showAllResults} />
                        </ButtonBig>
                      </div>
                    )}
                  </Wrapper>
                )}
              </Menu>
            ) : null}
          </Test>
        )}
      </Downshift>
    </Main>
  );
}

SearchInput.propTypes = {
  color: PropTypes.string,
  searchOpen: PropTypes.bool,
  toggleSearch: PropTypes.func.isRequired,
  historyPush: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  languageOverride: PropTypes.string,
  defaultValue: PropTypes.string,
  disableAutocomplete: PropTypes.bool,
  height: PropTypes.number,
  categories: PropTypes.object,
  types: PropTypes.arrayOf(PropTypes.string),
  typesToRender: PropTypes.arrayOf(PropTypes.string),
  inAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
  searchOpen: makeSelectSearchOpen(),
  categories: makeSelectCategoriesBySlug(),
  isPreview: makeSelectPreviewMode(),
});

const mapDispatchToProps = dispatch => ({
  historyPush: url => dispatch(push(url)),
  toggleSearch: show => dispatch(toggleSearchAction(show)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
