import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CheckboxLabel as Label } from 'components/layout';
import { useModal } from '../../utils/hooks';
import request from '../../utils/request';
import Loader from '../Loader';
import YesNoModal from '../modals/YesNoModal';

function EmailNotificationButton({ entityId }) {
  const [departments, setDepartments] = useState([]);
  const [modalOpen, openModal, closeModal] = useModal(false);
  const [loading, setLoading] = useState(false);
  const [chooseDeps, setChooseDeps] = useState(false);
  const [selectedDeps, setSelectedDeps] = useState([]);
  async function getDepartments() {
    const data = await request('v1/get_prusa3d_departments');
    setDepartments(data);
  }

  async function sendNotifications() {
    setLoading(true);
    try {
      const resp = await request(
        `v1/send_email_notifications?type=new_post&post_id=${entityId}`,
        { method: 'POST' },
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      closeModal();
    }
  }

  useEffect(() => {
    if (chooseDeps === true && departments.length === 0) {
      getDepartments();
    }
  }, [chooseDeps]);

  return (
    <div>
      <YesNoModal
        onClose={closeModal}
        open={modalOpen}
        onSuccess={sendNotifications}
        yesDisabled={loading}
      >
        {chooseDeps ? (
          <>
            <h2>Send email notification to departments:</h2>
            {Object.entries(departments).map(([key, label]) => (
              <Label>
                <input type="checkbox" value={key} key={key} />
                {label}
              </Label>
            ))}
            <strong>Send?</strong>
          </>
        ) : (
          <>
            <h2>Send email notifications to all employees?</h2>
            {/* <p>
              <Button onClick={() => setChooseDeps(true)}>
                Choose specific departments
              </Button>
            </p> */}
          </>
        )}
        <br />
        <br />
        <br />
        {loading && <Loader />}
      </YesNoModal>
      <Button
        onClick={e => {
          e.preventDefault();
          openModal();
        }}
      >
        Send email notifications
      </Button>
    </div>
  );
}

EmailNotificationButton.propTypes = {
  entityId: PropTypes.number.isRequired,
};

export default EmailNotificationButton;
