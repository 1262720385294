/**
 *
 * RedirectPage
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../components/Loader';
import { urlBuilder } from '../../utils/helpers';
import request from '../../utils/request';

export function RedirectPage({ match, history }) {
  const { id } = match.params;

  useEffect(() => {
    request(`v1/all`, { params: { id } })
      .then(resp => {
        if (!!resp.data.slug) {
          const item = resp.data;
          history.push(urlBuilder({ language: item.lng, item }));
        } else {
          history.push('/en/404');
        }
      })
      .catch(err => {
        history.push('/en/404');
      });
  }, [id]);

  return <Loader />;
}

RedirectPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default RedirectPage;
