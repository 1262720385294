import { call, put, takeLatest, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import request from 'utils/request';
import { notifyError, notifySuccess } from 'utils/notifications';
import { loadGlobal } from 'containers/App/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { makeSelectGuides } from 'containers/TagPage/selectors';
import { setLoading } from '../AdminSection/actions';

import { saveTagError } from './actions';
import { SAVE_GUIDE_ORDER, DELETE_TAG, SAVE_TAG } from './constants';

function* tagSaveSaga(action) {
  try {
    let data;
    let id;
    yield put(setLoading(true));
    if (action.id) {
      data = yield call(request, `wp/v2/tags/${action.id}`, {
        method: 'POST',
        body: JSON.stringify(action.tag),
      });
      id = action.id;
      notifySuccess('Tag updated!');
    } else {
      data = yield call(request, `wp/v2/tags`, {
        method: 'POST',
        body: JSON.stringify(action.tag),
      });
      id = data.id;
      notifySuccess('Tag created!');
      if (action.redirect) {
        const lang = yield select(makeSelectLocale());
        yield put(push(`/${lang}/admin/tag/${action.tag.slug}`));
      }
    }
    yield put(loadGlobal(true));
    if (id) {
      yield call(request, `v1/object_management?id=${id}&type=term`, {
        method: 'GET',
      });
    }
  } catch (e) {
    console.error(e);
    yield put(saveTagError(e));
    notifyError(e.toString(), 'Cannot save tag');
  } finally {
    yield put(setLoading(false));
  }
}

export function* guideOrderSaga(action) {
  const guidesData = yield select(makeSelectGuides());

  try {
    const data = guidesData.dataByCat[action.category].map(guide => ({
      order: guide.order,
      id: guide.id,
    }));
    yield call(request, `v1/order`, {
      method: 'POST',
      body: JSON.stringify({
        data,
        type: 'posts',
      }),
    });
    notifySuccess('Order saved!');
  } catch (e) {
    notifyError(e.toString(), 'Cannot save order!');
  }
}

export function* tagDeleteSaga(action) {
  try {
    yield put(setLoading(true));
    if (action.id) {
      yield call(request, `wp/v2/tags/${action.id}`, {
        method: 'DELETE',
        params: { force: true },
      });
      notifySuccess('Tag deleted!');
      const lang = yield select(makeSelectLocale());
      yield put(loadGlobal());
      yield call(request, `v1/object_management?id=${action.id}&type=term`, {
        method: 'GET',
      });
      yield put(push(`/${lang}/admin/tags`));
    } else {
      throw new Error('Tag not specified!');
    }
  } catch (e) {
    console.error(e);
    notifyError(e.toString(), 'Cannot delete tag!');
  } finally {
    yield put(setLoading(false));
  }
}

export default function* adminTagPageSaga() {
  yield takeLatest(SAVE_TAG, tagSaveSaga);
  yield takeLatest(DELETE_TAG, tagDeleteSaga);
  yield takeLatest(SAVE_GUIDE_ORDER, guideOrderSaga);
}
