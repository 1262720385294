import styled from 'styled-components';
import theme from "../../utils/constants/theme";

const ArrowRight = styled.span<{color?: string}>`
  height: 14px;
  position: relative;
  overflow: hidden;
  width: 8px;
  //top: 4px;
  right: 5px;
  &:before {
    background-color: ${props => props.color ? props.color : theme.colors.lightGrey};
    content: '';
    height: 14px;
    //left: 5px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 14px;
    left: auto;
    right: 5px;
  }
  &:after {
    background-color: white;
    content: '';
    height: 14px;
    //left: 8px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 14px;
    left: auto;
    right: 8px;
  }
  &:hover:before {
    background-color: ${theme.colors.main};
  }
`;

export default ArrowRight;
