import { takeLatest, call, put, select } from 'redux-saga/effects';
import request from 'utils/request';
import { makeSelectPreviewMode } from '../App/selectors';

import { makeSelectLocale } from '../LanguageProvider/selectors';
import { LOAD_GUIDES, LOAD_TAG_CATEGORIES, LOAD_TAG_HELPS } from './constants';
import {
  loadGuidesSuccess,
  loadGuidesError,
  loadTagHelpsError,
  loadTagHelpsSuccess,
  loadTagCategoriesError,
  loadTagCategoriesSuccess,
} from './actions';

export function* guidesSaga(action) {
  try {
    const lang = yield select(makeSelectLocale());
    const preview = yield select(makeSelectPreviewMode());
    const params = { lng: lang };
    if (action.tag) {
      params.tag = action.tag;
    }
    if (preview || action.isAdmin) {
      params.admin = true;
      params.translations_info = true;
    }
    if (preview && !action.isAdmin) {
      params.preview = true;
    }
    const data = yield call(request, 'v1/guides', {
      params,
    });

    yield put(loadGuidesSuccess(data ? data.data : []));
  } catch (e) {
    console.error(e);
    yield put(loadGuidesError(e));
  }
}

export function* helpsSaga(action) {
  const lang = yield select(makeSelectLocale());
  const preview = yield select(makeSelectPreviewMode());
  try {
    const params = {
      lng: lang,
      tag: action.tag,
      per_page: 10,
      page: action.page || 1,
      include_guides: action.includeGuides,
    };
    if (action.category) {
      params.category = action.category;
    }
    if (preview || action.isAdmin) {
      params.admin = true;
      params.translations_info = true;
    }
    if (preview && !action.isAdmin) {
      params.preview = true;
    }
    if (action.sort) {
      if (0 && action.sort === 'popular') {
        params.popular = true;
      } else {
        const [orderby, order] = action.sort.split(',');
        params.orderby = orderby;
        params.order = order;
      }
    }
    const data = yield call(request, 'v1/helps', {
      params,
    });

    yield put(
      loadTagHelpsSuccess(
        data ? data.data : [],
        data ? data.info.total_pages : 0,
        data ? data.info.total_items : 0,
      ),
    );
  } catch (e) {
    console.error(e);
    yield put(loadTagHelpsError(e));
  }
}

export function* categorySaga(action) {
  try {
    const lang = yield select(makeSelectLocale());
    const data = yield call(request, 'v1/categories', { params: { lng: lang, tag: action.tag } });

    yield put(loadTagCategoriesSuccess(data ? data.data : []));
  } catch (e) {
    console.error(e);
    yield put(loadTagCategoriesError(e));
  }
}

// Individual exports for testing
export default function* tagPageSaga() {
  yield takeLatest(LOAD_GUIDES, guidesSaga);
  yield takeLatest(LOAD_TAG_HELPS, helpsSaga);
  yield takeLatest(LOAD_TAG_CATEGORIES, categorySaga);
}
