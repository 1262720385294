/**
 *
 * AdminNewsPage
 *
 */

import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  SortingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid as TableGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import AdminGuide from 'components/admin/AdminGuide';
import DeleteModal from 'components/modals/DeleteModal';
import { Button, Grid, H2 } from 'components/layout';
import Loader from 'components/Loader';
import {
  makeSelectCategoriesBySlug,
  makeSelectTagsBySlug,
  makeSelectCategories,
} from 'containers/App/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useModal, useLocalStorage } from 'utils/hooks';
import { useInjectReducer } from 'utils/injectReducer';

import { useInjectSaga } from 'utils/injectSaga';
import {
  CategoriesTypeProvider,
  globalTableColumnExtensions,
  ImageTypeProvider,
  StatusTypeProvider,
  ActionsTypeProvider,
  TagsTypeProvider,
  DateTypeProvider,
  TranslationsTypeProvider,
} from 'utils/tableUtils';
import PrintModal from 'components/modals/PrintModal';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getCatChainById, urlBuilder } from '../../../utils/helpers';
import request from '../../../utils/request';

import messages from './messages';

export function AdminNewsPage({ match, categoriesBySlug, language, tags }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState([
    { columnName: 'updated', direction: 'desc' },
  ]);
  const [pageSize, setPageSize] = useLocalStorage('prusuki_page_size', 20);
  const tableColumnExtensions = [
    ...globalTableColumnExtensions,
    { columnName: 'title', width: 250, wordWrapEnabled: true },
    {
      columnName: 'tags',
      width: 200,
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
    {
      columnName: 'categories',
      width: 200,
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
    { columnName: 'created', width: 200 },
    { columnName: 'updated', width: 200 },
  ];

  useEffect(() => {
    setLoading(true);
    let sort = 'modified,desc';
    if (sorting && sorting.length) {
      sort = `${sorting[0].columnName},${sorting[0].direction}`;
    }
    request('v1/news', {
      params: {
        lng: language,
        admin: true,
        sort,
        page: currentPage || 1,
        per_page: pageSize,
        translations_info: true,
      },
    }).then(resp => {
      setData(resp.data);
      setLoading(false);
      setMetaData(resp.info);
    });
  }, [match.params.category, currentPage, pageSize, sorting]);

  const totalCount = metaData.totalItems;

  return (
    <div>
      <H2>Edit news</H2>
      {loading ? (
        <Loader />
      ) : (
        data &&
        data.length > 0 && (
          <TableGrid
            rows={data}
            columns={[
              { name: 'actions', title: ' ' },
              { name: 'media', title: 'Image' },
              { name: 'title', title: 'Title' },
              // { name: 'tags', title: 'Tags' },
              // { name: 'categories', title: 'Categories' },
              { name: 'created', title: 'Created' },
              { name: 'updated', title: 'Modified' },
              { name: 'status', title: 'Status' },
              { name: 'translations_info', title: 'Translations' },
            ]}
          >
            <PagingState
              defaultCurrentPage={0}
              defaultPageSize={pageSize}
              onPageSizeChange={setPageSize}
              currentPage={currentPage - 1}
              onCurrentPageChange={p => {
                setCurrentPage(p + 1);
              }}
            />
            <CustomPaging totalCount={totalCount} />
            <FilteringState
              defaultFilters={[]}
              columnExtensions={[
                { columnName: 'actions', filteringEnabled: false },
                { columnName: 'media', filteringEnabled: false },
              ]}
            />
            <IntegratedFiltering />
            <SortingState
              sorting={sorting}
              onSortingChange={setSorting}
              columnExtensions={tableColumnExtensions}
            />
            <ImageTypeProvider for={['media']} />
            <TagsTypeProvider for={['tags']} tags={tags} language={language} />
            <CategoriesTypeProvider
              for={['categories']}
              categories={categoriesBySlug}
            />
            <DateTypeProvider for={['updated', 'created']} />
            <TranslationsTypeProvider for={['translations_info']} />
            <StatusTypeProvider for={['status']} />
            <ActionsTypeProvider
              for={['actions']}
              language={`${language}/admin`}
            />
            <Table
              columnExtensions={tableColumnExtensions}
              /* rowComponent={TableRowWrapper(
                rowClickHandler({
                  push: historyPush,
                  language: `${language}/admin`,
                }),
              )} */
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <PagingPanel pageSizes={[10, 20, 50, 100]} />
          </TableGrid>
        )
      )}
    </div>
  );
}

AdminNewsPage.propTypes = {
  articles: PropTypes.object,
  guides: PropTypes.object,
  categoriesBySlug: PropTypes.object,
  loadArticles: PropTypes.func.isRequired,
  loadGuides: PropTypes.func.isRequired,
  saveNews: PropTypes.func.isRequired,
  deleteNews: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  tags: PropTypes.object,
  categories: PropTypes.array,
  changeGuideOrder: PropTypes.func.isRequired,
  saveGuidesOrder: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  categoriesBySlug: makeSelectCategoriesBySlug(),
  tags: makeSelectTagsBySlug(),
  categories: makeSelectCategories(),
  language: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    /* loadArticles: (category, page, pageSize, sort = 'modified,desc') =>
      dispatch(
        loadArticlesAction(
          category,
          page,
          true,
          true,
          sort,
          false,
          true,
          pageSize,
        ),
      ), */
    // saveNews: (id, category) => dispatch(saveNewsAction(id, category)),
    // deleteNews: id => dispatch(deleteNewsAction(id)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AdminNewsPage);
