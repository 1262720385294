import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ButtonBig } from '../layout';
import { useScrollPosition } from '../../utils/hooks';

import messages from './messages';

const Wrapper = styled.div`
  text-align: center;
  margin: 1em 0;
`;

function InfiniteScroll({ onLoad, page, totalPages, isLoading }) {
  const ref = useRef(null);
  const [count, setCount] = useState(0);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        isLoading ||
        totalPages === page ||
        totalPages <= page ||
        count >= 2
      ) {
        return;
      }
      if (currPos.y < window.innerHeight / 1.2) {
        onLoad(page + 1);
        setCount(count + 1);
      }
    },
    [page, isLoading, count],
    ref,
    false,
    200,
  );

  if (totalPages === 1) {
    return null;
  }

  return (
    <Wrapper
      ref={ref}
      style={{ fontStyle: totalPages === page ? 'italic' : 'normal' }}
    >
      {totalPages === page ? (
        <FormattedMessage {...messages.noMore} />
      ) : (
        <ButtonBig
          onClick={() => {
            onLoad(page + 1);
            setCount(0);
          }}
        >
          {isLoading ? (
            <FormattedMessage {...messages.loading} />
          ) : (
            <FormattedMessage {...messages.loadMore} />
          )}
        </ButtonBig>
      )}
    </Wrapper>
  );
}

InfiniteScroll.propTypes = {
  onLoad: PropTypes.func.isRequired,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default InfiniteScroll;
