import request from './request';
import advlistPlugin from './tinymce-plugin-advlist';

export function editorSetup(editor) {
  advlistPlugin(editor);

  editor.ui.registry.addButton('note', {
    text: 'Note',
    onAction() {
      const text = editor.selection.getContent({
        format: 'html',
      });
      editor.insertContent(`<div class="note-callout">${text || 'Note'}</div>`);
    },
  });
  editor.ui.registry.addButton('tip', {
    text: 'Tip',
    onAction() {
      const text = editor.selection.getContent({
        format: 'html',
      });
      editor.insertContent(`<div class="tip-callout">${text || 'Tip'}</div>`);
    },
  });
  editor.ui.registry.addButton('warning', {
    text: 'Warning',
    onAction() {
      const text = editor.selection.getContent({
        format: 'html',
      });
      editor.insertContent(
        `<div class="warning-callout">${text || 'Warning'}</div>`,
      );
    },
  });
  editor.ui.registry.addButton('key', {
    text: 'Key',
    onAction() {
      const text = editor.selection.getContent({
        format: 'html',
      });
      editor.insertContent(`<span class="key">${text || 'Key'}</span>`);
    },
  });

  editor.ui.registry.addButton('codesampleinline', {
    text: 'Code',
    onAction() {
      const text = editor.selection.getContent({
        format: 'html',
      });
      editor.insertContent(`<code class="codesample-inline">${text || 'Code here'}</code>`);
    },
  });

  editor.ui.registry.addAutocompleter('tooltips', {
    ch: '<',
    minChars: 2,
    columns: 1,
    async fetch(pattern) {
      const data = await request('v1/search', {
        params: {
          search: pattern,
          lng: editor.settings.language,
          per_page: 100,
          include_excerpt: true,
          type: 'tooltips',
        },
      });

      return new Promise(resolve => {
        const results = data.data.map(char => ({
          value: `<span class="tooltip" data-tooltip="${char.slug}">${
            char.title
          }</span> .`,
          text: char.title,
        }));
        resolve(results);
      });
    },
    onAction(autocompleteApi, rng, value) {
      editor.selection.setRng(rng);
      editor.insertContent(value);
      autocompleteApi.hide();
    },
  });

  // Image srcset workaround
  editor.on('NodeChange', e => {
    if (e.element.tagName === 'IMG' && e.element.title) {
      if (!e.element.title.includes('.gif')) {
        e.element.setAttribute('srcset', e.element.title);
        e.element.setAttribute('sizes', '100vw');
      }
      e.element.removeAttribute('title');
    }
  });
  // Allow tabs
  editor.on('KeyDown', e => {
    if (e.keyCode === 9){ // tab pressed
      editor.execCommand('mceInsertRawHTML', false, '\x09'); // inserts tab
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  });
}

export function generateSettings({
  language,
  setCurrentEditorImage,
  setGalleryCallback,
  openGallery,
}) {
  return {
    height: 800,
    forced_root_block: 'p',
    paste_word_valid_elements: 'b,strong,i,em,h1,h2,p',
    plugins:
      'autolink image code media table lists link wordcount paste codesample',
    // stickytoolbar
    keep_styles: false,
    paste_as_text: true,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    entity_encoding: 'raw',
    toolbar:
      'formatselect | bold italic | numlista bullista outdent indent | ' +
      'alignleft aligncenter alignright alignjustify | codesample codesampleinline | removeformat | ' +
      'code | image media | slideshow slide | table | link | note tip warning | key | didyouknow didyouknow-left didyouknow-right | undo redo',
    file_picker_callback(callback, value, meta) {
      // Provide image and alt text for the image dialog
      if (meta.filetype === 'image' || meta.filetype === 'media') {
        if (value) {
          let name = value.split('/');
          name = name[name.length - 1];
          request(`v1/media`, { params: { name } }).then(r => {
            if (r) {
              setCurrentEditorImage([parseInt(r.id, 0)]);
            }
            setGalleryCallback(() => callback);
            openGallery();
          });
        } else {
          setGalleryCallback(() => callback);
          openGallery();
        }
      }
    },
    // extended_valid_elements: 'img[class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name|srcset]',
    image_dimensions: true,
    image_title: true,
    image_caption: true,
    media_dimensions: true,
    media_live_embeds: true,
    media_poster: false,
    language,
    setup: editorSetup,
    content_css: `${window.location.origin}/assets/css/custom.css`,
    video_template_callback(videoData) {
      return `
        <video muted autoplay controls loop width="925" height="auto" ${
          videoData.poster ? ` poster="${videoData.poster}"` : ''
        }>
            <source src="${videoData.source}" ${
              videoData.sourcemime ? ` type="${videoData.sourcemime}"` : ''
            } />
        </video>
      `;
    },
  };
}
