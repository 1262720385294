import { createSelector } from 'reselect';
import { categoryInitialState, initialState, tooltipInitialState } from './reducer';

const selectRouter = state => state.router;

const selectMainDomain = state =>
  state.main || { global: initialState, categories: categoryInitialState, tooltips: tooltipInitialState };

export const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState.location,
  );

export const makeSelectGlobal = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global,
  );

export const makeSelectCategories = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.categories,
  );

export const makeSelectCategoriesBySlug = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.categories.dataBySlug,
  );

export const makeSelectGlobalTag = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.currentTag,
  );

export const makeSelectTags = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.tags,
  );

export const makeSelectTagsBySlug = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.tagsBySlug,
  );

export const makeSelectRelationsBySlug = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.relationsBySlug,
  );

export const makeSelectSearchOpen = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.searchOpen,
  );

export const makeSelectAdminFlag = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.isAdmin,
  );

export const makeSelectCurrentUser = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.currentUser,
  );

export const makeSelectIsLoggedIn = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.currentUser && !!globalState.global.currentUser.email,
  );

export const makeSelectTooltip = () =>
  createSelector(
    selectMainDomain,
    global => global.tooltips,
  );

export const makeSelectUserRoles = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.roles,
  );

export const makeSelectPreviewMode = () =>
  createSelector(
    selectMainDomain,
    globalState => globalState.global.isPreview,
  );

export default { makeSelectLocation, makeSelectGlobal };
