import React from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import uuid from 'uuid/v1';
import request from '../../../utils/request';
import { Button } from '../../layout';
import { notifySuccess } from '../../../utils/notifications';

import Arrow from './Arrow';
import CircleArrow from './CircleArrow';
import Circle from './Circle';
import Line from './Line';
import Measure from './Measure';
import NumberedCircle from './NumberedCircle';
import ObjectList from '../ObjectList';
import ColorBar from '../ColorBar';
import ToolsBar from '../ToolsBar';
import Rectangle from './Rectangle';
import Triangle from './Triangle';

fabric.Object.prototype.noScaleCache = false;
fabric.Object.prototype.resizeToScale = function() {
  // resizes an object that has been scaled (e.g. by manipulating the handles), setting scale to 1 and recalculating bounding box where necessary
  switch (this.type) {
    case 'circle':
    case 'circleArrow':
    case 'numberedCircle':
      this.setRadius(this.radius * this.scaleX);
      // this.radius *= this.scaleX;
      this.scaleX = 1;
      this.scaleY = 1;
      break;
    case 'ellipse':
      this.rx *= this.scaleX;
      this.ry *= this.scaleY;
      this.width = this.rx * 2;
      this.height = this.ry * 2;
      this.scaleX = 1;
      this.scaleY = 1;
      break;
    case 'triangle':
    case 'line':
    case 'arrow':
    case 'measure':
    case 'rect':
      this.width *= this.scaleX;
      this.height *= this.scaleY;
      this.scaleX = 1;
      this.scaleY = 1;
      if (
        this.type === 'arrow' ||
        this.type === 'measure' ||
        this.type === 'line'
      ) {
        this.x1 = this.left;
        this.y1 = this.top;
        this.x2 = this.left + this.width;
        this.y2 = this.top + this.height;
      } else {
        this.setCoords();
      }
      break;
    default:
      break;
  }
};

function getSvgColorString(prop, value) {
  if (!value) {
    return `${prop}: none; `;
  }
  if (value.toLive) {
    return `${prop}: url(#SVGID_${value.id}); `;
  }

  const color = new fabric.Color(value);
  let str = `${prop}: ${color.toRgb()}; `;
  const opacity = color.getAlpha();
  if (opacity !== 1) {
    // change the color in rgb + opacity
    str += `${prop}-opacity: ${opacity.toString()}; `;
  }
  return str;
}

fabric.util.object.extend(
  fabric.Object.prototype,
  /** @lends fabric.Object.prototype */ {
    /**
     * Returns styles-string for svg-export
     * @param {Boolean} skipShadow a boolean to skip shadow filter output
     * @return {String}
     */
    getSvgStyles(skipShadow) {
      const fillRule = this.fillRule ? this.fillRule : 'nonzero';
      const strokeWidth = this.strokeWidth ? this.strokeWidth : '0';
      const strokeDashArray = this.strokeDashArray
        ? this.strokeDashArray.join(' ')
        : 'none';
      const strokeDashOffset = this.strokeDashOffset
        ? this.strokeDashOffset
        : '0';
      const strokeLineCap = 'round';
      const strokeLineJoin = this.strokeLineJoin
        ? this.strokeLineJoin
        : 'miter';
      const strokeMiterLimit = this.strokeMiterLimit
        ? this.strokeMiterLimit
        : '4';
      const opacity = typeof this.opacity !== 'undefined' ? this.opacity : '1';
      const visibility = this.visible ? '' : ' visibility: hidden;';
      const filter = skipShadow ? '' : this.getSvgFilter();
      const fill = getSvgColorString('fill', this.fill);
      const stroke = getSvgColorString('stroke', this.stroke);

      return [
        stroke,
        'stroke-width: ',
        strokeWidth,
        '; ',
        'stroke-dasharray: ',
        strokeDashArray,
        '; ',
        'stroke-linecap: ',
        strokeLineCap,
        '; ',
        'stroke-dashoffset: ',
        strokeDashOffset,
        '; ',
        'stroke-linejoin: ',
        strokeLineJoin,
        '; ',
        'stroke-miterlimit: ',
        strokeMiterLimit,
        '; ',
        fill,
        'fill-rule: ',
        fillRule,
        '; ',
        'opacity: ',
        opacity,
        ';',
        filter,
        visibility,
      ].join('');
    },
    getSvgStrokeStyles(skipShadow) {
      const fillRule = this.fillRule ? this.fillRule : 'nonzero';
      const strokeWidth = this.strokeWidth ? this.strokeWidth + 2 : '0';
      const strokeDashArray = this.strokeDashArray
        ? this.strokeDashArray.join(' ')
        : 'none';
      const strokeDashOffset = this.strokeDashOffset
        ? this.strokeDashOffset
        : '0';
      const strokeLineCap = 'round';
      const strokeLineJoin = this.strokeLineJoin
        ? this.strokeLineJoin
        : 'miter';
      const strokeMiterLimit = this.strokeMiterLimit
        ? this.strokeMiterLimit
        : '4';
      const opacity = typeof this.opacity !== 'undefined' ? this.opacity : '1';
      const visibility = this.visible ? '' : ' visibility: hidden;';
      const filter = skipShadow ? '' : this.getSvgFilter();
      const fill = getSvgColorString('fill', this.fill);
      const stroke = getSvgColorString('stroke', 'white');

      return [
        stroke,
        'stroke-width: ',
        strokeWidth,
        '; ',
        'stroke-dasharray: ',
        strokeDashArray,
        '; ',
        'stroke-linecap: ',
        strokeLineCap,
        '; ',
        'stroke-dashoffset: ',
        strokeDashOffset,
        '; ',
        'stroke-linejoin: ',
        strokeLineJoin,
        '; ',
        'stroke-miterlimit: ',
        strokeMiterLimit,
        '; ',
        fill,
        'fill-rule: ',
        fillRule,
        '; ',
        'opacity: ',
        opacity,
        ';',
        filter,
        visibility,
      ].join('');
    },
    _createBaseSVGMarkup(objectMarkup, options) {
      options = options || {};
      const { noStyle } = options;
      const { reviver } = options;
      const styleInfo = noStyle ? '' : `style="${this.getSvgStyles()}" `;
      const strokeStyleInfo = noStyle
        ? ''
        : `style="${this.getSvgStrokeStyles()}" `;
      const shadowInfo = options.withShadow
        ? `style="${this.getSvgFilter()}" `
        : '';
      const { clipPath } = this;
      const vectorEffect = this.strokeUniform
        ? 'vector-effect="non-scaling-stroke" '
        : '';
      const absoluteClipPath = clipPath && clipPath.absolutePositioned;
      const { stroke } = this;
      const { fill } = this;
      const { shadow } = this;
      let commonPieces;
      const markup = [];
      let clipPathMarkup;
      // insert commons in the markup, style and svgCommons
      const index = objectMarkup.indexOf('COMMON_PARTS');
      const { additionalTransform } = options;
      if (clipPath) {
        clipPath.clipPathId = `CLIPPATH_${fabric.Object.__uid++}`;
        clipPathMarkup = `<clipPath id="${
          clipPath.clipPathId
        }" >\n${clipPath.toClipPathSVG(reviver)}</clipPath>\n`;
      }
      if (absoluteClipPath) {
        markup.push('<g ', shadowInfo, this.getSvgCommons(), ' >\n');
      }
      markup.push(
        '<g ',
        this.getSvgTransform(false),
        !absoluteClipPath ? shadowInfo + this.getSvgCommons() : '',
        ' >\n',
      );
      commonPieces = [
        styleInfo,
        vectorEffect,
        noStyle ? '' : this.addPaintOrder(),
        ' ',
        additionalTransform ? `transform="${additionalTransform}" ` : '',
      ].join('');
      const strokeCommonPieces = [
        strokeStyleInfo,
        vectorEffect,
        noStyle ? '' : this.addPaintOrder(),
        ' ',
        additionalTransform ? `transform="${additionalTransform}" ` : '',
      ].join('');
      objectMarkup[index] = strokeCommonPieces;
      markup.push(objectMarkup.join(''));
      objectMarkup[index] = commonPieces;
      if (fill && fill.toLive) {
        markup.push(fill.toSVG(this));
      }
      if (stroke && stroke.toLive) {
        markup.push(stroke.toSVG(this));
      }
      if (shadow) {
        markup.push(shadow.toSVG(this));
      }
      if (clipPath) {
        markup.push(clipPathMarkup);
      }
      markup.push(objectMarkup.join(''));
      markup.push('</g>\n');
      absoluteClipPath && markup.push('</g>\n');
      return reviver ? reviver(markup.join('')) : markup.join('');
    },
  },
);

class ImageFabricEdit extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.copiedObjects = [];
    this.copiedObject = null;
    this.state = {
      selectedItem: null,
      currentObjects: [],
      isDrawing: false,
      currentType: '',
      anyChange: false,
      currentDrawing: null,
      currentTool: null,
      canvasWidth:
        (props.wrapperRef.current
          ? props.wrapperRef.current.clientWidth
          : window.innerWidth) - 350,
      canvasHeight:
        (props.wrapperRef.current
          ? props.wrapperRef.current.clientHeight
          : window.innerHeight) - 100,
      settings: {
        strokeWidth: 4,
        color: '#AA0A3C',
        number: 1,
      },
    };
    this.canvas = null;
  }

  generators = {
    line: {
      init: (points, options) => new Line(points, this.getOptions(options)),
    },
    arrow: {
      init: (points, options) => new Arrow(points, this.getOptions(options)),
    },
    measure: {
      init: (points, options) => new Measure(points, this.getOptions(options)),
    },
    textbox: {
      init: (points, options) =>
        new fabric.IText('', this.getTextOptions(points, options)),
    },
    triangle: {
      init: (points, options) =>
        new Triangle(this.getRectOptions(points, options)),
    },
    circle: {
      init: (points, options) =>
        new Circle(this.getCircleOptions(points, options)),
    },
    circleArrow: {
      init: (points, options) =>
        new CircleArrow(this.getCircleOptions(points, options)),
    },
    numberedCircle: {
      init: (points, options) =>
        new NumberedCircle(this.getCircleOptions(points, options)),
    },
    rect: {
      init: (points, options) =>
        new Rectangle(this.getRectOptions(points, options)),
    },
    /* image: {
      init: ({ imgElement, ...option }) => new fabric.Image(imgElement, {
        ...defaultOptions,
        ...option,
      }),
    }, */
    polygon: {
      init: (points, options) =>
        new fabric.Polygon(points, {
          ...this.getOptions(options),
          perPixelTargetFind: true,
        }),
    },
  };

  getOptions(options) {
    return {
      strokeUniform: true,
      strokeWidth: this.state.settings.strokeWidth,
      stroke: this.state.settings.color,
      fill: 'transparent',
      selectable: true,
      evented: true,
      centeredScaling: false,
      ...options,
    };
  }

  getRectOptions(points, options) {
    return {
      ...this.getOptions(options),
      left: points[0],
      top: points[1],
      width: points[0] - points[2],
      height: points[1] - points[3],
      rx: 1,
      ry: 1,
    };
  }

  getTextOptions(points, options) {
    return {
      left: points[0],
      top: points[1],
      fontFamily: 'Arial',
      fontSize: 23,
      fontWeight: 'bold',
      fill: this.state.settings.color,
      selectable: true,
      evented: true,
      editable: true,
      stroke: 'white',
      strokeWidth: 1,
    };
  }

  getCircleOptions(points, options) {
    return {
      ...this.getOptions(options),
      left: points[0],
      top: points[1],
      radius: 5,
      centeredScaling: true,
    };
  }

  createObject = (points, options = {}) => {
    const { currentType } = this.state;
    if (currentType === 'numberedCircle') {
      this.setState(({ settings }) => {
        options.label = settings.number;
        const set = { ...settings };
        set.number = settings.number + 1;
        return { settings: set };
      });
    }
    return this.generators[currentType].init(points, options);
  };

  deleteObject(obj) {
    if (obj) {
      this.canvas.remove(obj);
      this.canvas.discardActiveObject();
      this.setState(({ currentObjects }) => {
        const idx = currentObjects.findIndex(o => o.id === obj.id);
        currentObjects.splice(idx, 1);
        return { selectedItem: null, currentObjects };
      });
    }
  }

  handleObjectSelect = id => {
    const objIdx = this.canvas.getObjects().findIndex(obj => obj.id === id);
    const item = this.canvas.item(objIdx);
    if (typeof item.recalculate === 'function') {
      item.recalculate();
    }
    this.canvas.discardActiveObject();
    this.canvas.setActiveObject(item);
    this.canvas.renderAll();
    this.setState({ selectedItem: item });
  };

  handleResize = () => {};

  addObject(object) {
    object.id = uuid();
    object.name = this.state.currentTool ? this.state.currentTool.name : object.name;
    this.canvas.add(object);
    this.setState(prevState => ({
      currentObjects: [...prevState.currentObjects, object],
    }));
  }

  componentDidMount() {
    this.canvas = new fabric.Canvas('canvas', {
      width: 800, // this.canvasRef.clientWidth,
      height: 600, // this.canvasRef.clientHeight,
      preserveObjectStacking: true,
      perPixelTargetFind: true, // To prevent the line having a selectable rectangle drawn around it and instead only have it selectable on direct click
      targetFindTolerance: 20,
    });
    const { canvas } = this;
    this.canvas.wrapperEl.tabIndex = 1000;
    let height = window.innerHeight - 100;

    if (this.props.image.meta) {
      canvas.clear();
      canvas.loadFromJSON(
        JSON.parse(this.props.image.meta.objects),
        () => {
          canvas.renderAll();
        },
        (o, object) => {
          // `o` = json object
          // `object` = fabric.Object instance
          object.id = uuid();
          this.setState(prevState => ({
            currentObjects: [...prevState.currentObjects, object],
          }));
        },
      );
    }

    fabric.Image.fromURL(this.props.image.url, img => {
      // add background image
      const ratio = canvas.width / img.width;
      /* canvas.setHeight(img.height);
      canvas.setWidth(img.width); */
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        scaleX: ratio,
        scaleY: ratio,
        backgroundImageStretch: true,
        crossOrigin: 'Anonymous',
      });
      /* this.setState({
        canvasWidth: img.width,
        canvasHeight: img.height,
      }); */
      height = img.height * ratio;
      canvas.setHeight(height);
    });

    this.setState({
      canvasHeight: height,
    });

    this.canvas.on('mouse:down', o => {
      this.setState({ mouseDrawing: true });
      if (this.state.isDrawing) {
        this.canvas.set({ selection: false });
        const pointer = this.canvas.getPointer(o.e);
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];

        const obj = this.createObject(points);
        if (this.state.currentType === 'numberedCircle') {
          obj.set({ radius: 20 });
          this.addObject(obj);
        } else if (this.state.currentType === 'textbox') {
          this.addObject(obj);
          this.canvas.setActiveObject(obj);
          obj.enterEditing();
        } else {
          this.setState({ currentDrawing: obj });
          this.addObject(obj);
        }
      }
    });

    this.canvas.on('mouse:move', o => {
      if (
        this.state.isDrawing &&
        this.state.mouseDrawing &&
        this.state.currentDrawing
      ) {
        const pointer = this.canvas.getPointer(o.e);
        switch (this.state.currentType) {
          case 'line':
          case 'measure':
          case 'arrow':
            this.state.currentDrawing.set({
              x2: pointer.x,
              y2: pointer.y,
              realX2: pointer.x,
              realY2: pointer.y,
            });
            this.state.currentDrawing.setCoords();
            break;
          case 'rect':
          case 'textbox':
          case 'triangle':
            this.state.currentDrawing.set({
              width: pointer.x - this.state.currentDrawing.left,
              height: pointer.y - this.state.currentDrawing.top,
            });
            this.state.currentDrawing.setCoords();
            break;
          case 'circle':
          case 'circleArrow':
          case 'numberedCircle':
            this.state.currentDrawing.set({
              radius: Math.abs(
                (pointer.x - this.state.currentDrawing.left) / 2,
              ),
            });
            this.state.currentDrawing.setCoords();
            break;
          default:
            break;
        }
        this.canvas.renderAll();
      }
    });

    this.canvas.on('mouse:up', o => {
      if (this.state.isDrawing) {
        this.cancelDrawing();
        this.canvas.set({ selection: true });
      }
      this.canvas.renderAll();
    });

    this.canvas.on('text:editing:entered', o => {
      console.log('MOD', o);
    });

    this.canvas.observe('object:modified', e => {
      e.target.resizeToScale();
      // this.canvas.requestRenderAll();
    });

    this.canvas.on('selection:created', e => {
      this.setState({ selectedItem: this.canvas.getActiveObject() });
      if (e.target.type === 'activeSelection') {
        this.canvas.discardActiveObject();
      } else {
        // do nothing
      }
    });

    /* this.canvas.on('object:selected', o => {
      this.setState({ selectedItem: this.canvas.getActiveObject() });
    }); */

    this.canvas.on('selection:cleared', o => {
      this.setState({ selectedItem: null });
    });

    document.addEventListener('keydown', this.handleKeydown, false);
    window.addEventListener('resize', this.handleResize, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('resize', this.handleResize);
  }

  cancelDrawing() {
    this.setState(({ currentDrawing }) => {
      if (currentDrawing && currentDrawing.id) {
        this.handleObjectSelect(currentDrawing.id);
      }
      return {
        isDrawing: false,
        currentType: '',
        currentDrawing: null,
        mouseDrawing: false,
        currentTool: null,
        anyChange: true,
        selectedItem: currentDrawing,
      };
    });
  }

  handleKeydown = e => {
    switch (e.key) {
      case 'c':
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          if (this.canvas.getActiveObject()) {
            this.canvas.getActiveObject().clone(object => {
              object.set({
                top: object.top + 10,
                left: object.left + 10,
                x1: object.x1 + 10,
                x2: object.x2 + 10,
                realX2: object.realX2 + 10,
                y1: object.y1 + 10,
                y2: object.y2 + 10,
                realY2: object.realY2 + 10,
              });
              this.copiedObject = object;
              this.copiedObjects = [];
            });
          } else if (this.canvas.getActiveObjects()) {
            this.canvas.getActiveObjects().forEach(o => {
              o.clone(obj => {
                obj.set({
                  top: obj.top + 10,
                  left: obj.left + 10,
                });
                this.copiedObjects.push(obj);
                this.copiedObject = null;
              });
            });
          }
        }
        break;
      case 'v':
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          if (this.copiedObjects.length > 0) {
            this.copiedObjects.forEach(obj => {
              this.addObject(fabric.util.object.clone(obj));
            });
          } else if (this.copiedObject) {
            this.addObject(fabric.util.object.clone(this.copiedObject));
          }
          this.copiedObject = null;
          this.copiedObjects = [];
          this.canvas.discardActiveObject();
          this.canvas.renderAll();
        }
        break;
      case 'Delete':
        this.doDelete();
        break;
      case 'Escape':
        this.cancelDrawing();
        break;
      default:
        if (e.keyCode === 8 || e.keyCode === 46) {
          this.doDelete();
        }
        break;
    }
  };

  doDelete = () => {
    const selected = this.canvas.getActiveObjects();
    const selGroup = new fabric.ActiveSelection(selected, {
      canvas: this.canvas,
    });
    if (selGroup) {
      selGroup.forEachObject(obj => {
        this.deleteObject(obj);
      });
    } else {
      return false;
    }
    // Use discardActiveObject to remove the selection border
    this.canvas.discardActiveObject().renderAll();
    return true;
  };

  handleColorChange = c => {
    this.setState(prevState => {
      if (prevState.selectedItem) {
        if (prevState.selectedItem.type === 'i-text') {
          prevState.selectedItem.setColor(c);
        }
        prevState.selectedItem.set({ stroke: c });
        this.canvas.renderAll();
      }
      return { settings: { ...prevState.settings, color: c } };
    });
  };

  handleToolChange = tool => {
    switch (tool.type) {
      case 'drawing':
      case 'shape':
      case 'text':
        this.canvas.discardActiveObject();
        this.setState({
          selectedItem: null,
          currentType: tool.option.type,
          isDrawing: true,
          currentTool: tool,
        });
        break;
      default:
        break;
    }
  };

  handleToolSettingsChange = (type, value) => {
    this.setState(prevState => {
      const settings = { ...prevState.settings };
      settings[type] = value;
      if (type === 'strokeWidth') {
        if (prevState.selectedItem) {
          prevState.selectedItem.set({ strokeWidth: value });
          this.canvas.renderAll();
        }
      }
      return { settings };
    });
  };

  handleSave = e => {
    e.preventDefault();
    const json = this.canvas.toJSON();
    // const ratio = window.devicePixelRatio;
    const png = this.canvas.toDataURL({
      format: 'png',
      multiplier: 2,
      left: 0,
      top: 0,
    });

    const svg = this.canvas.toSVG();
    // console.log(svg);

    request(`v1/media`, {
      method: 'POST',
      body: JSON.stringify({
        attachment_id: this.props.image.id,
        objects: JSON.stringify(json),
        svg,
      }),
    }).then(() => {
      this.setState({ anyChange: false });
      notifySuccess('Saved');
    });

    // console.log(png);

    this.props.onSave(json, png);
  };

  handleClose = e => {
    e.preventDefault();
    if (this.state.anyChange) {
      const r = window.confirm(
        'You have unsaved changes. Do you really want to exit without saving?',
      );
      if (r) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { colors } = this.props;
    const {
      settings,
      currentObjects,
      currentType,
      canvasWidth,
      canvasHeight,
      selectedItem,
    } = this.state;

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          zIndex: 999,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ToolsBar
              onToolChange={this.handleToolChange}
              activeType={currentType}
              onToolSettingsChange={this.handleToolSettingsChange}
              settings={this.state.settings}
            />
            <ColorBar
              colors={colors}
              onColorChange={this.handleColorChange}
              currentColor={settings.color}
            />
          </div>
          <div style={{ display: 'flex', flexWrap: 'no-wrap', height: '100%' }}>
            <div
              style={{
                overflow: 'auto',
                width: '800px',
                height: 'calc(100% - 48px)',
                flex: '1 auto',
              }}
            >
              <canvas
                id="canvas"
                ref={this.canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                style={{
                  zIndex: 9999,
                  width: '100%',
                  maxWidth: '100%',
                }}
              />
            </div>
            <div
              style={{
                width: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <ObjectList
                objects={currentObjects}
                onObjectSelect={this.handleObjectSelect}
                selectedObject={selectedItem}
              />
              <div style={{ textAlign: 'right', padding: '10px' }}>
                <Button type="button" onClick={this.handleSave}>
                  Save
                </Button>
                &nbsp;
                <Button type="button" onClick={this.handleClose}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImageFabricEdit.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default ImageFabricEdit;
