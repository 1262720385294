/**
 *
 * AdminTagPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import tagReducer from 'containers/TagPage/reducer';
import tagSaga from 'containers/TagPage/saga';
import { InputLabel, Error } from 'components/admin/layout';
import { Grid, H2, Col } from 'components/layout';
import {
  makeSelectTagsBySlug,
  makeSelectUserRoles,
} from 'containers/App/selectors';
import FeaturedImageField from 'components/admin/FeaturedImageField';
import { validateTitle } from 'utils/formUtils';
import { slugify } from 'utils/helpers';
import FormActions from 'components/admin/FormActions';
import StatusField from '../../../components/admin/StatusField';
import Loader from '../../../components/Loader';
import { ROLES } from '../../../utils/constants';
import { useCheckRole } from '../../../utils/hooks';
import { makeSelectSavingState } from '../AdminSection/selectors';
import { saveTagAction } from './actions';

// import reducer from './reducer';
import saga from './saga';
import messages from './messages';

export function AdminTagEditor({
  tags,
  saveTag,
  savingState,
  slug,
  callback = false,
  onDelete = () => null,
  doRedirect = true,
  roles,
}) {
  useInjectReducer({ key: 'tagPage', reducer: tagReducer });
  useInjectSaga({ key: 'tagPage', saga: tagSaga });
  useInjectSaga({ key: 'tagAdminPage', saga });

  const { isInRole } = useCheckRole(roles);
  const isAdmin = isInRole(ROLES.ADMIN);

  const isNew = slug === 'new';

  const data = !isNew && slug !== 'all' ? tags[slug] : {};

  if (!data) return <Loader />;
  return (
    <React.Fragment>
      <H2>
        {!isNew ? (
          <>
            <FormattedMessage {...messages.editTag} /> {data.name}
          </>
        ) : (
          'New tag'
        )}
      </H2>
      <Formik
        initialValues={{
          name: data.name || '',
          description: data.content || '',
          slug: data.slug || '',
          status: data.status || 'private',
          _thumbnail_id:
            data.media && data.media.image
              ? data.media.image.id
              : null
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          const tagData = {
            ...values,
          };
          if (data.id) {
            saveTag(data.id, tagData, doRedirect);
          } else {
            tagData.slug = slugify(tagData.name);
            saveTag(null, tagData, doRedirect);
            if (callback && typeof callback === 'function') callback();
          }
          actions.setSubmitting(false);
        }}
        render={({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <Grid>
              <Col cols={7}>
                <div>
                  <InputLabel>Title</InputLabel>
                  <Field
                    type="text"
                    name="name"
                    size={20}
                    validate={validateTitle}
                  />
                  {errors.name && touched.name && <Error>{errors.name}</Error>}
                </div>
                <div>
                  <InputLabel>Description</InputLabel>
                  <Field
                    type="text"
                    name="description"
                    size={50}
                    // validate={validateTitle}
                  />
                  {errors.description && touched.description && <Error>{errors.description}</Error>}
                </div>
                <StatusField
                  values={values}
                  isAdmin={isAdmin}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col cols={5}>
                <FeaturedImageField
                  name="_thumbnail_id"
                  setFieldValue={setFieldValue}
                  currentMedia={data.media || {}}
                />
              </Col>
            </Grid>
            <FormActions
              saveDisabled={isSubmitting || Object.keys(errors).length > 0}
              isSaving={savingState.loading}
              isNew={isNew}
              onDelete={isAdmin ? onDelete : null}
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
}

AdminTagEditor.propTypes = {
  tags: PropTypes.object,
  saveTag: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  savingState: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  callback: PropTypes.func,
  doRedirect: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = createStructuredSelector({
  tags: makeSelectTagsBySlug(),
  savingState: makeSelectSavingState(),
  roles: makeSelectUserRoles(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveTag: (id, tag, redirect) => dispatch(saveTagAction(id, tag, redirect)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AdminTagEditor);
