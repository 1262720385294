/**
 *
 * OkModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

import { ButtonBig } from '../../layout';

function OkModal({ open, children, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ textAlign: 'center' }}>
        {children}
        <div>
          <ButtonBig onClick={onClose}>Ok</ButtonBig>
        </div>
      </DialogContent>
    </Dialog>
  );
}

OkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default OkModal;
