import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faText,
  faHorizontalRule,
  faRulerHorizontal,
  faCircle,
  faDrawPolygon,
  faTriangle,
  faDotCircle,
  faRectangleLandscape,
  faRedo,
} from '@fortawesome/pro-light-svg-icons';
import { Button as Btn } from '../../layout/Button';
import descriptors from '../FabricEdit/Descriptors';

const Button = styled(Btn)`
  margin-right: 2px;
`;

const Label = styled.label`
  margin-left: 0.8em;
  & > * {
    vertical-align: middle;
  }
  input {
    margin-left: 0.4em;
  }
`;

export const iconMap = {
  arrow: faArrowRight,
  circle: faCircle,
  circleArrow: faRedo,
  line: faHorizontalRule,
  measure: faRulerHorizontal,
  rect: faRectangleLandscape,
  numberedCircle: faDotCircle,
  textbox: faText,
  'i-text': faText,
  polygon: faDrawPolygon,
  triangle: faTriangle,
};

function getIcon(type) {
  const icon = iconMap[type] || null;
  if (!icon) return type;
  return <FontAwesomeIcon icon={icon} />;
}

function ToolsBar({
  activeType,
  onToolChange,
  onToolSettingsChange,
  settings,
}) {
  const { number, strokeWidth } = settings;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0.5em', flex: '1 auto' }}>
      {descriptors.DRAWING.map(d => (
        <Button
          disabled={d.option.type === activeType}
          key={d.name}
          onClick={() => onToolChange(d)}
        >
          {getIcon(d.option.type)}
        </Button>
      ))}
      {descriptors.TEXT.map(d => (
        <Button
          disabled={d.option.type === activeType}
          key={d.name}
          onClick={() => onToolChange(d)}
        >
          {getIcon(d.option.type)}
        </Button>
      ))}
      {descriptors.SHAPE.map(d => (
        <Button
          disabled={d.option.type === activeType}
          key={d.name}
          onClick={() => onToolChange(d)}
        >
          {getIcon(d.option.type)}
        </Button>
      ))}
      <Label>
        Number:
        <input
          onChange={e => onToolSettingsChange('number', parseInt(e.target.value))}
          value={number}
          type="number"
          min={0}
          step={1}
          style={{ width: '50px' }}
        />
      </Label>
      <Label>
        Stroke ({strokeWidth}):
        <input
          type="range"
          min="1"
          max="10"
          value={strokeWidth}
          onChange={e =>
            onToolSettingsChange('strokeWidth', parseInt(e.target.value))
          }
        />
      </Label>
    </div>
  );
}

ToolsBar.propTypes = {
  activeType: PropTypes.string.isRequired,
  onToolChange: PropTypes.func.isRequired,
  onToolSettingsChange: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default ToolsBar;
